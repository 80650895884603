<template lang="pug">
v-container(fluid)
  v-card
    v-card-title {{ title }}
    v-card-text
      v-form(ref="form" @submit.prevent="onSubmit")
        v-row
          v-col(cols="12" md="6")
            v-autocomplete(
              label="Selecciona un producto base"
              v-model="form.base_product"
              :search-input.sync="searchBaseProduct"
              outlined
              no-filter
              item-text="name"
              item-value="id"
              @change="handleSelectBaseProduct"
              :items="baseProducts"
              :rules="v.base_product")

            .d-flex
              UpperCaseTextInput(
                label="Ingresa SKU"
                v-model="form.sku"
                :rules="v.sku"
                outlined
                )
              ShowInfoBtn.mt-4(style="height: 100%" @click="$refs.skuInfoAlert.showAlert()")
            InfoAlert(:message="alertMessages.sku" ref="skuInfoAlert")

            UpperCaseTextInput(
              label="Nombre del producto"
              v-model="form.name"
              outlined
              )
            h3.mb-4 Atributos
            div(v-if="selectedBaseProduct")
              div(v-for="(attribute, index) in selectedBaseProduct.attributes" :key="index")
                v-select(
                  :label="attribute.name"
                  v-model="form.attribute_options[index]"
                  :items="attribute.options"
                  item-value="id"
                  item-text="name"
                  outlined
                  :rules="v.attributes"
                  )
            div.mb-8(v-else) Selecciona un
              b "Producto Base"
              span para cargar los atributos posibles.
            div(:id="WIZARD_ELEMENTS_ID.HELP_MARKET_PRICE")
              .d-flex
                NumberMaskedInput(
                  v-model="form.market_price"
                  :rules="v.market_price"
                  label="Ingresa el precio de mercado"
                  prefix="$"
                )
                ShowInfoBtn.mt-4(style="height: 100%" @click="$refs.marketPriceInfoAlert.showAlert()")
              InfoAlert(:message="alertMessages.market_price" ref="marketPriceInfoAlert")
            NumberMaskedInput(
              label="Costo unitario"
              v-model="form.unit_cost"
              type="number"
              :rules="v.unit_cost"
              hint="Se utiliza para calcular el margen de ganancia (Precio de mercado - Costo unitario)"
              prefix="$"
              persistent-hint
              outlined
              )
        .text-right
          v-btn(color="primary" outlined @click="handleCancel" :disabled="loading").mr-2 Cancelar
          v-btn(color="primary" type="submit" :loading="loading") Guardar
</template>

<script>
import { mapActions } from 'vuex'
import FormMixin from '@/components/mixins/FormMixin'
import validations from '@/shared/formValidations'
import debounce from 'debounce'
import ShowInfoBtn from '@/components/common/ShowInfoBtn.vue'
import InfoAlert from '@/components/common/InfoAlert.vue'
import { numberMaskFormat } from '../utils/mask'
import NumberMaskedInput from '../components/common/NumberMaskedInput.vue'
import UpperCaseTextInput from '../components/common/UpperCaseTextInput.vue'
import { WIZARD_STEPS_EVENTS, WIZARD_ELEMENTS_ID } from '@/constants'
import { globalEmitter } from '@/utils/eventBus'

export default {
  mixins: [FormMixin],
  components: {
    ShowInfoBtn,
    InfoAlert,
    NumberMaskedInput,
    UpperCaseTextInput
  },
  data () {
    return {
      WIZARD_ELEMENTS_ID,
      numberMaskFormat,
      instanceId: this.$route.params.id || null,
      form: {
        name: null,
        base_product: null,
        sku: null,
        unit_cost: null,
        attribute_options: [],
        monthly_production_capacity: 0,
        january_production_percentage: 100,
        february_production_percentage: 100,
        march_production_percentage: 100,
        april_production_percentage: 100,
        may_production_percentage: 100,
        june_production_percentage: 100,
        july_production_percentage: 100,
        august_production_percentage: 100,
        september_production_percentage: 100,
        october_production_percentage: 100,
        november_production_percentage: 100,
        december_production_percentage: 100,
      },
      searchBaseProduct: null,
      baseProducts: [],
      selectedBaseProduct: null,
      alertMessages: {
        sku: 'Te recomendamos utilizar tu propio código interno como código de producto para facilitar el manejo del inventario. Si no cuentas con uno, puedes crear acrónimos que te permitan identificar rápidamente el producto con solo ver el código. Por ejemplo, el acrónimo CCZ-500 podría representar Coca Cola Zero 500ml.',
        market_price: 'Registra el precio promedio del producto en el mercado según tus propias definiciones y en función de tus clientes. Este dato es fundamental, ya que el modelo utilizará este valor como referencia para determinar a qué precio un cliente potencial podría realizar una compra, especialmente cuando no tengas información específica sobre el precio que un cliente está dispuesto a pagar.',
        monthly_production_capacity: `Indica aquí la capacidad máxima de producción o abastecimiento mensual para cada producto. Este valor es crucial, ya que actúa como un límite en tus análisis de potencial comercial. Recuerda que la capacidad ingresada puede incluir tanto la producción propia como la capacidad de compra a terceros. Puedes ir variando porcentualmente por mes la capacidad de producción inicial.

Asegúrate de actualizar este dato regularmente para reflejar cualquier incremento o reducción en tus capacidades, permitiéndote así mantener una planificación precisa y adaptativa.`,
      }
    }
  },
  methods: {
    globalEmitter,
    ...mapActions('product', [
      'getProduct',
      'getBaseProduct',
      'listBaseProducts',
      'createProduct',
      'editProduct',
    ]),
    onSuccess () {
      globalEmitter(WIZARD_STEPS_EVENTS.PRODUCT_CREATED)
      this.$router.push('/productos?tab=1')
    },
    onError ({ error }) {
      this.loading = false
      console.log(error.response.data)
      let errorMessage = `Ocurrió un error al ${this.instanceId ? 'editar' : 'guardar'} el producto \n`
      const labels = {
        name: 'Nombre',
        base_product: 'Producto base',
        sku: 'SKU',
        market_price: 'Precio de mercado',
        attribute_options: 'Atributos'
      }
      for (const key in error.response.data) {
        errorMessage = errorMessage.concat(`${labels[key]}: ${error.response.data[key].join(', ')} \n`)
      }
      this.$toast.error(errorMessage)

    },
    getAction () {
      return this.instanceId ? this.editProduct : this.createProduct
    },
    async fetchForm () {
      // Si se está editando un producto
      if (this.instanceId) {
        const { data } = await this.getProduct(this.instanceId)
        await this.handleSelectBaseProduct(data.base_product)
        this.form = {
          ...data,
        }
      }
      // Si se está copiando un producto
      else if (this.$route.query.copy) {
        const { data } = await this.getProduct(this.$route.query.copy)
        await this.handleSelectBaseProduct(data.base_product)
        data.sku = `${data.sku}-copia`
        data.name = `${data.name} (copia)`
        this.form = {
          ...data,
        }
      }
      if (this.baseProducts.length === 0) {
        this.handleSearchBaseProduct()
      }
    },
    getData () {
      const data = { data: this.form }
      if (this.instanceId) {
        data.id = this.instanceId
      }
      return data
    },
    handleCancel () {
      this.$router.push('/productos?tab=1')
    },
    async handleSelectBaseProduct (baseProductId) {
      if (!baseProductId) {
        this.form.attribute_options = []
        this.selectedBaseProduct = null
        return
      }
      const { data } = await this.getBaseProduct(baseProductId)
      this.form.attribute_options = data.attributes.map(() => null)
      this.selectedBaseProduct = data
    },
    async handleSearchBaseProduct () {
      const params = {
        search: this.searchBaseProduct,
        page_size: 15
      }
      const { data } = await this.listBaseProducts({ params })
      this.baseProducts = data.results
    },
  },
  computed: {
    title () {
      return this.instanceId ? 'Editar producto' : 'Añadir producto'
    },
    v () {
      return {
        sku: [validations.isRequired],
        name: [validations.isRequired],
        line_of_business: [validations.isRequired],
        base_product: [validations.isRequired],
        attributes: [validations.isRequired],
        attributeOptionsNames: [validations.isRequired],
        monthly_production_capacity: [validations.isRequired, validations.minOrEqual(0)],
        production_percentage: [validations.isRequired, validations.minOrEqual(0)],
        unit_cost: [validations.isRequired, validations.minOrEqual(0)],
      }
    }
  },
  watch: {
    searchBaseProduct () {
      this.handleSearchBaseProduct()
    }
  },
  created () {
    this.fetchForm()
    this.handleSearchBaseProduct = debounce(this.handleSearchBaseProduct, 400)
  }
}
</script>