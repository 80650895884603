<template lang="pug">
  Bar(:chart-data="chartData" :chart-options="options" :chart-id="id" :width="200" :height="30")
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import months from '../../../shared/months'
ChartJS.register(Title, Tooltip, BarElement, CategoryScale, LinearScale)

export default {
  props: ['datasets'],
  components: {
    Bar
  },
  data () {
    return {
      id: this._uid.toString(),
      options: {
        elements: {
          bar: {
            backgroundColor: '#4B85EC',
            borderWidth: 2
          }
        }
      }
    }
  },
  computed: {
    chartData () {
      return {
        labels: months,
        datasets: this.datasets
      }
    }
  }
}
</script>
