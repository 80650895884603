<template lang="pug">
v-card.pb-4
  v-card-title
    span Productos Base
    ShowInfoBtn(@click="$refs.infoAlert.showAlert()")
    v-spacer
    v-btn.mr-2(:disabled="!selectedItems.length" color="primary" @click="deleteSelectedItems" outlined)
        v-icon.mr-2 mdi-delete
        span Eliminar Selección
    v-btn(
      color="primary"
      :id="WIZARD_ELEMENTS_ID.BTN_CREATE_BASE_PRODUCT"
      @click="$router.push('/productos-base/nuevo'); globalEmitter(WIZARD_STEPS_EVENTS.ON_CLICK_CREATE_BASE_PRODUCT)"
    )
      v-icon.mr-2 mdi-plus-circle-outline
      span Añadir Producto
  v-card-text
    InfoAlert(:message="alertMessage" ref="infoAlert" :id="WIZARD_ELEMENTS_ID.HELP_BASE_PRODUCTS" :showInfo="$route.query.help_base_products")
  template(v-if="hasBaseProducts")
    v-simple-table(key="customer-classification")
      template(v-slot:default)
        thead(key="customer-classification-header")
          tr
            th
              v-checkbox(
                @change="toggleSelectAll"
                v-model="selectAll"
              )
            th Nombre
            th N° Atributos
            th Unidad de Medida
            th(style="width: 80px") Acciones
        tbody(key="customer-classification-body")
          tr(v-for="item in items" :key="item.id")
            td
              v-checkbox(
                v-model="item._selected"
              )
            td {{item.name}}
            td {{item.attributes.length}}
            td {{item.unit_of_measure}}
            td
              v-menu(offset-y left)
                template(v-slot:activator="{ on, attrs }")
                  v-btn(icon v-bind="attrs" v-on="on")
                    v-icon mdi-dots-vertical
                v-list
                  v-list-item(:to="`/productos-base/editar/${item.id}`")
                    v-list-item-title Editar
                  v-list-item(@click="deleteItem(item.id)")
                    v-list-item-title Eliminar
    v-pagination(v-model="pagination.page" :length="pagination.pages" @input="fetchItems")

  EmptyState(
    v-else
    title="Productos Base"
    description="Aun no tienes productos base registrados, comienza creando un nuevo producto base para organizar tus productos."
    primaryActionText="Crear Producto Base"
    imgPath="/img/empty_state_1.png"
    @click-primary-action="$router.push('/productos-base/nuevo')"
  )

  CustomerClassificationFormModal(ref="customerClassificationFormModal" @save="fetchItems")
  CustomConfirmDialog(ref="confirmDialog")
</template>

<script>
import { mapActions } from 'vuex'
import CustomerClassificationFormModal from '@/components/customers/CustomerClassificationFormModal.vue'
import CustomConfirmDialog from '@/components/common/dialogs/CustomConfirmDialog.vue'
import ShowInfoBtn from '../common/ShowInfoBtn.vue'
import InfoAlert from '../common/InfoAlert.vue'
import EmptyState from '../common/EmptyState.vue'
import TableMultipleSelectionMixin from '@/components/mixins/TableMultipleSelectionMixin.vue'
import { CASE_MODIFICATION_WARNING, WIZARD_ELEMENTS_ID, WIZARD_STEPS_EVENTS } from '@/constants'
import { globalEmitter } from '@/utils/eventBus'
export default {
  mixins: [TableMultipleSelectionMixin],
  components: {
    CustomerClassificationFormModal,
    CustomConfirmDialog,
    ShowInfoBtn,
    InfoAlert,
    EmptyState
  },
  data () {
    return {
      WIZARD_STEPS_EVENTS,
      WIZARD_ELEMENTS_ID,
      pagination: {
        page: 1,
        pages: 1,
        pageSize: 20
      },
      items: [],
      alertMessage: '¿Quieres analizar productos que comparten varios atributos en conjunto? La funcionalidad de Producto Base te facilita esta tarea. Puedes agrupar tus productos en múltiples categorías y seleccionar diversos atributos para un análisis detallado.'
    }
  },
  methods: {
    globalEmitter,
    ...mapActions('product', ['listBaseProducts', 'deleteBaseProduct', 'bulkDeleteBaseProducts']),
    async fetchItems () {
      const params = {
        page: this.pagination.page,
        page_size: this.pagination.pageSize
      }
      const { data } = await this.listBaseProducts({ params })
      this.items = data.results || []
      this.pagination.pages = Math.ceil(data.count / this.pagination.pageSize)
    },
    async deleteItem (id) {
      const confirm = await this.$refs.confirmDialog.open(
        'Eliminar producto base',
        '¿Está seguro que desea eliminar este producto base?. ' + CASE_MODIFICATION_WARNING,
        {
          okTitle: 'Eliminar',
        }
      )
      if (confirm) {
        await this.deleteBaseProduct(id)
        await this.fetchItems()
      }
    },
    async deleteSelectedItems() {
      const ok = await this.$refs.confirmDialog.open(
        'Eliminar productos',
        '¿Está seguro que desea eliminar los productos base seleccionados?. '+ CASE_MODIFICATION_WARNING,
        {
          okTitle: 'Eliminar',
          icon: 'mdi-delete'
        }
      )

      if (!ok) return

      const { error } = await this.bulkDeleteBaseProducts(this.selectedItems.map(item => item.id))
      if (error) {
        this.$toast.error('No se pudieron eliminar los productos base seleccionados')
        return
      }

      this.$toast.success('Productos base eliminados correctamente')
      this.fetchItems()
    }
  },
  computed: {
    hasBaseProducts() {
      return this.$store.state.product.hasBaseProducts
    }
  },
  async created () {
    await this.fetchItems()
    if (this.items.length === 0) {
      this.$refs.infoAlert.showAlert()
    }
  }
}
</script>