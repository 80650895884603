<template lang="pug">
div
  v-card-text
    v-row.mb-4
      v-col(cols="auto")
        h3.mb-2 Venta anual según Plan de ventas
        h1.primary--text {{ totalSales | currency }}
      v-col(cols="auto")
        h3.mb-2 Margen bruto de Plan de ventas
        h1.primary--text {{ totalProfit | currency }}
    v-row
      v-col(cols="12" md="auto")
        v-select(v-model="chartBy" :items="chartByOptions" label="Agrupar gráfico por" outlined)
        v-card-text
    Bar(:chart-data="chartData" :chart-options="chartOptions" :chart-id="id" :height="150")
</template>


<script>
import { Bar } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import { mapActions } from 'vuex'
import chartsUtils from '@/utils/chartsUtils'
ChartJS.register(Title, Tooltip, BarElement, CategoryScale, Legend, LinearScale)

export default {
  props: {
    filters: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  components: {
    Bar,
  },
  data() {
    return {
      id: this._uid.toString(),
      marketSize: 0,
      totalSales: 0,
      totalProfit: 0,
      form: {
        growthRate: 0
      },
      chartBy: 'customer',
      chartByOptions: [
        { value: 'customer', text: 'Cliente' },
        { value: 'product', text: 'Producto' }
      ],
      chartOptions: {
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true
          }
        },
        plugins: {
          legend: {
            display: true,
            position: 'top',
          },
          tooltip: {
            callbacks: {
              label: function(context) {
                return `$${context.formattedValue}`
              },
              title: function(context) {
                const label = context[0].dataset.label
                return label
              }
            }
          }
        }
      },
      chartData: {
        labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        datasets: [
          {
            label: 'Potencial en $',
            data: [],
            backgroundColor: chartsUtils.colors,
            hoverBackgroundColor: chartsUtils.colors
          }
        ]
      },
      v: {
        growthRate: [
          v => !!v || 'Este campo es requerido',
          v => v >= 0 || 'El valor debe ser mayor o igual a 0'
        ]
      }
    }
  },
  computed: {
    computedMarketSize() {
      return this.chartData.datasets[0].data.reduce((acc, value) => acc + value, 0)
    }
  },
  methods: {
    ...mapActions('commercial_projection', ['getSalePlanByCustomerChart', 'exportOptimalMix', 'getSalePlanByProductChart', 'getStatisticsSalePlan']),
    exportData() {
      this.exportOptimalMix({
        projection: this.$route.params.id,
        ...this.filters
      })
    },
    async fetchStatistics() {
      const params = {
        ...this.filters,
        projection: this.projection || this.$route.params.id
      }
      const { data, error } = await this.getStatisticsSalePlan(params)

      if (!error) {
        this.totalProfit = data.total_profit
        this.totalSales = data.total_sales
      }
    },
    async fetchData() {
      const params = {
        ...this.filters,
        projection: this.$route.params.id
      }

      this.fetchStatistics()
      const action = this.chartBy === 'customer' ? this.getSalePlanByCustomerChart : this.getSalePlanByProductChart

      const { data, error } = await action(params)

      if (!error) {
        this.chartData.datasets = data.datasets.map((dataset, index) => ({
          label: dataset.label,
          data: dataset.data,
          backgroundColor: chartsUtils.colors[index % chartsUtils.colors.length],
          hoverBackgroundColor: chartsUtils.colors[index % chartsUtils.colors.length]
        }))
      }
    },
  },
  watch: {
    filters: {
      handler() {
        this.fetchData()
      },
      deep: true,
    },
    chartBy() {
      this.fetchData()
    }
  },
  created() {
    this.fetchData()
  }
}
</script>
