import axios from '../../plugins/axios'

export const listDataStudioReports = ({ rootGetters }, { params } = {} ) => {
  
  const _params ={
    ...params,
    business_id: rootGetters['app/businessId']
  }
  return axios.get('/reports/datastudio/', { params: _params })
    .catch(err => err.response)
}

export const listPlanDashboard = ({ rootGetters }, { params } = {} ) => {
  
  const _params ={
    ...params,
    business_id: rootGetters['app/businessId']
  }
  return axios.get('/plan/dashboard/', { params: _params })
    .catch(err => err.response)
}

