<template lang="pug">
v-dialog(v-model="dialog" width="500")
  v-card
    v-card-title {{ title }}
    v-card-text
      v-form(ref="form" @submit.prevent="onSubmit")
        v-text-field(
          label="Nombre del cliente"
          v-model="form.name"
          outlined
          :rules="v.name")
        v-select(
          label="Clasificación"
          v-model="form.classification"
          outlined
          :items="classifications"
          :rules="v.classification"
          item-value="id"
          item-text="name")
        v-text-field(
          label="Dirección"
          v-model="form.address"
          outlined)
        v-autocomplete(
          label="Comuna"
          v-model="form.commune"
          outlined
          :items="communes"
          item-value="id"
          item-text="name")


        .text-right
          v-btn(color="primary" outlined @click="closeDialog" :disabled="loading").mr-2 Cancelar
          v-btn(color="primary" type="submit" :loading="loading") Guardar
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FormMixin from '@/components/mixins/FormMixin'
import validations from '@/shared/formValidations'
import { WIZARD_STEPS_EVENTS } from '@/constants'
import { globalEmitter } from '../../utils/eventBus';

export default {
  mixins: [FormMixin],
  data () {
    return {
      instanceId: null,
      form: {
        name: null,
        classification: null,
        address: null,
        commune: null,
      },
      communes: [],
      classifications: [],
      dialog: false,
    }
  },
  methods: {
    ...mapActions('customer', ['createCustomer', 'editCustomer', 'getCustomer', 'listCommunes']),
    ...mapActions('business', ['listCustomerClassifications']),
    async openDialog (instanceId) {
      this.instanceId = instanceId
      await this.fetchForm()
      this.dialog = true
    },
    closeDialog () {
      this.dialog = false
    },
    onSuccess () {
      this.dialog = false
      globalEmitter(WIZARD_STEPS_EVENTS.CLIENT_CREATED)
      this.$emit('save')
    },
    getAction () {
      return this.instanceId ? this.editCustomer : this.createCustomer
    },
    async fetchForm () {
      if (this.instanceId) {
        const { data } = await this.getCustomer(this.instanceId)
        this.form = {
          name: data.name,
          classification: data.classification,
          address: data.address,
          commune: data.commune,
        }
      }
      if (!this.communes.length) {
        const { data: communes } = await this.listCommunes()
        this.communes = communes
      }

      if (!this.classifications.length) {
        const { data: classifications } = await this.listCustomerClassifications()
        this.classifications = classifications
      }
    },
    getData () {
      const data = { data: this.form }
      if (this.instanceId) {
        data.id = this.instanceId
      }
      return data
    },
    resetForm () {
      this.form = {
        name: null,
        classification: null,
        address: null,
        commune: null,
      }
    },
    onClose () {
      this.instanceId = null
      this.resetForm()
    }
  },
  computed: {
    ...mapGetters('app_config', ['currentBusiness']),
    title () {
      return this.instanceId ? 'Editar cliente' : 'Agregar cliente'
    },
    v () {
      return {
        name: [validations.isRequired]
      }
    }
  },
  watch: {
    dialog () {
      if (!this.dialog) {
        this.onClose()
      }
    }
  }
}
</script>