<template lang="pug">
BaseListEstimatedData(
  :plan="plan"
  :listAction="currentConfig.listAction"
  :exportAction="currentConfig.exportAction"
  :title="currentConfig.title"
  :tableField="currentConfig.tableField"
)
</template>

<script>
import BaseListEstimatedData from './estimated_data/BaseListEstimatedData.vue'
import { mapActions } from 'vuex'

export default {
  props: {
    plan: {
      type: Object,
      required: true
    }
  },
  components: {
    BaseListEstimatedData
  },
  data () {
    const listDataConfig = {
      'COSTOS': {
        listAction: this.listEstimatedCost,
        exportAction: this.exportEstimatedCost,
        title: 'Costos Planificados',
        tableField: [
          {
            text: 'Fecha',
            field: 'date'
          },
          {
            text: 'Costo',
            field: 'cost_name'
          },
          {
            text: 'Costo Planificado',
            field: 'estimation'
          }
        ]
      },
      'VENTAS': {
        listAction: this.listEstimatedSales,
        exportAction: this.exportEstimatedSales,
        title: 'Ventas Planificadas',
        tableField: [
          {
            text: 'Fecha',
            field: 'date'
          },
          {
            text: 'Ventas Estimadas',
            field: 'quantity'
          },
          {
            text: 'Monto Estimado',
            field: 'estimation'
          }
        ]
      },
      'PRODUCCIÓN': {
        listAction: this.listEstimatedProduction,
        exportAction: this.exportEstimatedProduction,
        title: 'Producción Planificada',
        tableField: [
          {
            text: 'Fecha',
            field: 'date'
          },
          {
            text: 'Producción Estimada',
            field: 'estimation'
          }
        ]
      }
    }

    return {
      currentConfig: listDataConfig[this.plan.plan_type]
    }
  },
  methods: {
    ...mapActions('estimated_data', ['listEstimatedCost', 'listEstimatedSales', 'listEstimatedProduction', 'exportEstimatedProduction', 'exportEstimatedSales', 'exportEstimatedCost'])
  }
}
</script>