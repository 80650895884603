import * as actions from './actions'
import mutations from './mutations'
import * as getters from './getters'

const ProductStore = {
  namespaced: true,
  state: {
    hasProducts: false,
    hasBaseProducts: false,
    hasLineOfBusiness: false,
  },
  getters,
  mutations,
  actions
}

export default ProductStore
