<template lang="pug">
v-stepper(v-model="step" width="500")
  v-stepper-header
    v-stepper-step(step="1")
    v-divider
    v-stepper-step(step="2")
    v-divider
    v-stepper-step(step="3")
    v-divider
    v-stepper-step(step="4")
  v-stepper-items
    v-stepper-content(:step="1")
      v-form.mt-3
        v-text-field(label="Nombre de la Empresa" outlined)
        .text-right.mb-2
          v-btn(@click="step = 2" color="primary") Siguente
    v-stepper-content(:step="2")
      v-form.mt-3
        label Días laborales
        v-chip-group(v-model="days" multiple column active-class="primary--text")
          v-chip Lunes
          v-chip Martes
          v-chip Miércoles
          v-chip Jueves
          v-chip Viernes
          v-chip Sávado
          v-chip Domingo
        label Meses laborales
        v-chip-group(v-model="months" multiple column active-class="primary--text")
          v-chip Enero
          v-chip Febrero
          v-chip Marzo
          v-chip Abril
          v-chip Mayo
          v-chip Junio
          v-chip Julio
          v-chip Agosto
          v-chip Septiembre
          v-chip Octubre
          v-chip Noviembre
          v-chip Diciembre
        .text-right.mb-2
          v-btn(@click="step = 1" color="primary" outlined).mr-2 Volver
          v-btn(@click="step = 3" color="primary") Siguente
    v-stepper-content(:step="3")
      v-form
        v-text-field(outlined)
        v-textarea
    v-stepper-content(:step="4")
      v-form
        v-text-field(outlined)
        v-textarea
</template>

<script>

export default {
  data () {
    return {
      step: 1,
      days: [0, 1, 2, 3, 4],
      months: [0, 1, 2, 3, 4, 5, 6, 7, 8 ,9, 10, 11]
    }
  },
  watch: {
    days () {
      console.log(this.days)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
