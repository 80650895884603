const mutations = {
  SET_USER (state, value) {
    if (value) {
      state.user = value
      localStorage.setItem('user', JSON.stringify(value))
    } else {
      state.user = null
      localStorage.removeItem('user')
    }
  }
}

export default mutations
