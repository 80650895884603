<template lang="pug">
v-dialog(v-model="dialog" :max-width="options.width" @keydown.esc="cancel")
  v-card
    v-card-title {{ title }}
    v-card-text.text-center(v-if="options.icon")
      v-icon.m-auto(
        :color="options.iconColor"
        :size="80"
      ) {{ options.icon }}
    v-card-text
      .text-center.px-md-4(v-show="!!message" v-html="message")
    v-card-text.text-right
      v-btn.mr-2(v-if="!options.noconfirm" color="primary" @click="cancel" outlined) {{ options.cancelTitle }}
      v-btn(color="primary" @click="agree") {{ options.okTitle }}
</template>

<script>
export default {
  data () {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      options: {
        width: 450,
        zIndex: 200,
        noconfirm: false,
        icon: null,
        iconColor: 'red',
        okTitle: 'Aceptar',
        cancelTitle: 'Cancelar'
      }
    }
  },
  methods: {
    open(title, message, options) {
      this.dialog = true
      this.title = title
      this.message = message
      this.options = Object.assign(this.options, options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      });
    },
    agree() {
      this.resolve(true)
      this.dialog = false
    },
    cancel() {
      this.resolve(false)
      this.dialog = false
    }
  }
}
</script>
