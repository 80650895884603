<template lang="pug">
v-card
  v-card-title.d-flex.flex-column.align-center.w-100
    span Top 10 Productos con
    span  Mayor Potencial de Venta
  v-card-text
    v-simple-table
      template(v-slot:default)
        thead
          tr
            th Producto
            th Venta
        tbody
          tr(v-for="item in items" :key="item.id")
            td.td {{item.product_name}}
            td.td {{item.total | currency()}}
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    filters: {
      type: Object,
      required: true,
      default: () => ({})
    },
    projection: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      items: []
    }
  },
  methods: {
    ...mapActions('commercial_projection', ['getTopProductsTable']),
    async fetchData() {
      const params = {
        ...this.filters,
        projection: this.projection || this.$route.params.id
      }
      const { data, error } = await this.getTopProductsTable(params)
      if (!error) {
        this.items = data
      }
    }
  },
  watch: {
    filters: {
      handler() {
        this.fetchData()
      },
      deep: true,
    },
  },
  created() {
    this.fetchData()
  }
}
</script>

<style scoped>
.td {
  min-height: 32px !important;
  height: 32px !important;
}

</style>
