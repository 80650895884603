<template lang="pug">
div
  v-card-text
    .d-flex.justify-space-between.align-end.w-100.pr-2
      h2
        | Ubicación Potencial Comercial
        ShowInfoBtn(@click="$refs.infoAlert.showAlert()")
  v-card-text
    InfoAlert(:message="alertMessage" ref="infoAlert")
    div(id="googlemap" style="width: 100%; height: 90vh")
</template>

<script>
import settings from '../../../../settings'
import { mapActions } from 'vuex'
import InfoAlert from '../../common/InfoAlert.vue'
import ShowInfoBtn from '../../common/ShowInfoBtn.vue'

export default {
  components: {
    InfoAlert,
    ShowInfoBtn
  },
  props: {
    filters: {
      type: Object,
      required: true,
      default: () => ({})
    },
    projection: {
      type: Number,
      default: null,
    },
    marketSize: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      map: null,
      alertMessage: `
      <p>
Adicionalmente, puedes aprovechar la visualización por <b>zonas de calor en el mapa</b> para identificar las áreas con mayor potencial comercial. Esta herramienta te <b>facilitará la toma de decisiones estratégicas</b> y la <b>optimización</b> de la distribución de recursos <b>basada en datos</b> precisos y localizados.
</p>`,
    }
  },
  mounted() {
    this.createMap()
  },
  watch: {
    filters: {
      handler() {
        window.initMap()
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('commercial_projection', ['getHeatMapChart']),
    async createMap () {
      const key = settings.GOOGLE_MAPS_API_KEY

      const googleMapScript = document.createElement('SCRIPT')
      googleMapScript.setAttribute(
        'src',
        `https://maps.googleapis.com/maps/api/js?libraries=visualization&key=${key}&callback=initMap`
      )
      googleMapScript.setAttribute('defer', '')
      googleMapScript.setAttribute('async', '')
      document.head.appendChild(googleMapScript)
      this.initMap()
    },
    initMap () {
      const updateChart = this.updateHeatMap
      window.initMap = () => {
        var chile = new google.maps.LatLng(-35.66651,-71.53856);
        this.map = new google.maps.Map(document.getElementById('googlemap'), {
          center: chile,
          zoom: 4,
          mapTypeId: 'roadmap'
        });
        updateChart()
      }
    },

    async updateHeatMap () {
      const params = {
        ...this.filters,
        projection: this.projection || this.$route.params.id
      }
      const { data, error } = await this.getHeatMapChart(params)
      if (!error) {
        var heatmapData = data.map(d => {
          return {
            location: new google.maps.LatLng(d.latitude, d.longitude),
            weight: d.weight
          }
        })

        var heatmap = new google.maps.visualization.HeatmapLayer({
          data: heatmapData,
          radius: 30
        });
        heatmap.setData(heatmapData)
        heatmap.setMap(this.map)
      }
    }
  }
}
</script>
