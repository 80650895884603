import axios from 'axios'
import settings from '../../settings'

export const isAuthenticated = () => {
  return !!localStorage.getItem('accessToken')
}

export const setAuthTokens = (access,  refresh) => {
  localStorage.setItem('accessToken', access);
  localStorage.setItem('refreshToken', refresh);
}

export const getAccessToken = () => {
  return localStorage.getItem('accessToken');
}

export const getRefreshToken = () => {
  return localStorage.getItem('refreshToken');
}

export const clearAuthTokens = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
}

export const isTokenExpired = token => {
  return Date.now() >= (JSON.parse(atob(token.split('.')[1]))).exp * 1000
}

export const getCurrentBusinessId = () => {
  const businessString = localStorage.getItem('current_business')
  if (businessString) {
    return JSON.parse(businessString).id
  }
  return null
}
