<template lang="pug">
v-card.pb-4
  v-card-title
    span Clasificación de Clientes
    ShowInfoBtn(@click="$refs.infoAlert.showAlert()")
    v-spacer
    v-btn(
      color="primary"
      @click="$refs.customerClassificationFormModal.openDialog(); globalEmitter(WIZARD_STEPS_EVENTS.ON_CLICK_CREATE_CLIENT_CLASSIFICATION)"
      :id="WIZARD_ELEMENTS_ID.BTN_CREATE_CLIENT_CLASSIFICATION"
    )
      v-icon mdi-note-edit-outline
      span Nueva clasificación
  v-card-text
    InfoAlert(:message="alertMessage" ref="infoAlert" @close="handleClosedAlert()" :showInfo="$route.query.help_classification" :id="WIZARD_ELEMENTS_ID.HELP_CLIENTS_CLASSIFICATION")
  v-simple-table(v-if="!isEmpty" key="customer-classification")
    template(v-slot:default)
      thead(key="customer-classification-header")
        tr
          th Nombre
          th(style="width: 80px") Acciones
      tbody(key="customer-classification-body")
        tr(v-for="item in items" :key="item.id")
          td {{item.name}}
          td
            v-menu(offset-y left)
              template(v-slot:activator="{ on, attrs }")
                v-btn(icon v-bind="attrs" v-on="on")
                  v-icon mdi-dots-vertical
              v-list
                v-list-item(@click="$refs.customerClassificationFormModal.openDialog(item.id)")
                  v-list-item-title Editar
                v-list-item(@click="deleteItem(item.id)")
                  v-list-item-title Eliminar
  EmptyState(
    v-else
    title="Clasificación de Clientes"
    description="Aun no tienes clasificaciones de clientes registradas, comienza creando una nueva clasificación para organizar tus clientes."
    primaryActionText="Crear Clasificación"
    @click-primary-action="$refs.customerClassificationFormModal.openDialog()"
  )
  CustomerClassificationFormModal(ref="customerClassificationFormModal" @save="fetchItems")
  CustomConfirmDialog(ref="confirmDialog")
</template>

<script>
import { mapActions } from 'vuex'
import CustomerClassificationFormModal from '@/components/customers/CustomerClassificationFormModal.vue'
import CustomConfirmDialog from '@/components/common/dialogs/CustomConfirmDialog.vue'
import ShowInfoBtn from '@/components/common/ShowInfoBtn.vue'
import InfoAlert from '@/components/common/InfoAlert.vue'
import EmptyState from '../common/EmptyState.vue'
import { WIZARD_ELEMENTS_ID, WIZARD_STEPS_EVENTS } from '@/constants'
import { globalEmitter } from '@/utils/eventBus'

export default {
  components: {
    CustomerClassificationFormModal,
    CustomConfirmDialog,
    ShowInfoBtn,
    InfoAlert,
    EmptyState
  },
  data () {
    return {
      WIZARD_STEPS_EVENTS,
      WIZARD_ELEMENTS_ID,
      items: [],
      isEmpty: false,
      alertMessage: '¡Personaliza la clasificación de tus clientes según el tamaño, sector, o cualquier otro criterio relevante! Esto te permitirá filtrar y analizar tu estudio de mercado con mayor precisión, basándote en las características específicas que definas.',
      alertInfoLocalStore: 'ClasificacionClientesInfoAlert',
    }
  },
  methods: {
    ...mapActions('business', ['listCustomerClassifications', 'deleteCustomerClassification']),
    globalEmitter,
    async fetchItems () {
      const { data } = await this.listCustomerClassifications()
      this.items = data || []
      if (this.items.length !== 0) {
        this.isEmpty = false
      }
    },
    async deleteItem (id) {
      const confirm = await this.$refs.confirmDialog.open(
        'Eliminar clasificación',
        '¿Está seguro que desea eliminar esta clasificación?',
        {
          okTitle: 'Eliminar',
        }
      )
      if (confirm) {
        await this.deleteCustomerClassification(id)
        await this.fetchItems()
      }
    },
    handleClosedAlert () {
      localStorage.setItem(this.alertInfoLocalStore, true)
    }
  },
  async created () {
    await this.fetchItems()
    if (this.items.length === 0) {
      this.$refs.infoAlert.showAlert()
      this.isEmpty = true
    }
    const readIinfoAlertFirstTime = localStorage.getItem(this.alertInfoLocalStore)
    if (!readIinfoAlertFirstTime) {
      this.$refs.infoAlert.showAlert()
    }
  }
}
</script>