<template lang="pug">
div
  v-card.mb-4
    v-card-title 
      span {{plan.name}}
    v-card-subtitle Año {{plan.year}}
    v-card-text
      h4.mb-3 {{texts[plan.plan_type].estimationText}}
      h1.mb-3 {{plan.estimation | planEstimation(plan.plan_type)}}
      h2.my-3 {{texts[plan.plan_type].manthlyEstimationText}}
      MonthlyChart(:datasets="datasets")
      h2.my-3 {{texts[plan.plan_type].manthlyEstimationAcumText}}
      MonthlyChart(:datasets="acumDatasets")
</template>

<script>
import MonthlyChart from './plan_detail/MonthlyChart.vue'
import { mapActions } from 'vuex'
import { EventBus } from '../../utils/eventBus'

export default {
  props: {
    plan: {
      type: Object,
      required: true
    }
  },
  components: {
    MonthlyChart
  },
  data () {
    return {
      estimatedColor: '#4B85EC',
      realColor: '#FFC107',
      monthlyEstimate: (Array(12)).fill(0),
      monthlyRealData: (Array(12)).fill(0),
      texts: {
        'VENTAS': {
          estimationText: 'Estimación de ventas',
          manthlyEstimationText: 'Ventas mensuales planificadas',
          manthlyEstimationAcumText: 'Ventas mensuales planificadas acumuladas',
        },
        'PRODUCCIÓN': {
          estimationText: 'Estimación de producción',
          manthlyEstimationText: 'Producción mensual planificada',
          manthlyEstimationAcumText: 'Producción mensual planificada acumulada',
        },
        'COSTOS': {
          estimationText: 'Estimación de costos',
          manthlyEstimationText: 'Costos mensuales planificados',
          manthlyEstimationAcumText: 'Costos mensuales planificados acumulado',
        }
      }
    }
  },
  methods: {
    ...mapActions('estimated_data', ['getMonthlyEstimate']),
    ...mapActions('real_data', ['getMonthlyRealData']),
    async setMonthlyEstimate () {
      const monthlyEstimate = (Array(12)).fill(0)
      const response = await this.getMonthlyEstimate({ planId: this.plan.id })
      for (const estimate of response.data) {
        monthlyEstimate[estimate.date__month - 1] = estimate.estimation
      }
      this.monthlyEstimate = monthlyEstimate
    },
    async setMonthlyRealData () {
      const monthlyRealData = (Array(12)).fill(0)
      const response = await this.getMonthlyRealData({ planId: this.plan.id })
      for (const realData of response.data) {
        monthlyRealData[realData.date__month - 1] = realData.total
      }
      this.monthlyRealData = monthlyRealData
    },
    updateChart () {
      this.setMonthlyEstimate()
      this.setMonthlyRealData()
      this.$forceUpdate()
    }
  },
  computed: {
    datasets () {
      return [
        { data: this.monthlyEstimate, label: 'Estimado', backgroundColor: this.estimatedColor, borderColor: this.estimatedColor},
        { data: this.monthlyRealData, label: 'Real', backgroundColor: this.realColor, borderColor: this.realColor}
      ]
    },
    acumDatasets () {
      const estimated = []
      let sum = 0
      for (const item of this.monthlyEstimate) {
        sum += item
        estimated.push(sum)
      }
      
      const real = []
      sum = 0
      for (const item of this.monthlyRealData) {
        sum += item
        real.push(sum)
      }

      return [
        { data: estimated, label: 'Estimado', backgroundColor: this.estimatedColor, borderColor: this.estimatedColor},
        { data: real, label: 'Real', backgroundColor: this.realColor, borderColor: this.realColor}
      ]
    }
  },
  created () {
    this.updateChart()
    EventBus.$on('updateRealData', this.updateChart)
  },
  beforeDestroy () {
    EventBus.$off('updateRealData', this.updateChart)
  }
}
</script>