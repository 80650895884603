<template lang="pug">
v-card
  v-card-title
    v-row(dense)
      v-col
        span {{title}}
      v-col(cols="auto")
        v-text-field(v-model="filters.date__gte" label="Desde" type="date" hide-details outlined dense clearable)
      v-col(cols="auto")
        v-text-field(v-model="filters.date__lte" label="Hasta" type="date" hide-details outlined dense clearable)
      v-col(cols="auto")
        v-btn(@click="fetchData") filtrar
      v-col(cols="auto")
        v-btn(@click="exportData") Exportar
  v-simple-table(key="list-estimated-data")
    template(v-slot:default)
      thead(key="list-estimated-data-header")
        tr
          th(v-for="item in tableField") {{item.text}}
      tbody(key="list-estimated-data-body")
        tr(v-for="estimatedSale in estimatedSales" :key="estimatedSale.id")
          td(v-for="item in tableField") 
            label(v-if="item.field !== 'estimation'") {{estimatedSale[item.field]}}
            label(v-else) {{estimatedSale[item.field] | planEstimation(plan.plan_type)}}
  .text-center.py-4
    v-progress-circular(v-if="loading" :size="50" color="primary" indeterminate :width="7")
    label(v-else-if="!estimatedSales.length") No se encontraron datos registrados
  .text-center.py-4
    v-pagination(v-model="currentPage" :length="paginationLength")
</template>

<script>
import { mapActions } from 'vuex'
import PaginationMixin from '../../../mixins/PaginationMixin.vue'
import fileDownload from 'js-file-download'

export default {
  mixins: [PaginationMixin],
  props: {
    plan: {
      type: Object,
      required: true
    },
    listAction: {
      type: Function,
      required: true
    },
    exportAction: {
      type: Function,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    tableField: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      filters: {},
      estimatedSales: []
    }
  },
  methods: {
    ...mapActions('estimated_data', ['listEstimatedCost']),
    async fetchData () {
      this.loading = true
      this.estimatedSales = []
      const params = this.getFilters()

      if (this.plan) {
        params.plan_id = this.plan.id
      }
      const response = await this.listAction({ params })
      this.estimatedSales = response.data.results
      this.itemCount = response.data.count
      this.loading = false
    },
    async exportData () {
      const params = this.getFilters()

      if (this.plan) {
        params.plan_id = this.plan.id
      }
      const response = await this.exportAction({ params })
      fileDownload(response.data, 'resultados.xlsx');
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
