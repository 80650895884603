<template lang="pug">
v-form(ref="form" @submit.prevent="onSubmit")
  .text-h5.my-4.text-center.font-weight-bold Bienvenido de nuevo!
  v-alert(v-if="errors.length" dense outlined type="error" rounded).rounded-pill
    div(v-for="error in errors") {{error}}
  v-text-field(v-model="form.username" label="Correo" outlined rounded :rules="v.username")
  v-text-field(v-model="form.password" label="Contraseña" outlined type="password" rounded :rules="v.password")
  v-btn(type="submit" block color="primary" large rounded :loading="loading") Entrar
</template>

<script>
import { mapActions } from 'vuex'
import validations from '../../shared/formValidations'
import FromMixn from '../mixins/FormMixin.vue'

export default {
  mixins: [FromMixn],
  data () {
    return {
      form: {
        username: null,
        password: null
      },
      errors: []
    }
  },
  methods: {
    ...mapActions('auth', ['login']),
    onError () {
      this.errors = ['Las credenciales son incorrectas']
    },
    onSuccess () {
      this.$router.push('/')
    },
    getAction () {
      return this.login
    }
  },
  computed: {
    v () {
      return {
        username: [validations.isRequired],
        password: [validations.isRequired]
      }
    }
  }
}
</script>
