import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import ReportView from '../views/ReportView'
import SigninView from '../views/SigninView'
import SignupView from '../views/SignupView'
import FirstLoginView from '../views/FirstLoginView'
import PlanView from '../views/PlanView'
import PlanFormView from '../views/PlanFormView'
import PlanDetailView from '../views/PlanDetailView'
import UserFormView from '../views/UserFormView'
import BusinessConfigView from '../views/BusinessConfigView'
import BusinessInvitationView from '../views/BusinessInvitationView'
import PlanEditFormView from '../views/PlanEditFormView'
import CustomersView from '../views/CustomersView.vue'
import ProductsView from '../views/ProductsView.vue'
import BaseProductsView from '../views/BaseProductsView.vue'
import BaseProductFormView from '../views/BaseProductFormView.vue'
import ProductFormView from '../views/ProductFormView.vue'
import CommercialProjectionView from '../views/CommercialProjectionView.vue'
import CommercialProjectionItemsView from '../views/CommercialProjectionItemsView.vue'
import CommercialProjectionDashboardView from '../views/CommercialProjectionDashboardView.vue'
import ProductionCapacityView from '../views/ProductionCapacityView.vue'
import CommercialProjectionItemFormView from  '../views/CommercialProjectionItemFormView.vue'

import { isAuthenticated } from '../utils/authUtils'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/reportes',
    name: 'Reportes',
    component: ReportView
  },
  {
    path: '/proyeccion-comercial',
    name: 'Proyección Comercial',
    component: CommercialProjectionView
  },
  {
    path: '/proyeccion-comercial/:id/items',
    name: 'Proyección Comercial Items',
    component: CommercialProjectionItemsView
  },
  {
    path: '/proyeccion-comercial/:id/items/form',
    name: 'Proyección Comercial Items Formulario',
    component: CommercialProjectionItemFormView
  },
  {
    path: '/proyeccion-comercial/:id/dashboard',
    name: 'Proyección Comercial Dashboard',
    component: CommercialProjectionDashboardView
  },
  {
    path: '/clientes',
    name: 'Clientes',
    component: CustomersView
  },
  {
    path: '/productos',
    name: 'Productos',
    component: ProductsView
  },
  {
    path: '/capacidad-produccion',
    name: 'Capacidad de Producción',
    component: ProductionCapacityView
  },
  {
    path: '/productos-base',
    name: 'Productos Base',
    component: BaseProductsView
  },
  {
    path: '/productos-base/editar/:id',
    name: 'Editar Producto Base',
    component: BaseProductFormView
  },
  {
    path: '/productos-base/nuevo',
    name: 'Nuevo Producto Base',
    component: BaseProductFormView
  },
  {
    path: '/productos/editar/:id',
    name: 'Editar Producto',
    component: ProductFormView
  },
  {
    path: '/productos/nuevo',
    name: 'Nuevo Producto',
    component: ProductFormView
  },
  {
    path: '/planes',
    name: 'Planes',
    component: PlanView
  },
  {
    path: '/planes/:planId',
    name: 'Detalle Plan',
    component: PlanDetailView
  },
  {
    path: '/nuevo-plan/:planType',
    name: 'Nuevo Plan',
    component: PlanFormView
  },
  {
    path: '/planes/editar/:planId',
    name: 'Editar Plan',
    component: PlanEditFormView
  },
  {
    path: '/negocio',
    name: 'Organización',
    component: BusinessConfigView
  },
  {
    path: '/nuevo-usuario',
    name: 'Nuevo Usuarios',
    component: UserFormView
  },
  {
    path: '/primeros-pasos',
    name: 'First Login',
    component: FirstLoginView,
    meta: {
      layout: 'EmptyLayout'
    }
  },
  {
    path: '/invitacion/:token',
    name: 'Invitación',
    component: BusinessInvitationView,
    meta: {
      layout: 'EmptyLayout',
      public: true
    }
  },
  // Auth
  {
    path: '/login',
    name: 'Login',
    component: SigninView,
    meta: {
      layout: 'EmptyLayout',
      public: true,
      unauthenticatedOnly: true
    }
  },
  {
    path: '/registro',
    name: 'Registro Usuario',
    component: SignupView,
    meta: {
      layout: 'EmptyLayout',
      public: true,
      unauthenticatedOnly: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (!to.meta.public && !isAuthenticated()) {
    next('/login')
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  if (to.meta.unauthenticatedOnly && isAuthenticated()) {
    next('/')
  } else {
    next()
  }
})

export default router
