<template lang="pug">
ConfirmDialog(
  ref="confirmDialog",
  title="Eliminar Registro",
  text="¿Está seguro que desea eliminar este registro? Esta acción no se puede deshacer."
  @close="close"
  @confirm="confirm"
)
</template>

<script>
import ConfirmDialog from '../../common/dialogs/ConfirmDialog.vue'
import { mapActions } from 'vuex'
import { EventBus } from '../../../utils/eventBus'

export default {
  components: {
    ConfirmDialog
  },
  data () {
    return {
      realDataId: null,
      planType: null,
    }
  },
  methods: {
    ...mapActions('real_data', ['deleteRealCost', 'deleteRealProduction', 'deleteRealSales']),
    open (realDataId, planType) {
      this.realDataId = realDataId
      this.planType = planType
      this.$refs.confirmDialog.open()
    },
    close (response) {
      this.$emit('close', response)
    },
    getActionByPlanType () {
      switch (this.planType) {
        case 'COSTOS':
          return this.deleteRealCost
        case 'PRODUCCIÓN':
          return this.deleteRealProduction
        case 'VENTAS':
          return this.deleteRealSales
        default:
          return null
      }
    },
    async confirm () {
      const action = this.getActionByPlanType()
      const response = await action({ realDataId: this.realDataId })

      if (response.status === 200 || response.status === 204) {
        this.$emit('confirm', true)
        this.$emit('save', response)
        EventBus.$emit('updateRealData', this.planId)
      } else {
        this.$emit('confirm', false)
      }
    }
  }
}
</script>