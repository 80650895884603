<template lang="pug">
v-form(ref="form")
  v-card
    v-card-title Plan de Costos
    v-card-subtitle {{description}}
    v-card-text
      v-text-field(v-model="form.name" label="Nombre del plan" outlined :rules="v.name")
      v-select(v-model="form.year" label="Año de ejecución del Plan" outlined :items="[new Date().getFullYear(), new Date().getFullYear() + 1]")
      v-text-field(v-model="form.error_range" label="Margen de error (se sugiere un 5% para sortear imprevistos, feriados u otros aspectos del negocio, ese valor puede variar según la realidad de cada empresa)" outlined type="number" :min="0" max="50" suffix="%")
      
      //- Lista de costos
      .cost-list
        h2.mb-2 Lista de Costos
        div.mb-4 Agregue los costos que desea contemplar en su plan, en la guía de implementación de planes de costos contará con un listado de costos típicos de las empresas, el no incorporar alguno de ellos le puede ayudar a cuestionarse la necesidad de considerarlo.
        v-card.mb-4(v-for="(cost, index) in form.cost_items" color="#f7f7f7")
          v-card-text
            .cost-item-remove-btn(v-if="form.cost_items.length !== 1")
              v-btn(icon @click="removeCost(index)" color="red")
                v-icon(size="30") mdi-close-circle-outline
            h4.mb-3 Costo Nº{{index + 1}}
            v-combobox(v-model="cost.name" label="Nombre del costo" outlined :rules="v.cost_items.name" :items="costAutocomplete")
            v-select.mb-1(v-model="cost.variable_cost" @change="onChangeVariableCost(cost)" :items="costTypes" label="Tipo de costo" outlined hide-details)
            .mb-4 {{costTypesDescription[cost.variable_cost.toString()]}}

            //- Es costo Fijo
            div(v-if="!cost.variable_cost")
              h4.mb-4 Distribución Mensual
              v-currency-field(:value="0" @change="onChangeEstimation(cost.cost_item_monthly_estimate, $event)" label="Estimación mensual para este costo" outlined type="number" :min="0" prefix="$" )
              .mb-4
                strong Costos Por mes: 
                label En caso que el costo varía durante el mes se puede llenar mes a mes el valor, en caso contrario completar el valor mensual fijo en la pregunta anterior.
              v-row(dense)
                v-col(cols="6" md="2" lg="auto")
                  v-currency-field(v-model="cost.cost_item_monthly_estimate.january" label="Enero" outlined type="number" :min="0"  prefix="$" hide-details)
                v-col(cols="6" md="2" lg="auto")
                  v-currency-field(v-model="cost.cost_item_monthly_estimate.february" label="Febrero" outlined type="number" :min="0"  prefix="$" hide-details)
                v-col(cols="6" md="2" lg="auto")
                  v-currency-field(v-model="cost.cost_item_monthly_estimate.march" label="Marzo" outlined type="number" :min="0"  prefix="$" hide-details)
                v-col(cols="6" md="2" lg="auto")
                  v-currency-field(v-model="cost.cost_item_monthly_estimate.april" label="Abril" outlined type="number" :min="0"  prefix="$" hide-details)
                v-col(cols="6" md="2" lg="auto")
                  v-currency-field(v-model="cost.cost_item_monthly_estimate.may" label="Mayo" outlined type="number" :min="0"  prefix="$" hide-details)
                v-col(cols="6" md="2" lg="auto")
                  v-currency-field(v-model="cost.cost_item_monthly_estimate.june" label="Junio" outlined type="number" :min="0"  prefix="$" hide-details)
                v-col(cols="6" md="2" lg="auto")
                  v-currency-field(v-model="cost.cost_item_monthly_estimate.july" label="Julio" outlined type="number" :min="0"  prefix="$" hide-details)
                v-col(cols="6" md="2" lg="auto")
                  v-currency-field(v-model="cost.cost_item_monthly_estimate.august" label="Agosto" outlined type="number" :min="0"  prefix="$" hide-details)
                v-col(cols="6" md="2" lg="auto")
                  v-currency-field(v-model="cost.cost_item_monthly_estimate.september" label="Septiembre" outlined type="number" :min="0"  prefix="$" hide-details)
                v-col(cols="6" md="2" lg="auto")
                  v-currency-field(v-model="cost.cost_item_monthly_estimate.october" label="Octubre" outlined type="number" :min="0"  prefix="$" hide-details)
                v-col(cols="6" md="2" lg="auto")
                  v-currency-field(v-model="cost.cost_item_monthly_estimate.november" label="Noviembre" outlined type="number" :min="0"  prefix="$" hide-details)
                v-col(cols="6" md="2" lg="auto")
                  v-currency-field(v-model="cost.cost_item_monthly_estimate.december" label="Diciembre" outlined type="number" :min="0"  prefix="$" hide-details)

            //- Es un costo Variable
            div(v-else)
              v-select(v-model="cost.variable_cost_plan" @change="onChangeVariableCost(cost)" :items="variablePlanOptions" label="Plan asociado al costo variable" outlined)            
              label.mb-3.d-block {{planTypes[currentPlan].monthlyEstimateText}}
              div(v-if="cost.variable_cost_plan && getPlanType(cost.variable_cost_plan) === 'VENTAS'")
                v-text-field(:value="0" @change="onChangeEstimation(cost.cost_item_monthly_estimate, $event)" label="Estimación mensual para este costo" outlined type="number" :min="0" max="100" suffix="%" )
                label.mb-4.d-block Porcentaje de las ventas que se considerará para este costo
                v-row(dense)
                  v-col(cols="6" md="2" lg="auto")
                    v-text-field(v-model="cost.cost_item_monthly_estimate.january" label="Enero" outlined type="number" :min="0" max="100" suffix="%")
                  v-col(cols="6" md="2" lg="auto")
                    v-text-field(v-model="cost.cost_item_monthly_estimate.february" label="Febrero" outlined type="number" :min="0" max="100" suffix="%")
                  v-col(cols="6" md="2" lg="auto")
                    v-text-field(v-model="cost.cost_item_monthly_estimate.march" label="Marzo" outlined type="number" :min="0" max="100" suffix="%")
                  v-col(cols="6" md="2" lg="auto")
                    v-text-field(v-model="cost.cost_item_monthly_estimate.april" label="Abril" outlined type="number" :min="0" max="100" suffix="%")
                  v-col(cols="6" md="2" lg="auto")
                    v-text-field(v-model="cost.cost_item_monthly_estimate.may" label="Mayo" outlined type="number" :min="0" max="100" suffix="%")
                  v-col(cols="6" md="2" lg="auto")
                    v-text-field(v-model="cost.cost_item_monthly_estimate.june" label="Junio" outlined type="number" :min="0" max="100" suffix="%")
                  v-col(cols="6" md="2" lg="auto")
                    v-text-field(v-model="cost.cost_item_monthly_estimate.july" label="Julio" outlined type="number" :min="0" max="100" suffix="%")
                  v-col(cols="6" md="2" lg="auto")
                    v-text-field(v-model="cost.cost_item_monthly_estimate.august" label="Agosto" outlined type="number" :min="0" max="100" suffix="%")
                  v-col(cols="6" md="2" lg="auto")
                    v-text-field(v-model="cost.cost_item_monthly_estimate.september" label="Septiembre" outlined type="number" :min="0" max="100" suffix="%")
                  v-col(cols="6" md="2" lg="auto")
                    v-text-field(v-model="cost.cost_item_monthly_estimate.october" label="Octubre" outlined type="number" :min="0" max="100" suffix="%")
                  v-col(cols="6" md="2" lg="auto")
                    v-text-field(v-model="cost.cost_item_monthly_estimate.november" label="Noviembre" outlined type="number" :min="0" max="100" suffix="%")
                  v-col(cols="6" md="2" lg="auto")
                    v-text-field(v-model="cost.cost_item_monthly_estimate.december" label="Diciembre" outlined type="number" :min="0" max="100" suffix="%")
              div(v-if="cost.variable_cost_plan && getPlanType(cost.variable_cost_plan) === 'PRODUCCIÓN'")
                v-currency-field(:value="0" @change="onChangeEstimation(cost.cost_item_monthly_estimate, $event)" label="Estimación mensual para este costo" outlined type="number" :min="0" prefix="$" )
                label.mb-4.d-block Costos unitario de producción por mes
                v-row(dense)
                  v-col(cols="6" md="2" lg="auto")
                    v-currency-field(v-model="cost.cost_item_monthly_estimate.january" label="Enero" outlined type="number" :min="0" prefix="$")
                  v-col(cols="6" md="2" lg="auto")
                    v-currency-field(v-model="cost.cost_item_monthly_estimate.february" label="Febrero" outlined type="number" :min="0" prefix="$")
                  v-col(cols="6" md="2" lg="auto")
                    v-currency-field(v-model="cost.cost_item_monthly_estimate.march" label="Marzo" outlined type="number" :min="0" prefix="$")
                  v-col(cols="6" md="2" lg="auto")
                    v-currency-field(v-model="cost.cost_item_monthly_estimate.april" label="Abril" outlined type="number" :min="0" prefix="$")
                  v-col(cols="6" md="2" lg="auto")
                    v-currency-field(v-model="cost.cost_item_monthly_estimate.may" label="Mayo" outlined type="number" :min="0" prefix="$")
                  v-col(cols="6" md="2" lg="auto")
                    v-currency-field(v-model="cost.cost_item_monthly_estimate.june" label="Junio" outlined type="number" :min="0" prefix="$")
                  v-col(cols="6" md="2" lg="auto")
                    v-currency-field(v-model="cost.cost_item_monthly_estimate.july" label="Julio" outlined type="number" :min="0" prefix="$")
                  v-col(cols="6" md="2" lg="auto")
                    v-currency-field(v-model="cost.cost_item_monthly_estimate.august" label="Agosto" outlined type="number" :min="0" prefix="$")
                  v-col(cols="6" md="2" lg="auto")
                    v-currency-field(v-model="cost.cost_item_monthly_estimate.september" label="Septiembre" outlined type="number" :min="0" prefix="$")
                  v-col(cols="6" md="2" lg="auto")
                    v-currency-field(v-model="cost.cost_item_monthly_estimate.october" label="Octubre" outlined type="number" :min="0" prefix="$")
                  v-col(cols="6" md="2" lg="auto")
                    v-currency-field(v-model="cost.cost_item_monthly_estimate.november" label="Noviembre" outlined type="number" :min="0" prefix="$")
                  v-col(cols="6" md="2" lg="auto")
                    v-text-field(v-model="cost.cost_item_monthly_estimate.december" label="Diciembre" outlined type="number" :min="0" prefix="$")
        .mb-4.text-right
          v-btn(@click="addCost" color="primary" rounded) + Agregar Costo
      .text-right
        v-btn(color="primary" rounded outlined to="/planes" :loading="loading").mr-4 Cancelar
        v-btn(color="primary" rounded :loading="loading" @click="onSubmit") Guardar Plan
</template>

<script>
import { mapActions } from 'vuex'
import validations from '../../../shared/formValidations'
import FormMixin from '../../mixins/FormMixin.vue'

export default {
  props: {
    plan: {
      type: Object
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  mixins: [FormMixin],
  data () {
    return {
      description: 'El plan de costos es una herramienta que te ayuda a planificar los costos de tu empresa.',
      costTypesDescription: {
        false: 'Costo fijo: Son los costos que no dependen de ningún factor variables como producción o ventas, por ejemplo el arriendo de una oficina paga el mismo valor independiente que se venda más o menos.',
        true: 'Costo variable: Son los costos que dependen de la producción o ventas, por ejemplo si yo le pago una comisión a los vendedores por cada producto vendido, debo destinar un porcentaje del valor de la venta para solventar ese costo. Por ejemplo, el 5% de la venta se destina para pagar comisión y eso hará que dependa del Plan de ventas.'
      },
      costAutocomplete: [
        'Costos operativos variables',
        'Depreciación',
        'Gastos de mantención',
        'Costos indirectos de producción',
        'Mano de obra directa',
        'Meteria prima',
        'Otros gastos de producción',
        'Suministros básicos (agua, luz, Internet, teléfono, calefacción)',
        'Pago de administrativos, contables, asesores, consultores',
        'Equipos de computación, software, impresoras',
        'Materiales de oficina',
        'Desarrollo de sitio web',
        'Marketing y publicidad',
        'Impuestos, aranceles y otros trámites administrativos',
        'Servicios y trámites legales',
        'Mantenimiento de equipos',
        'Mantenimiento de vehículos',
        'Gastos de limpieza',
        'Pago de seguros',
        'Gastos imprevistos',
        'Gastos de viaje',
        'Transporte de los productos',
        'Productos comprados para su reventa',
        'Pago de servicios de cobranza',
        'Comisión del factoring'
      ],
      variablePlans: [],
      costTypes: [
        {
          text: 'Costo fijo',
          value: false,
        },
        {
          text: 'Costo variable',
          value: true,
        }
      ],
      variablePlanOptions: [],
      currentPlan: this.$route.params.planType || 'ventas',
      planTypes: {
        ventas: {
          formName: 'Nuevo Plan de Ventas',
          estimationText: 'Estimación de ventas totales durante el año planificado',
          monthlyEstimateText: 'Distribución mensual de ventas planificadas',
          estimationPrefix: '$',
          action: this.createSalePlan
        },
        costos: {
          formName: 'Nuevo Plan de Costos',
        },
        produccion: {
          formName: 'Nuevo Plan de Producción',
          estimationText: 'Estimación de Producción total durante el año planificado',
          monthlyEstimateText: 'Distribución mensual de producción planificada',
          action: this.createProductionPlan
        }
      },
      loading: false,
      form: {
        name: 'Nuevo plan',
        year: new Date().getFullYear(),
        error_range: 5,
        cost_items: [this.getBaseCost()]
      },
      v: {
        name: [validations.isRequired],
        year: [validations.isRequired],
        cost_items: {
          name: [validations.isRequired],
          cost_item_monthly_estimate: [validations.isRequired, ]
        }
      }
    }
  },
  methods: {
    ...mapActions('plan', ['createCostPlan', 'listPlan', 'updatePlan']),
    onSuccess (response) {
      const planId = response.data.id
      this.$router.push(`/planes/${planId}`)
    },
    addCost () {
      this.form.cost_items.push(this.getBaseCost())
    },
    getData () {
      return { data: this.form, planId: this.plan?.id || null}
    },
    getAction () {
      if (this.edit) {
        return this.updatePlan
      }
      return this.createCostPlan
    },
    removeCost (index) {
      this.form.cost_items.splice(index, 1)
    },
    getPlanType (planId) {
      if (!planId) {
        return null
      }
      const plan = this.variablePlans.find(p => p.id === planId)
      return plan.plan_type
    },
    getBaseCost () {
      return {
        name: null,
        variable_cost: false,
        estimation: 0,
        variable_cost_plan: null,
        cost_item_monthly_estimate: {
          january: 0,
          february: 0,
          march: 0,
          april: 0,
          may: 0,
          june: 0,
          july: 0,
          august: 0,
          september: 0,
          october: 0,
          november: 0,
          december: 0
        }
      }
    },
    onChangeVariableCost (cost) {
      cost.cost_item_monthly_estimate.january = 0
      cost.cost_item_monthly_estimate.february = 0
      cost.cost_item_monthly_estimate.march = 0
      cost.cost_item_monthly_estimate.april = 0
      cost.cost_item_monthly_estimate.may = 0
      cost.cost_item_monthly_estimate.june = 0
      cost.cost_item_monthly_estimate.july = 0
      cost.cost_item_monthly_estimate.august = 0
      cost.cost_item_monthly_estimate.september = 0
      cost.cost_item_monthly_estimate.october = 0
      cost.cost_item_monthly_estimate.november = 0
      cost.cost_item_monthly_estimate.december = 0
    },
    onChangeEstimation (months, value) {
      if (typeof value === 'string') {
        value = value.split('.').join('')
        value = parseInt(value)
      }
      months.january = value
      months.february = value
      months.march = value
      months.april = value
      months.may = value
      months.june = value
      months.july = value
      months.august = value
      months.september = value
      months.october = value
      months.november = value
      months.december = value
    },
    getEstimatedPercentage (months) {
      let result = 0
      result += parseInt(months.january) || 0
      result += parseInt(months.february) || 0
      result += parseInt(months.march) || 0
      result += parseInt(months.april) || 0
      result += parseInt(months.may) || 0
      result += parseInt(months.june) || 0
      result += parseInt(months.july) || 0
      result += parseInt(months.august) || 0
      result += parseInt(months.september) || 0
      result += parseInt(months.october) || 0
      result += parseInt(months.november) || 0
      result += parseInt(months.december) || 0
      return result
    },
    async setVariablePlanOptions () {
      const response = await this.listPlan({ params: { 'max_size': 100 } })

      if (response.status === 200) {
        this.variablePlans = response.data.results
        this.variablePlanOptions = this.variablePlans.reduce((acum, item) => item.plan_type !== 'COSTOS' ? acum.concat([{ text: item.name, value: item.id }]) : acum, [])
      }
    }
  },
  created () {
    if (this.edit) {
      this.form = this.plan
    }
    this.setVariablePlanOptions()
  }
}
</script>

<style lang="scss" scoped>
.cost-item {
  padding-top: 20px;
  position: relative;
}

.cost-item-remove-btn {
  position: absolute;
  font-size: 20px;
  top: 5px;
  right: 5px;
}
</style>