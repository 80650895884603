<template lang="pug">
v-card
  v-card-title Nuevo Usuario
  v-card-text
    v-form(ref="form" @submit.prevent="onSubmit")
      v-text-field(v-model="form.first_name" label="Nombre" outlined)
      v-text-field(v-model="form.last_name" label="Apellido" outlined)
      v-text-field(v-model="form.email" label="Correo" outlined)
      v-text-field(v-model="form.password1" label="Contraseña" outlined type="password")
      v-text-field(v-model="form.password2" label="Repetir Contraseña" outlined type="password")
      v-select(v-model="form.role" label="Rol" outlined :items="roleOptios")
      .text-right
        v-btn(to="/usuarios" rounded color="primary" outlined).mr-4 Cancelar
        v-btn(to="/usuarios" rounded color="primary" type="submit") Crear Usuario
</template>

<script>
export default {
  data () {
    return {
      roleOptios: [
        {
          text: 'Colaborador',
          value: 'COLABORADOR'
        },
        {
          text: 'Administrador',
          value: 'ADMINISTRADOR'
        }
      ],
      form: {
        first_name: null,
        last_name: null,
        email: null,
        password1: null,
        password2: null,
        role: 'COLABORADOR'
      }
    }
  }
}
</script>