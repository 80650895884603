<template lang="pug">
v-card
  v-tabs(v-model="currentTab" grow)
    v-tab(v-for="item in tabItems" :key="item") {{item}}
  v-tabs-items(v-model="currentTab")
    v-tab-item(:key="tabItems[0]")
      PlanRealData(:plan="plan")
    v-tab-item(:key="tabItems[1]")
      PlanEstimatedData(:plan="plan")
</template>

<script>
import PlanRealData from './plan_detail/PlanRealData.vue'
import PlanEstimatedData from './plan_detail/PlanEstimatedData.vue'

export default {
  components: {
    PlanRealData,
    PlanEstimatedData
  },
  props: {
    plan: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      currentTab: 0,
      tabItems: [
        'Datos Reales',
        'Datos Planificados'
      ]
    }
  }
}
</script>