<template lang="pug">
v-dialog(v-model="dialog" width="500")
  template(v-slot:activator="{ on, attrs }")
    v-btn(v-bind="attrs" v-on="on" color="primary") Invitar Usuario
  v-card
    v-card-title Invitar usuario
    v-card-text
      v-form(ref="form" @submit.prevent="onSubmit")
        v-text-field(
          label="Email"
          v-model="form.user_email"
          outlined
          :rules="v.user_email")
        v-select(v-model="form.role" :items="rolesOptions" label="Rol" outlined :rules="v.role")
        .text-right
          v-btn(color="primary" outlined @click="cancel" :disabled="loading").mr-2 Cancelar
          v-btn(color="primary" type="submit" :loading="loading") Guardar
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FormMixin from '@/components/mixins/FormMixin'
import validations from '@/shared/formValidations'
import roles from '@/shared/userRoles'

export default {
  mixins: [FormMixin],
  data () {
    return {
      rolesOptions: roles,
      form: {
        user_email: null,
        role: roles[0].value,
      },
      dialog: false,
    }
  },
  methods: {
    ...mapActions('business', ['createBusinessInvitation']),
    openDialog () {
      this.dialog = true
    },
    closeDialog () {
      this.dialog = false
    },
    cancel () {
      this.$refs.form.reset()
      this.dialog = false
    },
    onSuccess () {
      this.dialog = false
      this.$refs.form.reset()
      this.$emit('save')
    },
    getAction () {
      return this.createBusinessInvitation
    },
    getData () {
      return { data: { business: this.currentBusiness.id, ...this.form}}
    }
  },
  computed: {
    ...mapGetters('app_config', ['currentBusiness']),
    v () {
      return {
        user_email: [validations.isEmail, validations.isRequired],
        role: [validations.isRequired],
      }
    }
  },
}
</script>