<template lang="pug">
BaseListRealData(
  :plan="plan"
  :listAction="currentConfig.listAction"
  :exportAction="currentConfig.exportAction"
  :title="currentConfig.title"
  :tableField="currentConfig.tableField"
  :importAction="currentConfig.importAction"
)
</template>

<script>
import BaseListRealData from './real_data/BaseListRealData.vue'
import { mapActions } from 'vuex'

export default {
  props: {
    plan: {
      type: Object,
      required: true
    }
  },
  components: {
    BaseListRealData
  },
  data () {
    const listDataConfig = {
      'COSTOS': {
        listAction: this.listRealCost,
        importAction: this.importRealCost,
        exportAction: this.exportRealCost,
        title: 'Costos Reales',
        tableField: [
          {
            text: 'Fecha',
            field: 'date'
          },
          {
            text: 'Nombre Costo',
            field: 'cost_name'
          },
          {
            text: 'Costo',
            field: 'cost',
            currency: true
          }
        ]
      },
      'VENTAS': {
        listAction: this.listRealSales,
        importAction: this.importRealSales,
        exportAction: this.exportRealSales,
        title: 'Ventas Reales',
        tableField: [
          {
            text: 'Fecha',
            field: 'date'
          },
          {
            text: 'Cantidad',
            field: 'quantity'
          },
          {
            text: 'Monto Total',
            field: 'total_price',
            currency: true
          }
        ]
      },
      'PRODUCCIÓN': {
        listAction: this.listRealProduction,
        exportAction: this.exportRealProduction,
        importAction: this.importRealProduction,
        title: 'Producción Real',
        tableField: [
          {
            text: 'Fecha',
            field: 'date'
          },
          {
            text: 'Cantidad',
            field: 'quantity'
          },
          {
            text: 'Producto',
            field: 'product_name'
          }
        ]
      }
    }

    return {
      currentConfig: listDataConfig[this.plan.plan_type]
    }
  },
  methods: {
    ...mapActions('real_data', ['listRealSales', 'listRealProduction', 'listRealCost', 'exportRealSales', 'exportRealProduction', 'exportRealCost',
      'importRealSales', 'importRealProduction', 'importRealCost'])
  }
}
</script>