<template lang="pug">
v-container#first-login(fluid)
  FirstLoginForm(width="500px")
</template>

<script>
import FirstLoginForm from '../components/first_login/FirstLoginForm.vue'

export default {
  components: {
    FirstLoginForm
  }
}
</script>

<style scoped lang="scss">
#first-login {
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
