<template lang="pug">
v-dialog(v-model="dialog" width="500")
  v-card
    v-card-title {{ title }}
    v-card-text
      v-form(ref="form" @submit.prevent="onSubmit")
        v-text-field(
          label="Nombre de clasificación"
          v-model="form.name"
          outlined
          :rules="v.name")
        .text-right
          v-btn(color="primary" outlined @click="closeDialog" :disabled="loading").mr-2 Cancelar
          v-btn(color="primary" type="submit" :loading="loading") Guardar
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FormMixin from '@/components/mixins/FormMixin'
import validations from '@/shared/formValidations'
import { EventBus } from '@/utils/eventBus'
import { WIZARD_STEPS_EVENTS } from '../../constants';

export default {
  mixins: [FormMixin],
  data () {
    return {
      instanceId: null,
      form: {
        name: null,
      },
      dialog: false,
    }
  },
  methods: {
    ...mapActions('business', ['createCustomerClassification', 'editCustomerClassification', 'getCustomerClassification']),
    async openDialog (instanceId) {
      this.instanceId = instanceId
      await this.fetchForm()
      this.dialog = true
    },
    closeDialog () {
      this.dialog = false
    },
    onSuccess () {
      this.dialog = false
      EventBus.$emit(WIZARD_STEPS_EVENTS.CLIENT_CLASSIFICATION_CREATED)
      this.$emit('save')
    },
    getAction () {
      return this.instanceId ? this.editCustomerClassification : this.createCustomerClassification
    },
    async fetchForm () {
      if (this.instanceId) {
        const { data } = await this.getCustomerClassification(this.instanceId)
        this.form = {
          name: data.name,
        }
      }
    },
    getData () {
      const data = { data: this.form }
      if (this.instanceId) {
        data.id = this.instanceId
      }
      return data
    },
    resetForm () {
      this.form = {
        name: null,
      }
    },
    onClose () {
      this.instanceId = null
      this.resetForm()
    }
  },
  computed: {
    ...mapGetters('app_config', ['currentBusiness']),
    title () {
      return this.instanceId ? 'Editar clasificación de cliente' : 'Crear clasificación de cliente'
    },
    v () {
      return {
        name: [validations.isRequired]
      }
    }
  },
  watch: {
    dialog () {
      if (!this.dialog) {
        this.onClose()
      }
    }
  }
}
</script>