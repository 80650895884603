import axios from '../../plugins/axios'

export const listEstimatedSales = (_, { params } = {} ) => {
  return axios.get('/estimated-data/estimated-sale/', { params })
    .catch(err => err.response)
}

export const listEstimatedProduction = (_, { params } = {} ) => {
  return axios.get('/estimated-data/estimated-production/', { params })
    .catch(err => err.response)
}

export const listEstimatedCost= (_, { params } = {} ) => {
  return axios.get('/estimated-data/estimated-cost/', { params })
    .catch(err => err.response)
}

export const exportEstimatedSales = (_, { params } = {} ) => {
  return axios.get('/estimated-data/estimated-sale/export/', { params, responseType: 'blob' })
    .catch(err => err.response)
}

export const exportEstimatedProduction = (_, { params } = {} ) => {
  return axios.get('/estimated-data/estimated-production/export/', { params, responseType: 'blob' })
    .catch(err => err.response)
}

export const exportEstimatedCost= (_, { params } = {} ) => {
  return axios.get('/estimated-data/estimated-cost/export/', { params, responseType: 'blob' })
    .catch(err => err.response)
}

export const getMonthlyEstimate = (_, { planId } = {} ) => {
  return axios.get(`/plan/${planId}/monthly_estimate/`)
    .catch(err => err.response)
}