<template lang="pug">
v-dialog(v-model="dialog" width="600")
  v-card(v-if="!submited")
    v-card-title {{ title }}
    v-card-text
      v-form(ref="form" @submit.prevent="onSubmit")
        div.mb-2 Selecciona el archivo Excel para cargar las visitas. Descarga la 
          a(href="/plantilla caso potencial comercial.xlsx" target="_blank" download)  plantilla aquí
          | .
        v-file-input(
          label="Archivo"
          v-model="form.file"
          persistent-hint
          :hint="instanceId ? 'Volver a subir el archivo para actualizar la proyección' : 'Sube un archivo con la proyección comercial'"
          outlined
          accept="csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          :rules="v.file")
        .text-right
          v-btn.mr-2(color="primary" outlined @click="closeDialog" :disabled="loading") Cancelar
          v-btn(color="primary" type="submit" :loading="loading") Guardar
  v-card(v-else-if="errors.length > 0")
    v-card-title Falló la Carga
    v-card-text
      .d-flex.justify-center.align-center
        v-icon.mx-auto(color="error" size="80") mdi-alert-circle-outline
      v-card-text.text-center
        div Elementos cargados: {{ itemsUploaded }} / {{ itemsCount }}
        div Lamentablemente no pudimos cargar todos los elementos, por favor revisa los errores y vuelve a intentar
      ul
        li(v-for="error in errors" :key="error") {{ error }}
      .text-right.mt-4
        v-btn.mr-2(color="primary" outlined @click="closeDialog" :disabled="loading") Cerrar
        v-btn(v-if="instanceId" color="primary" :to="`/proyeccion-comercial/${instanceId}/items`") Continuar
  v-card(v-else)
    v-card-title Archivo Subido Correctamente
    v-card-text
      .d-flex.justify-center.align-center
        v-icon.mx-auto(color="success" size="80") mdi-check-circle-outline
    v-card-text
      .text-center
        div El archivo se ha cargado correctamente, se han importado {{ itemsCount }} Líneas
        div presiona continuar para ver los resultados.
      .text-right.mt-4
        v-btn.mr-2(color="primary" outlined @click="closeDialog" :disabled="loading") Cerrar
</template>

<script>
import { mapActions } from 'vuex'
import FormMixin from '@/components/mixins/FormMixin' 
import validations from '@/shared/formValidations'

export default {
  mixins: [FormMixin],
  data() {
    return {
      instanceId: null,
      submited: false,
      itemsCount: 0,
      itemsUploaded: 0,
      isUploading: false,
      form: {
        file: null,
      },
      yearsOptions: [],
      dialog: false,
      linesOfBusiness: [],
      errors: []
    }
  },
  methods: {
    ...mapActions('commercial_projection', ['createCommercialProjection', 'editCommercialProjection', 'getCommercialProjection']),
    ...mapActions('product', ['listLineOfBusiness']),
    async fetchLinesOfBusiness () {
      const { data } = await this.listLineOfBusiness()
      this.linesOfBusiness = data
    },
    async openDialog(instanceId) {
      this.instanceId = instanceId
      this.dialog = true
    },
    closeDialog() {
      this.dialog = false
    },
    onError({ error }) {
      self.errors = []
      if (Array.isArray(error.response.data)) {
        this.errors = error.response.data
      } else {
        for (const key in error.response.data) {
          this.errors = this.errors.concat(error.response.data[key])
        }
      }
      this.submited = true
    },
    onSuccess({ data }) {
      self.errors = []
      self.itemsUploaded = 0
      self.itemsCount = 0

      if (this.isUploading) {
        this.submited = true
        if (data.upload_results) {
          this.errors = data.upload_results.errors
          this.itemsUploaded = data.upload_results.created_items,
          this.itemsCount = data.upload_results.total_items
        }
      } else {
        this.dialog = false
      }
      this.instanceId = data.id
      
      this.$emit('save')

    },
    getAction() {
      return this.instanceId ? this.editCommercialProjection : this.createCommercialProjection
    },
    getData() {
      const formData = new FormData()
      const data = { data: formData }
      this.isUploading = !!this.form.file
      for (const key in this.form) {
        if (Array.isArray(this.form[key])) {
          for (const value of this.form[key]) {
            formData.append(key, value)
          }
          continue
        }
        formData.append(key, this.form[key])
      }
       if (this.instanceId) {
        data.id = this.instanceId
      }
      return data
    },
    resetForm() {
      this.form = {
        year: new Date().getFullYear(),
        line_of_business: null,
        name: null,
        file: null,
      }
      this.$refs.form.resetValidation()
    },
    onClose() {
      this.instanceId = null
      this.errors = []
      this.submited = false
      this.loading = false
      this.resetForm()
    }
  },
  computed: {
    title() {
      return this.instanceId ? 'Editar Proyección Comercial' : 'Crear Proyección Comercial'
    },
    v() {
      return {
        file: [validations.isRequired]
      }
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.onClose()
      }
    }
  },
  created () {
    this.fetchLinesOfBusiness()
    const startYear = 2020
    const endYear = new Date().getFullYear() + 1
    const years = []
    for (let i = startYear; i <= endYear; i++) {
      years.push(i)
    }
    this.yearsOptions = years
  }
}
</script>
