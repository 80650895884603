<template lang="pug">
v-dialog(v-model="dialog" width="600")
  v-card(v-if="!submited")
    v-card-title {{ title }}
    v-card-text
      v-form(ref="form" @submit.prevent="onSubmit")
        v-text-field(label="Nombre" v-model="form.name" outlined :rules="v.name")
        v-select(label="Año" v-model="form.year" outlined type="number" :rules="v.year" :items="yearsOptions")
        v-select(
          v-model="form.line_of_business"
          :items="linesOfBusiness"
          item-text="name"
          item-value="id"
          label="Línea de Negocio"
          outlined
          :rules="v.line_of_business"
          chips
          multiple
        )
        .text-right
          v-btn.mr-2(color="primary" outlined @click="closeDialog" :disabled="loading") Cancelar
          v-btn(color="primary" type="submit" :loading="loading") Guardar
  v-card(v-else-if="errors.length > 0")
    v-card-title Falló la Carga
    v-card-text
      .d-flex.justify-center.align-center
        v-icon.mx-auto(color="error" size="80") mdi-alert-circle-outline
      v-card-text.text-center
        div Elementos cargados: {{ itemsUploaded }} / {{ itemsCount }}
        div Lamentablemente no pudimos cargar todos los elementos, por favor revisa los errores y vuelve a intentar
      ul
        li(v-for="error in errors" :key="error") {{ error }}
      .text-right.mt-4
        v-btn.mr-2(color="primary" outlined @click="closeDialog" :disabled="loading") Cerrar
        v-btn(v-if="instanceId" color="primary" :to="`/proyeccion-comercial/${instanceId}/items`") Continuar
  v-card(v-else)
    v-card-title Archivo Subido Correctamente
    v-card-text
      .d-flex.justify-center.align-center
        v-icon.mx-auto(color="success" size="80") mdi-check-circle-outline
    v-card-text
      .text-center
        div El archivo se ha cargado correctamente, se han importado {{ itemsCount }} Líneas
        div presiona continuar para ver los resultados.
      .text-right.mt-4
        v-btn.mr-2(color="primary" outlined @click="closeDialog" :disabled="loading") Cerrar
        v-btn(color="primary" :to="`/proyeccion-comercial/${instanceId}/items`") Continuar
</template>

<script>
import { mapActions } from 'vuex'
import FormMixin from '@/components/mixins/FormMixin'
import validations from '@/shared/formValidations'
import { globalEmitter } from '@/utils/eventBus'
import { WIZARD_STEPS_EVENTS } from '@/constants'

export default {
  mixins: [FormMixin],
  data() {
    return {
      instanceId: null,
      submited: false,
      itemsCount: 0,
      itemsUploaded: 0,
      isUploading: false,
      form: {
        year: new Date().getFullYear(),
        line_of_business: [],
        name: null,
      },
      yearsOptions: [],
      dialog: false,
      linesOfBusiness: [],
      errors: []
    }
  },
  methods: {
    ...mapActions('commercial_projection', ['createCommercialProjection', 'editCommercialProjection', 'getCommercialProjection']),
    ...mapActions('product', ['listLineOfBusiness']),
    async fetchLinesOfBusiness () {
      const { data } = await this.listLineOfBusiness()
      this.linesOfBusiness = data
    },
    async openDialog(instanceId) {
      this.instanceId = instanceId
      await this.fetchForm()
      this.dialog = true
    },
    closeDialog() {
      this.dialog = false
    },
    onError({ error }) {
      self.errors = []
      if (Array.isArray(error.response.data)) {
        this.errors = error.response.data
      } else {
        for (const key in error.response.data) {
          this.errors = this.errors.concat(error.response.data[key])
        }
      }
      this.submited = true
    },
    onSuccess({ data }) {
      globalEmitter(WIZARD_STEPS_EVENTS.COMMERCIAL_PROJECTION_CREATED)

      self.errors = []
      self.itemsUploaded = 0
      self.itemsCount = 0

      if (this.isUploading) {
        this.submited = true
        if (data.upload_results) {
          this.errors = data.upload_results.errors
          this.itemsUploaded = data.upload_results.created_items,
          this.itemsCount = data.upload_results.total_items
        }
      } else {
        this.dialog = false
      }
      this.instanceId = data.id

      this.$router.push(`/proyeccion-comercial/${ this.instanceId }/items`)
    },
    getAction() {
      return this.instanceId ? this.editCommercialProjection : this.createCommercialProjection
    },
    async fetchForm() {
      if (this.instanceId) {
        const { data } = await this.getCommercialProjection(this.instanceId)
        this.form = {
          year: data.year,
          line_of_business: data.line_of_business,
          name: data.name,
        }
      }
    },
    getData() {
      const formData = new FormData()
      const data = { data: formData }
      for (const key in this.form) {
        if (Array.isArray(this.form[key])) {
          for (const value of this.form[key]) {
            formData.append(key, value)
          }
          continue
        }
        formData.append(key, this.form[key])
      }
       if (this.instanceId) {
        data.id = this.instanceId
      }
      return data
    },
    resetForm() {
      this.form = {
        year: new Date().getFullYear(),
        line_of_business: null,
        name: null,
      }
      this.$refs.form.resetValidation()
    },
    onClose() {
      this.instanceId = null
      this.errors = []
      this.submited = false
      this.loading = false
      this.resetForm()
    }
  },
  computed: {
    title() {
      return this.instanceId ? 'Editar Proyección Comercial' : 'Crear Proyección Comercial'
    },
    v() {
      return {
        year: [validations.isRequired],
        line_of_business: [(v) => v.length > 0 || 'Debes seleccionar al menos una línea de negocio'],
        name: [validations.isRequired],
      }
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.onClose()
      }
    }
  },
  created () {
    this.fetchLinesOfBusiness()
    const startYear = 2020
    const endYear = new Date().getFullYear() + 1
    const years = []
    for (let i = startYear; i <= endYear; i++) {
      years.push(i)
    }
    this.yearsOptions = years
  }
}
</script>
