<template lang="pug">
v-card
  v-card-title Mis Planes
  v-simple-table
    template(v-slot:default)
      thead
        tr
          th Nombre
          th Tipo
          th Año
          th Estimación
          th Acciones
      tbody
        tr(v-for="plan in plans" @click="$router.push(`/planes/${plan.id}/`)" :key="plan.id")
          td {{plan.name}}
          td {{plan.plan_type}}
          td {{plan.year}}
          td {{plan.estimation | planEstimation(plan.plan_type)}}
          td
            v-menu(offset-y)
              template(v-slot:activator="{ on, attrs }")
                v-btn(v-bind="attrs" v-on="on" icon)
                  v-icon mdi-dots-vertical
              v-list
                v-list-item(:to="`/planes/editar/${plan.id}/`")
                  v-list-item-title Editar
                v-list-item(@click="$refs.removePlanDialog.open(plan.id)")
                  v-list-item-title Eliminar
  .text-center.py-4
    v-pagination(v-model="currentPage" :length="paginationLength")
  RemovePlanDialog(ref="removePlanDialog" @save="fetchData")
</template>

<script>
import { mapActions } from 'vuex'
import PaginationMixin from '../mixins/PaginationMixin.vue'
import RemovePlanDialog from './dialogs/RemovePlanDialog.vue'

export default {
  mixins: [PaginationMixin],
  components: {
    RemovePlanDialog
  },
  data () {
    return {
      plans: []
    }
  },
  methods: {
    ...mapActions('plan', ['listPlan']),
    async fetchData () {
      const response = await this.listPlan()
      this.plans = response.data.results
      this.itemCount = response.data.count
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
