<template lang="pug">
v-container(fluid style="overflow: show;")
  v-row
    v-col(cols="12")
      v-card(style="background: rgb(85,117,223); background: linear-gradient(0deg, rgba(85,117,223,1) 0%, rgba(67,163,202,1) 50%, rgba(49,208,182,1) 100%); position: relative;")
        v-card-text
          h3.white--text.mb-2 Wizard Plan 2 Sell
          h1.white--text.mb-4(style="line-height: 1.1;")
            | Explora la herramienta y descubre el potencial de tu negocio.
          v-btn(color="primary" @click="openWizard()" outlined style="background-color: white") COMENZAR
        v-img(src="/img/wizard-modal.png" alt="hero" width="120px" style="position: absolute; right: 20px; bottom: 0;")
  v-row
    v-col(cols="12" md="4" v-for="infocard in mainInfoCard")
      v-card.text-center(height="100%")
        v-card-text
          v-icon(size="85px" color="#1B2E38") {{ infocard.icon }}
          h1.mt-4.text-center(style="color: #19BFA7;") {{ infocard.title }}
        v-card-text.px-md-14 {{ infocard.description }}
  v-card.mt-6
    v-card-text
      h3.mb-4 Ejemplos de Uso: Casos de Estudio
      .d-flex.pb-4(style="gap: 16px; overflow-x: auto;")
        v-card(
          v-for="example in HOME_INFO_CARDS"
          min-width="175"
          outlined
        )
          v-card-text.pa-3.d-flex.flex-column.justify-space-between(style="height: 100%;")
            div
              v-icon.mb-2(size="35px" color="primary") {{ example.icon }}
              div {{ example.text1 }}
              h1.mb-4(style="color: #1B2E38;") {{ example.text2 }}
            div
              v-chip.d-block.rounded-lg.text-center(small text-color="#5575DF" color="#DFE7FA" @click="showExample(example)") Revisar Caso
  v-dialog(v-model="exampleModal" max-width="700px")
    v-card(v-if="selectedExample")
      v-card-title Casos de Estudio
      v-card-text
        h2.mb-4(style="color: #5575DF;") {{ selectedExample.title }}
        p(v-html="selectedExample.example")
        .text-right
          v-btn(color="primary" @click="exampleModal = false") Cerrar
</template>

<script>
import { WIZARD_STEPS_EVENTS } from '@/constants'
import { HOME_INFO_CARDS } from '@/constants/home'
import { globalEmitter } from '@/utils/eventBus'

export default {
  name: 'Home',
  data() {
    return {
      HOME_INFO_CARDS,
      exampleModal: false,
      selectedExample: null,
      mainInfoCard: [
        {
          title: 'Potencial Comercial',
          description: `Analiza el tamaño total del mercado.
Identifica oportunidades de crecimiento.
Visualiza y cuantifica tu mercado objetivo.
Ver productos y clientes con más participación.
Analiza zonas de mayor potencial.
Evalúa el crecimiento del mercado.`,
          icon: 'mdi-star-plus',
        },
        {
          title: 'Mix Óptimo',
          description: `Descubre la combinación más rentable.
Considera las capacidades, precios y costos de tu negocio.
Utiliza tecnología de punta con un modelo de optimización avanzado.
Maximiza tu margen bruto`,
          icon: 'mdi-tune-vertical',
        },
        {
          title: 'Plan de Ventas',
          description: `Personaliza tu estrategia de ventas.
Ajusta según análisis continuos.
Enfócate en el crecimiento sostenible.
Crea desde cero o modifica desde el mix óptimo.
Exporta el plan para seguimiento y control.`,
          icon: 'mdi-file-chart-check',
        }
      ],
    }
  },
  methods: {
    showExample(example) {
      this.selectedExample = example
      this.exampleModal = true
    },
    openWizard() {
      globalEmitter(WIZARD_STEPS_EVENTS.START_WIZARD, 0)
    }
  },
}
</script>
