<template lang="pug">
v-text-field(
  v-model="maskedValue"
  :rules="rules"
  outlined
  :suffix="suffix"
  :prefix="prefix"
  :label="label"
)
</template>

<script>

export default {
  props: {
    value: {
      type: Number,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    suffix: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    prefix: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      maskedValue: '',
    }
  },
  methods: {
    applyMask(value) {
      return value.toUpperCase()
    },
  },
  watch: {
    maskedValue: {
      immediate: true,
      handler(value) {
        this.$nextTick(() => {
          const newValue = this.applyMask(value)
          if (newValue !== value) {
            this.maskedValue = newValue
          }
          this.$emit('input', newValue)
        })
      },
    },
    value: {
      immediate: true,
      handler(value) {
        if (value === null || value === undefined) {
          this.maskedValue = ''
          return
        }
        this.maskedValue = this.applyMask(value)
      },
    },
  },
}
</script>