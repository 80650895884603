<template lang="pug">
v-dialog(v-model="isOpen" width="500")
  v-card
    v-card-title {{title}}
    v-card-text
      div {{text}}
      .text-right.mt-4
        v-btn.mr-2(@click="close" color="primary" outlined) Cancelar
        v-btn(@click="confirm" color="primary") Confirmar
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Confirmación'
    },
    text: {
      type: String,
      default: '¿Está seguro que desea realizar esta acción?'
    },
    icon: {
      type: String,
      default: 'mdi-alert-circle'
    },
    iconColor: {
      type: String,
      default: 'red'
    }
  },
  data () {
    return {
      isOpen: false
    }
  },
  methods: {
    open () {
      this.isOpen = true
    },
    close () {
      this.isOpen = false
      this.$emit('close', false)
    },
    confirm () {
      this.isOpen = false
      this.$emit('confirm', true)
    }
  }
}
</script>

<style lang="scss" scoped>
.v-card__text {
  text-align: center;
  div {
  }
}
</style>