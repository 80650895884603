<template lang="pug">
v-dialog(v-model="dialog" width="700")
  v-card
    v-card-title {{ title }}
    v-card-text
      //- Warning
      div.mb-6
        b Importante!
        span  Al cambiar la capacidad de producción de un producto, se actualizarán los
        b  Casos de Potencial Comercial
        span  comerciales asociadas a este producto.
      v-form(ref="form" @submit.prevent="onSubmit")
        v-row
          v-col(cols="12")
            v-select(
              label="Tipo de Capacidad"
              v-model="form.has_shared_capacity"
              outlined
              :items="capacityTypes"
              item-text="name"
              item-value="value"
            )
          v-col(v-if="form.has_shared_capacity" cols="12")
            v-autocomplete(
              label="Productos Combinables"
              placeholder="Seleccionar Productos Combinables"
              :search-input.sync="searchProductSharedCapacity"
              v-model="form.shared_capacity"
              :items="products"
              item-text="name"
              item-value="id"
              multiple
              chips
              outlined
            )
          v-col(cols="12")
            div(:id="WIZARD_ELEMENTS_ID.HELP_MONTHLY_PRODUCTION_CAPACITY")
              .d-flex
                NumberMaskedInput(
                  label="Capacidad de producción o abastecimiento mensual"
                  v-model="form.monthly_production_capacity"
                  :rules="v.monthly_production_capacity"
                )
                ShowInfoBtn.mt-4(style="height: 100%" @click="$refs.monthlyProductionCapacityInfoAlert.showAlert()")
              InfoAlert(:message="alertMessages.monthly_production_capacity" ref="monthlyProductionCapacityInfoAlert")
          v-col(cols="12")
            label.font-weight-bold Capacidad
            v-checkbox(
              v-model="usePercent"
              label="Activar Porcentajes"
            )
        v-row(v-if="usePercent")
          v-col(cols="12" sm="4" md="3")
            NumberMaskedInput(
              label="Enero"
              v-model="form.january_production_percentage"
              type="number"
              outlined
              suffix="%"
              :rules="v.production_percentage"
              )
          v-col(cols="12" sm="4" md="3")
            NumberMaskedInput(
              label="Febrero"
              v-model="form.february_production_percentage"
              type="number"
              outlined
              suffix="%"
              :rules="v.production_percentage"
              )
          v-col(cols="12" sm="4" md="3")
            NumberMaskedInput(
              label="Marzo"
              v-model="form.march_production_percentage"
              type="number"
              outlined
              suffix="%"
              :rules="v.production_percentage"
              )
          v-col(cols="12" sm="4" md="3")
            NumberMaskedInput(
              label="Abril"
              v-model="form.april_production_percentage"
              type="number"
              outlined
              suffix="%"
              :rules="v.production_percentage"
              )
          v-col(cols="12" sm="4" md="3")
            NumberMaskedInput(
              label="Mayo"
              v-model="form.may_production_percentage"
              type="number"
              outlined
              suffix="%"
              :rules="v.production_percentage"
              )
          v-col(cols="12" sm="4" md="3")
            NumberMaskedInput(
              label="Junio"
              v-model="form.june_production_percentage"
              type="number"
              outlined
              suffix="%"
              :rules="v.production_percentage"
              )
          v-col(cols="12" sm="4" md="3")
            NumberMaskedInput(
              label="Julio"
              v-model="form.july_production_percentage"
              type="number"
              outlined
              suffix="%"
              :rules="v.production_percentage"
              )
          v-col(cols="12" sm="4" md="3")
            NumberMaskedInput(
              label="Agosto"
              v-model="form.august_production_percentage"
              type="number"
              outlined
              suffix="%"
              :rules="v.production_percentage"
              )
          v-col(cols="12" sm="4" md="3")
            NumberMaskedInput(
              label="Septiembre"
              v-model="form.september_production_percentage"
              type="number"
              outlined
              suffix="%"
              :rules="v.production_percentage"
              )
          v-col(cols="12" sm="4" md="3")
            NumberMaskedInput(
              label="Octubre"
              v-model="form.october_production_percentage"
              type="number"
              outlined
              suffix="%"
              :rules="v.production_percentage"
              )
          v-col(cols="12" sm="4" md="3")
            NumberMaskedInput(
              label="Noviembre"
              v-model="form.november_production_percentage"
              type="number"
              outlined
              suffix="%"
              :rules="v.production_percentage"
              )
          v-col(cols="12" sm="4" md="3")
            NumberMaskedInput(
              label="Diciembre"
              v-model="form.december_production_percentage"
              type="number"
              outlined
              suffix="%"
              :rules="v.production_percentage"
              )
        v-row(v-else)
          v-col(cols="12" sm="4" md="3")
            NumberMaskedInput(
              label="Enero"
              v-model="form.january_production_capacity"
              type="number"
              outlined
              :rules="v.production_capacity"
              )
          v-col(cols="12" sm="4" md="3")
            NumberMaskedInput(
              label="Febrero"
              v-model="form.february_production_capacity"
              type="number"
              outlined
              :rules="v.production_capacity"
              )
          v-col(cols="12" sm="4" md="3")
            NumberMaskedInput(
              label="Marzo"
              v-model="form.march_production_capacity"
              type="number"
              outlined
              :rules="v.production_capacity"
              )
          v-col(cols="12" sm="4" md="3")
            NumberMaskedInput(
              label="Abril"
              v-model="form.april_production_capacity"
              type="number"
              outlined
              :rules="v.production_capacity"
              )
          v-col(cols="12" sm="4" md="3")
            NumberMaskedInput(
              label="Mayo"
              v-model="form.may_production_capacity"
              type="number"
              outlined
              :rules="v.production_capacity"
              )
          v-col(cols="12" sm="4" md="3")
            NumberMaskedInput(
              label="Junio"
              v-model="form.june_production_capacity"
              type="number"
              outlined
              :rules="v.production_capacity"
              )
          v-col(cols="12" sm="4" md="3")
            NumberMaskedInput(
              label="Julio"
              v-model="form.july_production_capacity"
              type="number"
              outlined
              :rules="v.production_capacity"
              )
          v-col(cols="12" sm="4" md="3")
            NumberMaskedInput(
              label="Agosto"
              v-model="form.august_production_capacity"
              type="number"
              outlined
              :rules="v.production_capacity"
              )
          v-col(cols="12" sm="4" md="3")
            NumberMaskedInput(
              label="Septiembre"
              v-model="form.september_production_capacity"
              type="number"
              outlined
              :rules="v.production_capacity"
              )
          v-col(cols="12" sm="4" md="3")
            NumberMaskedInput(
              label="Octubre"
              v-model="form.october_production_capacity"
              type="number"
              outlined
              :rules="v.production_capacity"
              )
          v-col(cols="12" sm="4" md="3")
            NumberMaskedInput(
              label="Noviembre"
              v-model="form.november_production_capacity"
              type="number"
              outlined
              :rules="v.production_capacity"
              )
          v-col(cols="12" sm="4" md="3")
            NumberMaskedInput(
              label="Diciembre"
              v-model="form.december_production_capacity"
              type="number"
              outlined
              :rules="v.production_capacity"
              )
        .text-right
          v-btn(color="primary" outlined @click="handleCancel" :disabled="loading").mr-2 Cancelar
          v-btn(color="primary" type="submit" :loading="loading") Guardar
</template>
<script>
import { mapActions } from 'vuex'
import FormMixin from '@/components/mixins/FormMixin'
import validations from '@/shared/formValidations'
import debounce from 'debounce'
import ShowInfoBtn from '@/components/common/ShowInfoBtn.vue'
import InfoAlert from '@/components/common/InfoAlert.vue'
import { numberMaskFormat } from '@/utils/mask'
import NumberMaskedInput from '@/components/common/NumberMaskedInput.vue'
import { WIZARD_STEPS_EVENTS, WIZARD_ELEMENTS_ID } from '@/constants'
import { globalEmitter } from '@/utils/eventBus'

export default {
  mixins: [FormMixin],
  components: {
    ShowInfoBtn,
    InfoAlert,
    NumberMaskedInput
  },
  data () {
    return {
      WIZARD_ELEMENTS_ID,
      dialog: false,
      numberMaskFormat,
      usePercent: this.getUsePercent(),
      instanceId: null,
      title: 'Añadir capacidad de producción',
      capacityTypes: [
        { name: 'Capacidad propia', value: false },
        { name: 'Capacidad compartida', value: true }
      ],
      form: {
        shared_capacity: [],
        monthly_production_capacity: 0,
        january_production_percentage: 100,
        february_production_percentage: 100,
        march_production_percentage: 100,
        april_production_percentage: 100,
        may_production_percentage: 100,
        june_production_percentage: 100,
        july_production_percentage: 100,
        august_production_percentage: 100,
        september_production_percentage: 100,
        october_production_percentage: 100,
        november_production_percentage: 100,
        december_production_percentage: 100,
        january_production_capacity: 0,
        february_production_capacity: 0,
        march_production_capacity: 0,
        april_production_capacity: 0,
        may_production_capacity: 0,
        june_production_capacity: 0,
        july_production_capacity: 0,
        august_production_capacity: 0,
        september_production_capacity: 0,
        october_production_capacity: 0,
        november_production_capacity: 0,
        december_production_capacity: 0,
        has_shared_capacity: false,
      },
      searchBaseProduct: null,
      searchProductSharedCapacity: null,
      baseProducts: [],
      products: [],
      selectedBaseProduct: null,
      selectedProductSharedCapacity: [],
      alertMessages: {
        sku: 'Te recomendamos utilizar tu propio código interno como código de producto para facilitar el manejo del inventario. Si no cuentas con uno, puedes crear acrónimos que te permitan identificar rápidamente el producto con solo ver el código. Por ejemplo, el acrónimo CCZ-500 podría representar Coca Cola Zero 500ml.',
        market_price: 'Registra el precio promedio del producto en el mercado según tus propias definiciones y en función de tus clientes. Este dato es fundamental, ya que el modelo utilizará este valor como referencia para determinar a qué precio un cliente potencial podría realizar una compra, especialmente cuando no tengas información específica sobre el precio que un cliente está dispuesto a pagar.',
        monthly_production_capacity: `Indica aquí la capacidad máxima de producción o abastecimiento mensual para cada producto. Este valor es crucial, ya que actúa como un límite en tus análisis de potencial comercial. Recuerda que la capacidad ingresada puede incluir tanto la producción propia como la capacidad de compra a terceros. Puedes ir variando porcentualmente por mes la capacidad de producción inicial.

Asegúrate de actualizar este dato regularmente para reflejar cualquier incremento o reducción en tus capacidades, permitiéndote así mantener una planificación precisa y adaptativa.`,
      }
    }
  },
  methods: {
    async openDialog (instanceId) {
      this.usePercent = this.getUsePercent()
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
      this.instanceId = instanceId
      await this.fetchForm()
      this.dialog = true
    },
    ...mapActions('product', [
      'getProduct',
      'getBaseProduct',
      'listBaseProducts',
      'listProducts',
      'createProduct',
      'editProduct',
    ]),
    onSuccess () {
      this.loading = false
      this.dialog = false
      globalEmitter(WIZARD_STEPS_EVENTS.PRODUCTION_CAPACITY_CREATED)
      this.$emit('save')
    },
    onError ({ error }) {
      this.loading = false
      console.log(error.response.data)
      let errorMessage = `Ocurrió un error al ${this.instanceId ? 'editar' : 'guardar'} el producto \n`
      const labels = {
        name: 'Nombre',
        base_product: 'Producto base',
        sku: 'SKU',
        market_price: 'Precio de mercado',
        attribute_options: 'Atributos'
      }
      for (const key in error.response.data) {
        errorMessage = errorMessage.concat(`${labels[key]}: ${error.response.data[key].join(', ')} \n`)
      }
      this.$toast.error(errorMessage)

    },
    getAction () {
      return this.instanceId ? this.editProduct : this.createProduct
    },
    async fetchForm () {
      // Si se está editando un producto
      if (this.instanceId) {
        const { data } = await this.getProduct(this.instanceId)
        await this.handleSelectBaseProduct(data.base_product)
        this.title = data.name
        this.form = {
          ...data,
          january_production_capacity: 0,
          february_production_capacity: 0,
          march_production_capacity: 0,
          april_production_capacity: 0,
          may_production_capacity: 0,
          june_production_capacity: 0,
          july_production_capacity: 0,
          august_production_capacity: 0,
          september_production_capacity: 0,
          october_production_capacity: 0,
          november_production_capacity: 0,
          december_production_capacity: 0,
        }
        this.toIntegerCapacity()
      }
      if (this.baseProducts.length === 0) {
        this.handleSearchBaseProduct()
      }
      if (this.products.length === 0) {
        this.handleSearchProductSharedCapacity()
      }
    },
    getData () {
      if (!this.usePercent) this.toPercentCapacity()

      const {
        shared_capacity,
        monthly_production_capacity,
        january_production_percentage,
        february_production_percentage,
        march_production_percentage,
        april_production_percentage,
        may_production_percentage,
        june_production_percentage,
        july_production_percentage,
        august_production_percentage,
        september_production_percentage,
        october_production_percentage,
        november_production_percentage,
        december_production_percentage,
      } = this.form
      const data = {
        data: {
          shared_capacity,
          monthly_production_capacity,
          january_production_percentage,
          february_production_percentage,
          march_production_percentage,
          april_production_percentage,
          may_production_percentage,
          june_production_percentage,
          july_production_percentage,
          august_production_percentage,
          september_production_percentage,
          october_production_percentage,
          november_production_percentage,
          december_production_percentage,
        }
      }
      if (this.instanceId) {
        data.id = this.instanceId
      }
      return data
    },
    handleCancel () {
      this.dialog = false
    },
    async handleSelectBaseProduct (baseProductId) {
      if (!baseProductId) {
        this.form.attribute_options = []
        this.selectedBaseProduct = null
        return
      }
      const { data } = await this.getBaseProduct(baseProductId)
      this.form.attribute_options = data.attributes.map(() => null)
      this.selectedBaseProduct = data
    },
    async handleSelectProductSharedCapacity (productsIds) {
      const { data } = await this.listProducts({ params: { id__in: productsIds } })
    },
    async handleSearchProductSharedCapacity () {
      const params = {
        search: this.searchProductSharedCapacity,
        page_size: 15
      }
      const { data } = await this.listProducts({ params })
      const products = data.results
      this.products = products.concat(this.selectedProductSharedCapacity)
    },
    async handleSearchBaseProduct () {
      const params = {
        search: this.searchBaseProduct,
        page_size: 15
      }
      const { data } = await this.listBaseProducts({ params })
      this.baseProducts = data.results
    },
    updateUsePercent(value) {
      localStorage.setItem('usePercentInProdCapacity', value)
      if (value) {
        this.toPercentCapacity()
      } else {
        this.toIntegerCapacity()
      }
    },
    getUsePercent() {
      return localStorage.getItem('usePercentInProdCapacity') === 'true' || true
    },
    toIntegerCapacity(){
      if (!this.form.monthly_production_capacity) return

      this.form.january_production_capacity = Math.round(this.form.monthly_production_capacity * this.form.january_production_percentage / 100)
      this.form.february_production_capacity = Math.round(this.form.monthly_production_capacity * this.form.february_production_percentage / 100)
      this.form.march_production_capacity = Math.round(this.form.monthly_production_capacity * this.form.march_production_percentage / 100)
      this.form.april_production_capacity = Math.round(this.form.monthly_production_capacity * this.form.april_production_percentage / 100)
      this.form.may_production_capacity = Math.round(this.form.monthly_production_capacity * this.form.may_production_percentage / 100)
      this.form.june_production_capacity = Math.round(this.form.monthly_production_capacity * this.form.june_production_percentage / 100)
      this.form.july_production_capacity = Math.round(this.form.monthly_production_capacity * this.form.july_production_percentage / 100)
      this.form.august_production_capacity = Math.round(this.form.monthly_production_capacity * this.form.august_production_percentage / 100)
      this.form.september_production_capacity = Math.round(this.form.monthly_production_capacity * this.form.september_production_percentage / 100)
      this.form.october_production_capacity = Math.round(this.form.monthly_production_capacity * this.form.october_production_percentage / 100)
      this.form.november_production_capacity = Math.round(this.form.monthly_production_capacity * this.form.november_production_percentage / 100)
      this.form.december_production_capacity = Math.round(this.form.monthly_production_capacity * this.form.december_production_percentage / 100)
    },
    toPercentCapacity(){
      if (!this.form.monthly_production_capacity) return

      this.form.january_production_percentage =  Math.round(this.form.january_production_capacity / this.form.monthly_production_capacity * 100)
      this.form.february_production_percentage =  Math.round(this.form.february_production_capacity / this.form.monthly_production_capacity * 100)
      this.form.march_production_percentage =  Math.round(this.form.march_production_capacity / this.form.monthly_production_capacity * 100)
      this.form.april_production_percentage =  Math.round(this.form.april_production_capacity / this.form.monthly_production_capacity * 100)
      this.form.may_production_percentage =  Math.round(this.form.may_production_capacity / this.form.monthly_production_capacity * 100)
      this.form.june_production_percentage =  Math.round(this.form.june_production_capacity / this.form.monthly_production_capacity * 100)
      this.form.july_production_percentage =  Math.round(this.form.july_production_capacity / this.form.monthly_production_capacity * 100)
      this.form.august_production_percentage =  Math.round(this.form.august_production_capacity / this.form.monthly_production_capacity * 100)
      this.form.september_production_percentage =  Math.round(this.form.september_production_capacity / this.form.monthly_production_capacity * 100)
      this.form.october_production_percentage =  Math.round(this.form.october_production_capacity / this.form.monthly_production_capacity * 100)
      this.form.november_production_percentage =  Math.round(this.form.november_production_capacity / this.form.monthly_production_capacity * 100)
      this.form.december_production_percentage =  Math.round(this.form.december_production_capacity / this.form.monthly_production_capacity * 100)

    },
  },
  computed: {
    v () {
      return {
        sku: [validations.isRequired],
        name: [validations.isRequired],
        line_of_business: [validations.isRequired],
        base_product: [validations.isRequired],
        attributes: [validations.isRequired],
        attributeOptionsNames: [validations.isRequired],
        monthly_production_capacity: [validations.isRequired, validations.minOrEqual(0)],
        production_percentage: [validations.isRequired, validations.minOrEqual(0)],
        production_capacity: [validations.isRequired, validations.minOrEqual(0)],
        unit_cost: [validations.isRequired, validations.minOrEqual(0)],
      }
    }
  },
  watch: {
    searchBaseProduct () {
      this.handleSearchBaseProduct()
    },
    searchProductSharedCapacity () {
      this.handleSearchProductSharedCapacity()
    },
    usePercent: {
      handler: (value) => {
        this.updateUsePercent(value)
      },
      immediate: true
    }
  },
  created () {
    this.fetchForm()
    this.handleSearchBaseProduct = debounce(this.handleSearchBaseProduct, 400)
    this.handleSearchProductSharedCapacity = debounce(this.handleSearchProductSharedCapacity, 400)
  }
}
</script>