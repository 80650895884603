<template lang="pug">
div
  v-card-title.d-flex.align-center
    span Mix Óptimo
    ShowInfoBtn(@click="$refs.infoAlert.showAlert()")
    v-spacer

    v-select.group-by-select(v-model="chartBy" :items="chartByOptions" label="Agrupar gráfico por" outlined hide-details)
    v-btn.ml-2(@click="exportData" color="primary" outlined) Exportar
    v-btn.ml-2(@click="updateMix" color="primary" outlined) Actualizar
  v-card-text
    InfoAlert(:message="alertMessage" ref="infoAlert")

  v-card-text
    v-row
      v-col(cols="auto")
        h3.mb-2 Venta anual óptima según modelo
        h1.primary--text {{ totalSales | currency }}
      v-col(cols="auto")
        h3.mb-2 Margen bruto de Mix Óptimo
        h1.primary--text {{ totalProfit | currency }}

    v-row.mt-6
      v-col(cols="12")
        h3.text-center Distribución Mensual Óptima de Ventas
    Bar(:chart-data="chartData" :chart-options="chartOptions" :chart-id="id" :height="120")
</template>


<script>
import { Bar } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import { mapActions } from 'vuex'
import chartsUtils from '@/utils/chartsUtils'
import InfoAlert from '../../common/InfoAlert.vue'
import ShowInfoBtn from '../../common/ShowInfoBtn.vue'

ChartJS.register(Title, Tooltip, BarElement, CategoryScale, Legend, LinearScale)

export default {
  props: {
    filters: {
      type: Object,
      required: true,
      default: () => ({})
    },
    projection: {
      type: Number,
      default: null,
    },
    totalYearProfit: {
      type: Number,
      default: 0,
    }
  },
  components: {
    Bar,
    InfoAlert,
    ShowInfoBtn,
  },
  data() {
    return {
      id: this._uid.toString(),
      alertMessage: `
<p>
Descubre el <b>mix de productos ideal</b> con nuestro <b>modelo de optimización avanzado</b>. Esta herramienta no solo <b>sugiere la combinación de ventas que maximiza el margen bruto</b> (la diferencia entre el costo unitario y el precio de venta), sino que también <b>ajusta esta recomendación mes a mes</b> para adaptarse a los cambios del mercado y <b>maximizar tu retorno</b>. El Mix Óptimo utiliza <b>restricciones basadas en tus capacidades</b> ingresadas, la demanda del mercado, y el margen de ganancia específico por cliente y producto.
<br/>
<br/>
<b>Diseñado para maximizar tus márgenes</b>, este modelo te proporciona una guía clara sobre cómo distribuir tus esfuerzos de venta <b>para lograr los mejores resultados financieros</b>, asegurando que se aprovechen al máximo las oportunidades comerciales.
<br/>
<br/>
Puedes exportarlo, para luego importarlo como plan de ventas.
</p>
`,
      marketSize: 0,
      totalProfit: 0,
      totalSales: 0,
      form: {
        growthRate: 0
      },
      chartBy: 'customer',
      chartByOptions: [
        { value: 'customer', text: 'Cliente' },
        { value: 'product', text: 'Producto' }
      ],
      chartOptions: {
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true
          }
        },
        plugins: {
          legend: {
            display: true,
            position: 'top',
          },
          tooltip: {
            callbacks: {
              label: function(context) {
                return `$${context.formattedValue}`
              },
              title: function(context) {
                const label = context[0].dataset.label
                return label
              }
            }
          }
        }
      },
      chartData: {
        labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        datasets: [
          {
            label: 'Potencial en $',
            data: [],
            backgroundColor: chartsUtils.colors,
            hoverBackgroundColor: chartsUtils.colors
          }
        ]
      },
      v: {
        growthRate: [
          v => !!v || 'Este campo es requerido',
          v => v >= 0 || 'El valor debe ser mayor o igual a 0'
        ]
      }
    }
  },
  computed: {
    computedMarketSize() {
      return this.chartData.datasets[0].data.reduce((acc, value) => acc + value, 0)
    },
  },
  methods: {
    ...mapActions('commercial_projection', ['getOptimalMixByCustomerChart', 'exportOptimalMix', 'getOptimalMixByProductChart', 'commercialProjectionUpdateOptimalMix', 'getStatisticsOptimalMix']),
    exportData() {
      this.exportOptimalMix({
        projection: this.projection || this.$route.params.id,
        ...this.filters
      })
    },
    async updateMix() {
      const { error } = await this.commercialProjectionUpdateOptimalMix({
        id: this.$route.params.id,
      })

      if (error) {
        this.$toast.error('Ocurrió un error al actualizar el mix óptimo')
        return
      }

      this.$toast.success('Mix óptimo actualizado correctamente')
      this.fetchData()
    },
    async fetchData() {
      const params = {
        ...this.filters,
        projection: this.projection || this.$route.params.id
      }
      this.fetchStatistics()

      const action = this.chartBy === 'customer' ? this.getOptimalMixByCustomerChart : this.getOptimalMixByProductChart

      const { data, error } = await action(params)

      if (!error) {
        this.chartData.datasets = data.datasets.map((dataset, index) => ({
          label: dataset.label,
          data: dataset.data,
          backgroundColor: chartsUtils.colors[index % chartsUtils.colors.length],
          hoverBackgroundColor: chartsUtils.colors[index % chartsUtils.colors.length]
        }))
      }
    },

    async fetchStatistics() {
      const params = {
        ...this.filters,
        projection: this.projection || this.$route.params.id
      }
      const { data, error } = await this.getStatisticsOptimalMix(params)

      if (!error) {
        this.totalProfit = data.total_profit
        this.totalSales = data.total_sales
      }
    },
  },
  watch: {
    filters: {
      handler() {
        this.fetchData()
      },
      deep: true,
    },
    chartBy() {
      this.fetchData()
    }
  },
  created() {
    this.fetchData()
  }
}
</script>

<style scoped lang="scss">
.group-by-select {
  flex-grow: 0;
  width: 300px;
}
</style>
