<template lang="pug">
v-form(ref="form" @submit.prevent="onSubmit")
  .text-h5.my-4.text-center.font-weight-bold {{title}}
  v-alert(v-if="errors.length" dense outlined type="error").rounded-pill
    div(v-for="error in errors") {{error}}
  v-text-field(v-model="form.first_name" :rules="v.first_name" label="Nombre" outlined rounded)
  v-text-field(v-model="form.last_name" :rules="v.last_name" label="Apellido" outlined rounded)
  v-text-field(v-model="form.email" :rules="v.email" label="Correo" outlined rounded :disabled="defaultValues.email !== undefined")
  v-text-field(v-model="form.business_name" :rules="v.business_name" label="Nombre de la empresa" outlined rounded)
  v-select(v-model="form.line_of_business" :rules="v.line_of_business" label="Rubro de la empresa" outlined rounded :items="lineOfBusinessChoices")
  v-text-field(v-model="form.position" :rules="v.position" label="Tu cargo dentro de la empresa" outlined rounded)
  v-text-field(v-model="form.password1" :rules="v.password1" label="Contraseña" outlined type="password" rounded)
  v-text-field(v-model="form.password2" :rules="v.password2" label="Repetir Contraseña" outlined type="password" rounded)
  v-btn(type="submit" block color="primary" large rounded :loading="loading") {{submitText}}
</template>

<script>
import { mapActions } from 'vuex'
import validations from '../../shared/formValidations'
import FromMixn from '../mixins/FormMixin.vue'

export default {
  mixins: [FromMixn],
  props: {
    defaultValues: {
      type: Object,
      default: () => ({})
    },
    noRedirect: {
      type: Boolean,
      default: false
    },
    submitText: {
      type: String,
      default: 'Registrarme'
    },
    title: {
      type: String,
      default: 'Empecemos!'
    }
  },
  data () {
    return {
      form: {
        first_name: null,
        last_name: null,
        email: null,
        password1: null,
        password2: null,
        business_name: null,
        line_of_business: null,
        position: null,
      },
      lineOfBusinessChoices: [],
      errors: [],
      loading: false,

    }
  },
  methods: {
    ...mapActions('auth', ['signup']),
    ...mapActions('business', ['getLineOfBusinessChoices']),
    onError () {
      this.errors = ['El correo ya está registrado']
    },
    onSuccess (response) {
      this.$emit('success', response)
      if (!this.noRedirect) {
        this.$router.push('/')
      }
    },
    getAction () {
      return this.signup
    }
  },
  computed: {
    v () {
      return {
        first_name: [validations.isRequired],
        last_name: [validations.isRequired],
        email: [validations.isRequired, validations.isEmail],
        password1: [validations.isRequired, validations.minLength(8), validations.password],
        password2: [validations.isRequired, validations.equals(this.form.password1, 'La contraseña no coincide')],
        business_name: [validations.isRequired],
        line_of_business: [validations.isRequired],
        position: [validations.isRequired],
      }
    }
  },
  created () {
    this.form.email = this.defaultValues.email
    this.getLineOfBusinessChoices().then(({ data }) => {
      if (data) {
        this.lineOfBusinessChoices = data
      }
    })
  }
}
</script>
