<template lang="pug">
v-card
  v-card-title
    v-btn.mr-2(v-if="backTo" :to="backTo" icon color="primary" left exact)
      v-icon mdi-arrow-left-bold-box
    span {{ title }}
    v-subheader(v-if="subtitle") {{ subtitle }}
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      default: null
    },
    backTo: {
      type: String,
      default: null
    }
  }
}
</script>