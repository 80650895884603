<script>
export default {
  data () {
    return {
      currentPage: 1,
      pageSize: 20,
      filters: {},
      itemCount: 0
    }
  }, 
  methods: {
    getFilters () {
      const filters = { ...this.filters }
      filters.page_size = this.pageSize
      filters.page = this.currentPage
      return filters
    }
  },
  computed: {
    paginationLength () {
      return this.itemCount === 0 ? 1 : Math.ceil(this.itemCount/this.pageSize)
    }
  },
  watch: {
    currentPage () {
      console.log('asdsad')
      this?.fetchData()
    }
  }
}
</script>