import * as actions from './actions'
import mutations from './mutations'
import * as getters from './getters'

const EstimatedData = {
  namespaced: true,
  state: {
  },
  getters,
  mutations,
  actions
}

export default EstimatedData
