import axios from '../../plugins/axios'
import { getCurrentBusinessId } from '../../utils/authUtils'

export const businessChangeName = (_, { businessId, data } = {} ) => {
  return axios.patch(`/client/business/${businessId}/`, data)
    .then(async response => {
      return response
    })
    .catch(err => err.response)
}

export const createBusinessInvitation = (_, { data } = {} ) => {
  return axios.post('/client/business-membership-invitation/', data)
    .then(async response => {
      return response
    })
    .catch(err => err.response)
}


export const listBusinesses = (_, { data } = {} ) => {
  return axios.get('/client/business/', data)
    .then(async response => {
      return response
    })
    .catch(err => err.response)
}

export const listBusinessMembers = ({ rootGetters }, { params } = {} ) => {
  const _params ={
    ...params,
    business_id: rootGetters['app/businessId']
  }
  return axios.get('/client/business-member/', { params: _params })
    .then(async response => {
      return response
    })
    .catch(err => err.response)
}

export const getLineOfBusinessChoices = () => {
  return axios.get('/client/line-of-business-choices/')
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const createCustomerClassification = (_, { data } = {} ) => {
  return axios.post(`/client/business/${getCurrentBusinessId()}/customer-classification/`, data)
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const listCustomerClassifications = () => {
  return axios.get(`/client/business/${getCurrentBusinessId()}/customer-classification/`)
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const editCustomerClassification = (_, { id, data } = {} ) => {
  return axios.patch(`/client/business/${getCurrentBusinessId()}/customer-classification/${id}/`, data)
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const deleteCustomerClassification = (_, id) => {
  return axios.delete(`/client/business/${getCurrentBusinessId()}/customer-classification/${id}/`)
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const getCustomerClassification = (_, id) => {
  return axios.get(`/client/business/${getCurrentBusinessId()}/customer-classification/${id}/`)
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}
