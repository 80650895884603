<template lang="pug">
v-autocomplete(
  v-model="internalValue"
  label="Selecciona un cliente"
  :search-input.sync="search"
  outlined
  no-filter
  item-text="name"
  return-object
  @focus="searchDebounced"
  @change="$emit('change', $event)"
  :hide-details="hideDetails"
  :loading="loading"
  :hide-no-data="hideNoData"
  :items="items"
  no-data-text="No se encontraron clientes"
  :rules="rules")
</template>

<script>
import { debounce } from 'debounce'
import { mapActions } from 'vuex'

export default {
  props: {
    value: {
      type: Object,
      default: null
    },
    rules: {
      type: Array,
      default: () => []
    },
    hideDetails: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      search: null,
      searchDebounced: debounce(this.handleSearch, 500),
      items: [],
      internalValue: this.value,
      loading: false,
      hideNoData: true,
    }
  },

  watch: {
    search (value) {
      this.loading = true
      this.searchDebounced()
    },
    internalValue (value) {
      this.$emit('input', value)
    },
    value: {
      handler (value) {
        this.internalValue = value
        if (value) {
          this.items = [value, ...this.items]
        }
      },
      immediate: true
    }
  },

  methods: {
    ...mapActions('customer', [
      'listCustomers'
    ]),
    async handleSearch () {
      this.loading = true
      const params = {
        search: this.search,
        page_size: 10
      }
      const { data, error } = await this.listCustomers({ params })
      if (!error) {
        this.items = data.results
      }
      this.loading = false
      this.hideNoData = false
    }
  }
}
</script>import { loading } from '../../store/app/getters'

