<template lang="pug">
div
  v-alert(
    v-model="show"
    colored-border
    color="primary"
    text
    type="info"
    border="left"
    icon="mdi-information"
  )
    h4.mb-2 Ayuda
    div.mb-2(v-html="message")
    .text-right
      v-btn(@click="close" color="primary") Entendido

</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    message: {
      type: String,
      default: '',
    },
    showInfo: {
      type: String | Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: Boolean(this.showInfo),
    }
  },
  methods: {
    close() {
      this.$emit('close')
      this.show = false
    },
    showAlert() {
      this.show = true
    },
  },
  computed: {
    ...mapGetters('app', ['wizardIsActive'])
  },
  created () {
    console.log('wizardIsActive',this.wizardIsActive)
    if (this.showInfo || this.wizardIsActive) {
      this.show = true
    }
  }
}
</script>