<template lang="pug">
div
  v-card.pb-4
    v-card-title(v-if="comercialProjection")
      v-btn.mr-2(icon @click="$router.push('/proyeccion-comercial')")
        v-icon mdi-arrow-left
      span {{ comercialProjection.name }}
      v-spacer
      div
        v-btn.mr-2(color="primary" outlined @click="$refs.commercialProjectionImportForm.openDialog($route.params.id)")
          v-icon.mr-2 mdi-file-upload
          span Cargar masivo
        v-btn.mr-2(color="primary"
          :id="WIZARD_ELEMENTS_ID.BTN_ADD_COMMERCIAL_PROJECTION_ITEM"
          @click="globalEmitter(WIZARD_STEPS_EVENTS.ON_CLICK_CREATE_COMMERCIAL_PROJECTION_ITEM); $router.push(`/proyeccion-comercial/${comercialProjection.id}/items/form/`)"
        )
          v-icon.mr-2 mdi-plus-circle-outline
          span Añadir Producto
    v-card-text
    v-simple-table(key="customer-classification")
      template(v-slot:default)
        thead(key="customer-classification-header")
          tr
            th SKU
            th Cliente
            th Consumo
            th Precio de venta
            th(style="width: 80px") Acciones
        tbody(key="customer-classification-body")
          tr(v-for="item in items" :key="item.id")
            td {{item.product_sku}}
            td {{item.customer_name}}
            td {{item.quantity | number }}
            td {{item.price | currency }}
            td
              v-menu(offset-y left)
                template(v-slot:activator="{ on, attrs }")
                  v-btn(icon v-bind="attrs" v-on="on")
                    v-icon mdi-dots-vertical
                v-list
                  v-list-item(@click="$refs.commercialProjectionForm.openDialog(item.id)")
                    v-list-item-title Editar
                  v-list-item(@click="deleteItem(item.id)")
                    v-list-item-title Eliminar
    v-pagination(v-model="pagination.page" :length="pagination.pages" @input="fetchItems")
    CommercialProjectionItemForm(ref="commercialProjectionForm" @save="fetchItems")
    CustomConfirmDialog(ref="confirmDialog")
    CommercialProjectionImportForm(ref="commercialProjectionImportForm" @save="fetchItems")
</template>

<script>
import { mapActions } from 'vuex'
import CommercialProjectionItemForm from '@/components/commercial_projection/CommercialProjectionItemForm.vue'
import CustomConfirmDialog from '@/components/common/dialogs/CustomConfirmDialog.vue'
import CommercialProjectionImportForm from '@/components/commercial_projection/CommercialProjectionImportForm.vue'
import { formatDate } from '@/utils/dateUtils.js'
import { WIZARD_ELEMENTS_ID, WIZARD_STEPS_EVENTS } from '@/constants'
import { globalEmitter } from '@/utils/eventBus'

export default {
  components: {
    CommercialProjectionItemForm,
    CommercialProjectionImportForm,
    CustomConfirmDialog,
  },
  data () {
    return {
      WIZARD_STEPS_EVENTS,
      WIZARD_ELEMENTS_ID,
      comercialProjection: null,
      pagination: {
        page: 1,
        pages: 1,
        pageSize: 20
      },
      filter: {
        search: '',
        base_product__line_of_business: null
      },
      items: [],
    }
  },
  methods: {
    globalEmitter,
    ...mapActions('commercial_projection', [
      'getCommercialProjection',
      'listCommercialProjectionItems',
      'deleteCommercialProjectionItem',
    ]),
    formatDate,
    async fetchItems () {
      const params = {
        ...this.filter,
        page: this.pagination.page,
        page_size: this.pagination.pageSize,
        projection: this.$route.params.id
      }
      const { data } = await this.listCommercialProjectionItems({ params })
      this.items = data.results || []
      this.pagination.pages = Math.ceil(data.count / this.pagination.pageSize)
    },
    async handleFilter () {
      this.pagination.page = 1
      await this.fetchItems()
    },
    async deleteItem (id) {
      const confirm = await this.$refs.confirmDialog.open(
        'Eliminar item',
        '¿Está seguro que desea eliminar este item?',
        {
          okTitle: 'Eliminar',
        }
      )
      if (confirm) {
        await this.deleteCommercialProjectionItem(id)
        await this.fetchItems()
      }
    },
    async fetchCommercialProjection () {
      const id = this.$route.params.id
      if (id) {
        const { data } = await this.getCommercialProjection(id)
        this.comercialProjection = data
      }
    }
  },
  created () {
    this.fetchCommercialProjection()
    this.fetchItems()
  }
}
</script>