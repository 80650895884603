import axios from '../../plugins/axios'

export const login = ({ dispatch }, { data } = {} ) => {
  return axios.post('/auth/login/', data)
    .then(async response => {
      localStorage.setItem('accessToken', response.data.access)
      localStorage.setItem('refreshToken', response.data.refresh)
      await dispatch('getMyUser')
      await dispatch('app_config/setCurrentBusiness', null, { root: true })
      await dispatch('app/setup', null, { root: true })
      return response
    })
    .catch(err => {
      console.log(err.response)
      console.log(err)
      return err.response
    })
}

export const logout = ({ commit, dispatch }) => {
  const refresh = localStorage.getItem('refreshToken')
  localStorage.clear()
  commit('SET_USER', null)
  commit('app_config/SET_CURRENT_BUSINESS', null, { root: true })
  dispatch('app/setup', null, { root: true })
  return axios.post('/auth/logout/', { refresh })
    .catch(err => err.response)
}

export const signup = ({ commit,dispatch }, { data } = {} ) => {
  return axios.post('/auth/register/', data)
    .then(async response => {
      commit('SET_USER', response.data.user)
      localStorage.setItem('accessToken', response.data.access)
      localStorage.setItem('refreshToken', response.data.refresh)
      await dispatch('app_config/setCurrentBusiness', null, { root: true })
      return response
    })
    .catch(err => err.response)
}

export const getMyUser = ({ commit }) => {
  return axios.get('/auth/me/')
    .then(response => {
      commit('SET_USER', response.data)
      return response
    })
    .catch(err => err.response)
}