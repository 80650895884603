<template lang="pug">
v-form(ref="form" @submit.prevent)
  v-card
    v-card-title {{planTypes[currentPlan].formName}}
    v-card-subtitle {{planTypes[currentPlan].description}}
    v-card-text
      v-text-field(v-model="form.name" label="Nombre del plan" outlined :rules="validations.name")
      v-select(v-model="form.year" label="Año de ejecución del Plan" outlined :items="[new Date().getFullYear(), new Date().getFullYear() + 1]")
      v-currency-field(v-model="form.estimation" :label="planTypes[currentPlan].estimationText" :rules="validations.estimation" outlined type="number" :min="0" :prefix="planTypes[currentPlan].estimationPrefix")
      v-text-field(v-model="form.error_range" label="Margen de error (se sugiere un 5% para sortear imprevistos, feriados u otros aspectos del negocio, ese valor puede variar según la realidad de cada empresa)" outlined type="number" min="0" max="50" suffix="%")
      SalePlanSubFrom(v-if="currentPlan === 'ventas'" :form="form")
      div.mb-3.d-block(v-if="form.projection_type === 'DAILY' || currentPlan !== 'ventas' ")
        strong Cuanto es la proporción de tiempo que se trabaja durante los días de la semana:
        span  {{planTypes[currentPlan].weeklyEstimateText}}
      v-row(dense v-if="form.projection_type === 'DAILY' || currentPlan !== 'ventas' ")
        v-col(cols="6" md="3" lg="auto")
          v-text-field(v-model="form.work_days.monday" label="Lunes" outlined type="number" min="0" max="100" suffix="%" :rules="validations.work_days.monday")
        v-col(cols="6" md="3" lg="auto")
          v-text-field(v-model="form.work_days.tuesday" label="Martes" outlined type="number" min="0"  max="100" suffix="%" :rules="validations.work_days.tuesday")
        v-col(cols="6" md="3" lg="auto")
          v-text-field(v-model="form.work_days.wednesdayes" label="Miércoles" outlined type="number" min="0"  max="100" suffix="%" :rules="validations.work_days.wednesdayes")
        v-col(cols="6" md="3" lg="auto")
          v-text-field(v-model="form.work_days.thursday" label="Jueves" outlined type="number" min="0"  max="100" suffix="%" :rules="validations.work_days.thursday")
        v-col(cols="6" md="3" lg="auto")
          v-text-field(v-model="form.work_days.friday" label="Viernes" outlined type="number" min="0"  max="100" suffix="%" :rules="validations.work_days.friday")
        v-col(cols="6" md="3" lg="auto")
          v-text-field(v-model="form.work_days.saturday" label="Sábado" outlined type="number" min="0"  max="100" suffix="%" :rules="validations.work_days.saturday")
        v-col(cols="6" md="3" lg="auto")
          v-text-field(v-model="form.work_days.sunday" label="Domingo" outlined type="number" min="0"  max="100" suffix="%" :rules="validations.work_days.sunday")
      div.mb-3.d-block
        strong {{planTypes[currentPlan].monthlyEstimateText}} (Porcentaje actual
          span(:style="estimatedPercentage > 100 ? 'color: red;' : ''")  {{estimatedPercentage}}% / 100%
          span ):
        span  {{planTypes[currentPlan].monthlyEstimateText2}}
      label.mb-3.d-block.red--text(v-if="validated && estimatedPercentage !== 100") La suma de los porcentajes debe sumar 100%
      v-row(dense)
        v-col(cols="6" md="2" lg="auto")
          v-text-field(v-model="form.monthly_estimate.january" label="Enero" outlined type="number" min="0"  max="100" suffix="%" :rules="validations.monthly_estimate.january")
        v-col(cols="6" md="2" lg="auto")
          v-text-field(v-model="form.monthly_estimate.february" label="Febrero" outlined type="number" min="0"  max="100" suffix="%" :rules="validations.monthly_estimate.february")
        v-col(cols="6" md="2" lg="auto")
          v-text-field(v-model="form.monthly_estimate.march" label="Marzo" outlined type="number" min="0"  max="100" suffix="%" :rules="validations.monthly_estimate.march")
        v-col(cols="6" md="2" lg="auto")
          v-text-field(v-model="form.monthly_estimate.april" label="Abril" outlined type="number" min="0"  max="100" suffix="%" :rules="validations.monthly_estimate.april")
        v-col(cols="6" md="2" lg="auto")
          v-text-field(v-model="form.monthly_estimate.may" label="Mayo" outlined type="number" min="0"  max="100" suffix="%" :rules="validations.monthly_estimate.may")
        v-col(cols="6" md="2" lg="auto")
          v-text-field(v-model="form.monthly_estimate.june" label="Junio" outlined type="number" min="0"  max="100" suffix="%" :rules="validations.monthly_estimate.june")
        v-col(cols="6" md="2" lg="auto")
          v-text-field(v-model="form.monthly_estimate.july" label="Julio" outlined type="number" min="0"  max="100" suffix="%" :rules="validations.monthly_estimate.july")
        v-col(cols="6" md="2" lg="auto")
          v-text-field(v-model="form.monthly_estimate.august" label="Agosto" outlined type="number" min="0"  max="100" suffix="%" :rules="validations.monthly_estimate.august")
        v-col(cols="6" md="2" lg="auto")
          v-text-field(v-model="form.monthly_estimate.september" label="Septiembre" outlined type="number" min="0"  max="100" suffix="%" :rules="validations.monthly_estimate.september")
        v-col(cols="6" md="2" lg="auto")
          v-text-field(v-model="form.monthly_estimate.october" label="Octubre" outlined type="number" min="0"  max="100" suffix="%" :rules="validations.monthly_estimate.october")
        v-col(cols="6" md="2" lg="auto")
          v-text-field(v-model="form.monthly_estimate.november" label="Noviembre" outlined type="number" min="0"  max="100" suffix="%" :rules="validations.monthly_estimate.november")
        v-col(cols="6" md="2" lg="auto")
          v-text-field(v-model="form.monthly_estimate.december" label="Diciembre" outlined type="number" min="0"  max="100" suffix="%" :rules="validations.monthly_estimate.december")
      .text-right
        v-btn(color="primary" rounded outlined to="/planes" :loading="loading").mr-3 Cancelar
        v-btn(color="primary" rounded :loading="loading" @click="onSubmit") Guardar Plan
</template>

<script>
import SalePlanSubFrom from './subforms/SalePlanSubFrom.vue'
import { mapActions } from 'vuex'
import validations from '../../shared/formValidations'

export default {
  props: {
    plan: {
      type: Object
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  components: {
    SalePlanSubFrom
  },
  data () {
    return {
      validated: false,
      currentPlan: this.getCurrentPlan(),
      planTypes: {
        ventas: {
          description: 'El plan de ventas es la carta de navegación de tu equipo de ventas, con él podrás ir monitoreando diaria o semanalmente el cumplimiento de tus metas, que con el tiempo te llevarán a la meta anual definida.',
          formName: 'Plan de Ventas',
          weeklyEstimateText: 'Esto permite distribuir las ventas del periodo en función del tiempo disponible para vender, por ejemplo si un negocio no abre los domingos su dedicación a la venta es 0% y por ende, se esperará que esos días no existan ventas.',
          estimationText: 'Estimación de Ventas totales durante el año planificado',
          monthlyEstimateText: 'Distribución mensual de ventas planificadas',
          monthlyEstimateText2: 'Esto corrige la estacionalidad de las ventas en el tiempo, por ejemplo negocios B2C como retail pueden tener variaciones en meses con fechas claves, por ejemplo, en diciembre, el porcentaje de las ventas anuales que aporta es mayor al resto de los meses.',
          estimationPrefix: '$',
          action: this.createSalePlan
        },
        costos: {
          formName: 'Nuevo Plan de Costos',
        },
        produccion: {
          formName: 'Plan de Producción',
          description: 'El plan de producción es la carta de navegación de tu equipo de producción, con él podrás ir monitoreando diaria o semanalmente el cumplimiento de tus metas, que con el tiempo te llevarán a la meta anual definida.',
          estimationText: 'Estimación de Producción total durante el año planificado',
          weeklyEstimateText: 'Esto permite distribuir las ventas del periodo en función del tiempo disponible para producción, por ejemplo si un negocio no abre los domingos su dedicación de producción es 0% y por ende, se esperará que esos días no exista producción.',
          monthlyEstimateText2: 'Esto corrige la estacionalidad de la producción en el tiempo, por ejemplo negocios B2C como retail pueden tener variaciones en meses con fechas claves, por ejemplo, en diciembre, el porcentaje de las ventas anuales que aporta es mayor al resto de los meses.',
          monthlyEstimateText: 'Distribución mensual de producción planificada',
          action: this.createProductionPlan
        }
      },
      loading: false,
      form: {
        name: 'Nuevo plan',
        year: new Date().getFullYear(),
        plan_type: 'Plan de Ventas',
        projection_type: 'WEEKLY',
        average_ticket: 10000,
        error_range: 5,
        estimation: 1000000,
        work_days: {
          monday: 100,
          tuesday: 100,
          wednesdayes: 100,
          thursday: 100,
          friday: 100,
          saturday: 100,
          sunday: 100
        },
        monthly_estimate: {
          january: 8,
          february: 8,
          march: 8,
          april: 8,
          may: 8,
          june: 8,
          july: 8,
          august: 8,
          september: 9,
          october: 9,
          november: 9,
          december: 9
        }
      },
      validations: {
        name: [validations.isRequired],
        estimation: [validations.isRequired, validations.min(0)],
        work_days: {
          monday: [validations.isRequired],
          tuesday: [validations.isRequired],
          wednesdayes: [validations.isRequired],
          thursday: [validations.isRequired],
          friday: [validations.isRequired],
          saturday: [validations.isRequired],
          sunday: [validations.isRequired]
        },
        monthly_estimate: {
          january: [validations.isRequired],
          february: [validations.isRequired],
          march: [validations.isRequired],
          april: [validations.isRequired],
          may: [validations.isRequired],
          june: [validations.isRequired],
          july: [validations.isRequired],
          august: [validations.isRequired],
          september: [validations.isRequired],
          october: [validations.isRequired],
          november: [validations.isRequired],
          december: [validations.isRequired]
        }
      }
    }
  },
  methods: {
    ...mapActions('plan', ['createSalePlan', 'createProductionPlan', 'updatePlan']),
    async onSubmit () {
      if (!this.$refs.form.validate() || !this.validate()) {
        return
      }
      this.loading = true
      const action = this.getAction()
      const response = await action({ data: this.form, planId: this.plan?.id || null })
      if (response.status === 201 || response.status === 200) {
        const planId = response.data.id
        this.$router.push(`/planes/${planId}`)
      }
      this.loading = false
    },
    validate () {
      this.validated = true
      if (this.estimatedPercentage === 100) {
        return true
      }
      return false
    },
    getAction () {
      if (this.edit) {
        return this.updatePlan
      }
      return this.planTypes[this.currentPlan].action
    },
    getCurrentPlan () {
      if (this.edit) {
        return this.plan.plan_type === 'VENTAS' ? 'ventas' : 'produccion'
      }
      return this.$route.params.planType || 'ventas'
    },
  },
  computed: {
    estimatedPercentage () {
      let result = 0

      result += parseInt(this.form.monthly_estimate.january) || 0
      result += parseInt(this.form.monthly_estimate.february) || 0
      result += parseInt(this.form.monthly_estimate.march) || 0
      result += parseInt(this.form.monthly_estimate.april) || 0
      result += parseInt(this.form.monthly_estimate.may) || 0
      result += parseInt(this.form.monthly_estimate.june) || 0
      result += parseInt(this.form.monthly_estimate.july) || 0
      result += parseInt(this.form.monthly_estimate.august) || 0
      result += parseInt(this.form.monthly_estimate.september) || 0
      result += parseInt(this.form.monthly_estimate.october) || 0
      result += parseInt(this.form.monthly_estimate.november) || 0
      result += parseInt(this.form.monthly_estimate.december) || 0
      return result
    }
  },
  created () {
    if (this.edit) {
      this.form = this.plan
    }
  }
}
</script>

