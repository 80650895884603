<script>
export default {
  data () {
    return {
      items: [],
      selectAll: false,
    }
  }, 
  methods: {
    toggleSelectAll (value) {
      this.items.forEach(item => item._selected = value)
      this.items = [...this.items]
    },
  },
  computed: {
    selectedItems () {
      return this.items.filter(item => item._selected)
    },
  },
  watch: {
    selectedItems () {
      this.selectAll = this.selectedItems.length === this.items.length
    }
  }
}
</script>