import Vue from 'vue'
import VCurrencyField from 'v-currency-field'
import { VTextField } from 'vuetify/lib'

Vue.component('v-text-field', VTextField)
Vue.use(VCurrencyField, { 
	locale: 'es-CL',
	decimalLength: 0,
	autoDecimalMode: true,
	min: null,
	max: null,
	defaultValue:  null,
  valueAsInteger: true,
  allowNegative: true
})