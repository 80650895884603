<template lang="pug">
v-container#login(fluid)
  InvitationCard(:invitationToken="this.$route.params.token")
</template>

<script>
import SignupForm from '../components/auth/SignupForm.vue'
import InvitationCard from '../components/invitations/InvitationCard.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    SignupForm,
    InvitationCard
  },
  data () {
    return {
      loading: false,
      invitation: null,
      state: null
    }
  },
  methods: {
    ...mapActions('invitation', ['getInvitationByToken', 'acceptInvitation']),
    async onAccept () {
      this.loading = true
      const response = await this.acceptInvitation(this.$route.params.token)

      if (response.status === 200 || response.status === 201) {
        this.state = 'SUCCESS'
        this.invitation = response.data
      } else {
        this.state = 'ERROR'
      }
      this.loading = false
    }
  },
  async created () {
    this.loading = true
    const response = await this.getInvitationByToken(this.$route.params.token)
    if (response.status === 200) {
      this.invitation = response.data
    } else {
      this.state = 'ERROR'
    }
    this.loading = false
  }
}
</script>

<style scoped lang="scss">
#login {
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inivitiation-text {
  text-align: center;
  font-size: 1.2rem;
  line-height: 1.5;
}

@media (max-width: 600px) {
  #login {
    padding: 0 !important;
    background-color: white;
  }
  .v-card { 
    border-radius: 0 !important;
  }
}
</style>
