<template lang="pug">
v-dialog(v-model="isOpen" max-width="500")
  v-card
    v-card-title
      span Crear Seguimiento de Plan de Venta
    v-card-text(v-if="!submited")
      div Se creará un seguimiento para este plan de venta, ahí podrás ver el avance de tus ventas y tu porcentaje de cumplimiento de tu plan.
      div ¿Deseas continuar?
    v-card-text(v-else-if="loading")
      v-progress-linear.mb-4(indeterminate)
      p.text-center Creando seguimiento de plan de venta...
    
    v-card-text(v-else-if="success")
      .text-center
        v-icon.mb-4(color="success" size="80") mdi-check-circle
        p.mb-8
          | El seguimiento de plan de venta ha sido creado correctamente.
          | Puedes ver el seguimiento haciendo clic en el botón "IR AL SEGUIMIENTO".
      .text-right
        v-btn.mr-2(@click="close" color="primary" outlined) Cerrar
        v-btn(color="primary" :to="`/planes/${salePlanId}`") ir al seguimiento
    
    v-card-text(v-else)
      .text-center
        v-icon.mb-4(color="error" size="80") mdi-alert-circle
        p.mb-4 Ha ocurrido un error al crear el seguimiento de plan de venta.
      .text-right
        v-btn(@click="close" color="primary" outlined) Cerrar
      
    v-card-text.text-right(v-if="!submited")
      v-btn.mr-2(@click="close()" color="primary" outlined) Cancelar
      v-btn(color="primary" @click="handleCreatePlanSale()") Crear Seguimiento
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      projection: null,
      isOpen: false,
      loading: false,
      salePlanId: null,
      submited: false,
      success: false,
    }
  },
  methods: {
    ...mapActions('commercial_projection', ['createPlanSale']),
    close() {
      this.isOpen = false
      this.loading = false
      this.submited = false
      this.success = false
      this.salePlanId = null
    },

    openDialog(projection) {
      this.projection = projection
      this.isOpen = true
      this.loading = false
      this.submited = false
      this.success = false
      this.salePlanId = null
    },

    async handleCreatePlanSale() {
      this.submited = true
      if (this.loading) return
      this.loading = true
      const { data, error } = await this.createPlanSale(this.projection)
      if (error) {
        this.onError()
      } else {
        this.onSuccess(data)
      }
      this.loading = false
    },
    onError () {
      this.message = "Error al crear el seguimiento de plan de venta"
      this.icon = "mdi-alert-circle"
      this.success = false
    },
    onSuccess (data) {
      this.message = "Seguimiento de plan de venta creado correctamente"
      this.icon = "mdi-check-circle"
      this.salePlanId = data.sale_plan_id
      this.success = true
    }
  }
}
</script>