import axios from '../../plugins/axios'

export const listRealSales = (_, { params } = {} ) => {
  return axios.get('/real-data/real-sale/', { params })
    .catch(err => err.response)
}

export const listRealProduction= (_, { params } = {} ) => {
  return axios.get('/real-data/real-production/', { params })
    .catch(err => err.response)
}

export const listRealCost = (_, { params } = {} ) => {
  return axios.get('/real-data/real-cost/', { params })
    .catch(err => err.response)
}

export const deleteRealSales = (_, { realDataId }) => {
  return axios.delete(`/real-data/real-sale/${realDataId}/`)
    .catch(err => err.response)
}

export const deleteRealProduction= (_, { realDataId }) => {
  return axios.delete(`/real-data/real-production/${realDataId}/`)
    .catch(err => err.response)
}

export const deleteRealCost = (_, { realDataId }) => {
  return axios.delete(`/real-data/real-cost/${realDataId}/`)
    .catch(err => err.response)
}

export const createRealCost = (_, { data } = {} ) => {
  return axios.post('/real-data/real-cost/', data)
    .catch(err => err.response)
}

export const createRealProduction = (_, { data } = {} ) => {
  return axios.post('/real-data/real-production/', data)
    .catch(err => err.response)
}

export const createRealSale = (_, { data } = {} ) => {
  return axios.post('/real-data/real-sale/', data)
    .catch(err => err.response)
}

export const createMasiveRealSale = (_, { data } = {} ) => {
  return axios.post('/real-data/real-sale/masive-create/', data)
    .catch(err => err.response)
}

export const exportRealSales = (_, { params } = {} ) => {
  return axios.get('/real-data/real-sale/export/', { params, responseType: 'blob' })
    .catch(err => err.response)
}

export const exportRealProduction= (_, { params } = {} ) => {
  return axios.get('/real-data/real-production/export/', { params, responseType: 'blob' })
    .catch(err => err.response)
}

export const exportRealCost = (_, { params } = {} ) => {
  return axios.get('/real-data/real-cost/export/', { params, responseType: 'blob' })
    .catch(err => err.response)
}

export const importRealSales = (_, { planId, data } = {} ) => {
  return axios.post(`/plan/sale-plan/${planId}/import_real_data/`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
    .catch(err => err.response)
}

export const importRealProduction= (_, { planId, data } = {} ) => {
  return axios.post(`/plan/production-plan/${planId}/import_real_data/`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
    .catch(err => err.response)
}

export const importRealCost = (_, { planId, data } = {} ) => {
  return axios.post(`/plan/cost-plan/${planId}/import_real_data/`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
    .catch(err => err.response)
}

export const getMonthlyRealData = (_, { planId } = {} ) => {
  return axios.get(`/plan/${planId}/monthly_real_data/`)
    .catch(err => err.response)
}