<script>
export default {
  props: {
    action: {
      type: Function
    }
  },
  data () {
    return {
      loading: false
    }
  },
  methods: {
    getAction () {
      if (this.action) {
        return this.action
      }
      return this._action
    },
    async onSubmit () {
      if (!this.$refs.form.validate()) {
        return
      }

      this.loading = true
      const action = this.getAction()
      const response = await action({ ...this.getData() })
      this.loading = false

      if (response.status >= 200 && response.status < 300) {
        this.$refs.form?.reset()
        this.onSuccess(response)
        this.$emit('save', response.data)
      } else {
        this.onError(response)
      }

    },
    getData () {
      return { data: this.form }
    },
    onSuccess (response) {},
    onError (response) {},
  }
}
</script>