<template lang="pug">
v-container(fluid)
  .d-flex.flex-column(style="gap: 16px;")
    ListCustomerClassification
    ListCustomers
</template>

<script>
import ListCustomers from '@/components/customers/ListCustomers.vue'
import ListCustomerClassification from '@/components/customers/ListCustomerClassification.vue'

export default {
  components: {
    ListCustomers,
    ListCustomerClassification
  },
}
</script>