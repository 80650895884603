<template lang="pug">
v-text-field(
  v-model="maskedValue"
  :rules="rules"
  outlined
  :suffix="suffix"
  :prefix="prefix"
  :label="label"
  :hint="hint"
  :persistent-hint="persistentHint"
)
</template>

<script>

export default {
  props: {
    value: {
      type: Number,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    suffix: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    prefix: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },
    'persistentHint': {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      maskedValue: '',
    }
  },
  methods: {
    numberMaskFormat(value) {
      if (value === 0 || value === '0') return '0'
      if (!value) return ''
      return value
        .toString()
        .replace(/\D/g, '')
        .replace(/^0+/, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    toNumber(value) {
      const numberValue = parseInt(value.replace(/\D/g, ''), 10)
      return isNaN(numberValue) ? null : numberValue
    },
  },
  watch: {
    maskedValue: {
      immediate: true,
      handler(value) {
        this.$nextTick(() => {
          const newValue = this.numberMaskFormat(value)
          if (newValue !== value) {
            this.maskedValue = newValue
          }
          this.$emit('input', this.toNumber(newValue))
        })
      },
    },
    value: {
      immediate: true,
      handler(value) {
        if (value === null || value === undefined) {
          this.maskedValue = ''
          return
        }
        this.maskedValue = this.numberMaskFormat(value)
      },
    },
  },
}
</script>