import axios from '../../plugins/axios'

export const setCurrentBusiness = ({ commit, rootState }, value) => {
  if (value) {
    commit('SET_CURRENT_BUSINESS', value)
    return
  }

  const currentBusiness = localStorage.getItem('current_business')
  if (currentBusiness) {
    const business = JSON.parse(currentBusiness)
    commit('SET_CURRENT_BUSINESS', business)
    return axios.get(`/client/business/${business.id}/`, { params: { owner: rootState.auth.user.id } })
    .then(response => {
      const _business = response.data
      commit('SET_CURRENT_BUSINESS', _business)
      return response
    })
    .catch(err => err.response)
  }

  return axios.get('/client/business/', { params: { owner: rootState.auth.user.id } })
    .then(response => {
      const bussines = response.data.results[0]
      commit('SET_CURRENT_BUSINESS', bussines)
      return response
    })
    .catch(err => err.response)
}
