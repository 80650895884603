<template lang="pug">
v-container(fluid)
  .d-flex.flex-column(style="gap: 16px;")
    ListLineOfBusiness

    v-card(v-if="hasLineOfBusiness || hasBaseProducts || hasProducts")
      v-toolbar(flat)
        v-tabs(v-model="currentTab" :value="currentTab" grow)
          v-tab(v-for="tab in tabs" :key="tab") {{ tab }}
      v-tabs-items(v-model="currentTab")
        v-tab-item(key="Productos Base")
          ListBaseProducts
        v-tab-item(key="Productos para venta (sku)")
          ListProducts
</template>

<script>
import ListProducts from '@/components/products/ListProducts.vue'
import ListLineOfBusiness from '@/components/products/ListLineOfBusiness.vue'
import ListBaseProducts from '@/components/products/ListBaseProducts.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    ListProducts,
    ListLineOfBusiness,
    ListBaseProducts
  },
  methods: {
    ...mapActions('product', ['checkHasBaseProducts', 'checkHasLineOfBusiness', 'checkHasProducts']),
    parseTab() {
      console.log(this.$route.query.tab)

      if (this.$route.query.tab) {
        return parseInt(this.$route.query.tab)
      }
      return 0
    }
  },
  data() {
    return {
      currentTab: this.parseTab(),
      tabs: [
        'Productos Base',
        'Productos para venta (sku)',
      ],
    }
  },
  watch: {
    $route(to, from) {
      this.currentTab = this.parseTab()
    }
  },
  computed: {
    hasBaseProducts() {
      return this.$store.state.product.hasBaseProducts
    },
    hasLineOfBusiness() {
      return this.$store.state.product.hasLineOfBusiness
    },
    hasProducts() {
      return this.$store.state.product.hasProducts
    },
  },
  created() {
    this.checkHasBaseProducts()
    this.checkHasLineOfBusiness()
    this.checkHasProducts()
  }
}
</script>