import roles from '../shared/userRoles.json'

const currency = (money) => {
  if (!money && money != '0') return ''

  return money.toLocaleString('es-CL', {
    style: 'currency',
    currency: 'CLP'
  })
}

const number = (number) => {
  if (!number && number != '0') return ''
  return number.toLocaleString('es-CL')
}

const planEstimation = (value, planType) => {
  if (planType === 'VENTAS' || planType === 'COSTOS') {
    return value.toLocaleString('es-CL', {
      style: 'currency',
      currency: 'CLP'
    })
  }
  
  if (planType === 'PRODUCCIÓN') {
    return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, '.')
  }
  return value
}

const userRole = (value) => {
  const roleIndex = roles.findIndex(r => r.value === value)
  if (roleIndex === -1) {
    return '-'
  }

  return roles[roleIndex].text
}


const dateFormat = (value) => {
  const date = new Date(value)
  return date.toLocaleDateString('es-CL')
}

export default {
  currency,
  planEstimation,
  userRole,
  number,
  dateFormat
}
