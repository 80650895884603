<template lang="pug">
v-container(fluid)
  v-card
    v-card-title
      span {{ title }}
      v-btn.ml-4(
        color="primary"
        @click="showExample = true; globalEmitter(WIZARD_STEPS_EVENTS.ON_CLICK_CREATE_BASE_PRODUCT_EXAMPLE)"
        rounded small
        :id="WIZARD_ELEMENTS_ID.BTN_CREATE_BASE_PRODUCT_EXAMPLE") Ver Ejemplo
    v-card-text
      v-form(ref="form" @submit.prevent="onSubmit")
        v-row
          v-col(cols="12" md="6")
            v-select(
              label="Línea de negocio"
              v-model="form.line_of_business"
              outlined
              item-text="name"
              item-value="id"
              :items="linesOfBusiness"
              :rules="v.line_of_business")
            UpperCaseTextInput(
              label="Nombre del producto"
              v-model="form.name"
            )

        h3.mb-4
          span Atributos
          ShowInfoBtn(@click="$refs.attributesInfoAlert.showAlert()")
        InfoAlert(:message="attributesAlertMessage" ref="attributesInfoAlert" :id="WIZARD_ELEMENTS_ID.HELP_PRODUCT_ATTRIBUTES")
        v-row(v-for="(attribute, index) in form.attributes" :key="index")
          v-col(cols="12" md="6")
            v-combobox(
              label="Nombre"
              v-model="attribute.name"
              outlined
              :rules="v.attributeNames"
              :items="attributesSuggestions"
              persistent-hint
              hint="Escribe para buscar un atributo, si no existe, se creará automáticamente"
              )
          v-col.d-flex.align-center(cols="auto" v-if="index !== 0")
            v-btn(color="error" small @click="removeAttribute(index)" text)
              v-icon.small mdi mdi-delete
              | Eliminar
          v-col(cols="12")
            v-row(v-for="(option, optionIndex) in attribute.options" :key="optionIndex")
              v-col(cols="3" :key="optionIndex")
                v-text-field(
                  :label="`Opción ${optionIndex + 1}`"
                  v-model="option.name"
                  hide-details
                  outlined
                  :rules="v.attributeOptionsNames"
                )
              v-col.d-flex.align-center(cols="auto" v-if="optionIndex !== 0")
                v-btn(color="error" small @click="removeOption(optionIndex, attribute.options)" text)
                  v-icon.small mdi mdi-delete
                  | Eliminar

            v-btn.mt-4(
              color="primary"
              small
              text
              @click="addOption(attribute.options)"
            ) + Agregar valor
        v-btn.my-4.rounded-xl(color="primary" @click="addAttribute" :disabled="form.attributes.length >= 4") Añadir Atributo
        h3.my-4 Unidad de medida
        v-row
          v-col(cols="12" md="6")
            v-combobox(
              label="Unidad de medida"
              v-model="form.unit_of_measure"
              :items="unitOfMeasurements"
              outlined
              persistent-hint
              hint="Escribe para buscar una unidad de medida, si no existe, se creará automáticamente"
              )
        .text-right
          v-btn(color="primary" outlined @click="handleCancel" :disabled="loading").mr-2 Cancelar
          v-btn(color="primary" type="submit" :loading="loading") Guardar
  v-dialog(v-model="showExample" max-width="600")
    v-card
      v-card-title Ejemplo de producto base
      v-card-text
        h4 Producto: Bebida Gaseosa
        h4 i. Línea: Alimentos
        h4 ii. Atributos:
        ul
          li Atributo 1: Tamaño
            ul
              li Opción 1: 500ml
              li Opción 2: 1L
              li Opción 3: 2L
          li Atributo 2: Marca
            ul
              li Opción 1: Coca Cola
              li Opción 2: Pepsi
              li Opción 3: Fanta
          li Atributo 3: Tipo
            ul
              li Opción 1: Zero
              li Opción 2: Light
              li Opción 3: Normal
        h4 iii. Unidad de medida:
        ul
          li Opciones: Selecciona la unidad deseada (unitaria, por litros, mL, etc.), adaptándola a la necesidad de tu análisis o inventario.

        .text-right.mt-4
          v-btn(color="primary" @click="showExample = false") Entendido
</template>

<script>
import { mapActions } from 'vuex'
import FormMixin from '@/components/mixins/FormMixin'
import validations from '@/shared/formValidations'
import ShowInfoBtn from '@/components/common/ShowInfoBtn.vue'
import InfoAlert from '@/components/common/InfoAlert.vue'
import UpperCaseTextInput from '@/components/common/UpperCaseTextInput.vue'
import { WIZARD_ELEMENTS_ID, WIZARD_STEPS_EVENTS } from '@/constants'
import { globalEmitter } from '@/utils/eventBus'

export default {
  mixins: [FormMixin],
  components: {
    ShowInfoBtn,
    InfoAlert,
    UpperCaseTextInput
  },
  data () {
    return {
      WIZARD_ELEMENTS_ID,
      WIZARD_STEPS_EVENTS,
      instanceId: this.$route.params.id || null,
      showExample: false,
      attributesAlertMessage: 'En esta lista desplegable encontrarás los atributos más comunes, sin embargo, cuando quieras puedes agregar uno nuevo!',
      form: {
        name: null,
        line_of_business: null,
        unit_of_measure: null,
        attributes: [
          {
            name: null,
            options: [
              { name: null },
            ]
          },
          {
            name: null,
            options: [
              { name: null },
            ]
          }
        ],
      },
      linesOfBusiness: [],
      unitOfMeasurements: [],
      attributesSuggestions: [],
    }
  },
  methods: {
    globalEmitter,
    ...mapActions('product', [
      'getBaseProduct',
      'createBaseProduct',
      'editBaseProduct',
      'attributeAutosuggest',
      'unitOfMeasureAutosuggest',
      'listLineOfBusiness'
    ]),
    onSuccess () {
      globalEmitter(WIZARD_STEPS_EVENTS.BASE_PRODUCT_CREATED)
      this.$router.push('/productos')
    },
    getAction () {
      return this.instanceId ? this.editBaseProduct : this.createBaseProduct
    },
    async fetchForm () {
      if (this.instanceId) {
        const { data } = await this.getBaseProduct(this.instanceId)
        this.form = {
          name: data.name,
          line_of_business: data.line_of_business,
          unit_of_measure: data.unit_of_measure,
          attributes: data.attributes.map(attribute => ({
            name: attribute.name,
            options: attribute.options.map(option => ({ name: option.name }))
          }))
        }
      }
      if (!this.linesOfBusiness.length) {
        const { data: linesOfBusiness } = await this.listLineOfBusiness()
        this.linesOfBusiness = linesOfBusiness
      }
      if (!this.unitOfMeasurements.length) {
        const { data: unitOfMeasurements } = await this.unitOfMeasureAutosuggest()
        this.unitOfMeasurements = unitOfMeasurements
      }
      if (!this.attributesSuggestions.length) {
        const { data: attributesSuggestions } = await this.attributeAutosuggest()
        this.attributesSuggestions = attributesSuggestions
      }
    },
    getData () {
      const data = { data: this.form }
      if (this.instanceId) {
        data.id = this.instanceId
      }
      return data
    },
    resetForm () {
      this.form = {
        name: null,
      }
    },
    handleCancel () {
      this.$router.push('/productos')
    },
    addAttribute () {
      this.form.attributes.push({
        name: null,
        options: [
          { name: null },
          { name: null },
          { name: null },
          { name: null }
        ]
      })
    },
    addOption (options) {
      options.push({ name: null })
    },
    removeOption (index, options) {
      options.splice(index, 1)
    },
    removeAttribute (index) {
      this.form.attributes.splice(index, 1)
    }
  },
  computed: {
    title () {
      return this.instanceId ? 'Editar producto base' : 'Añadir producto base'
    },
    v () {
      return {
        name: [validations.isRequired],
        line_of_business: [validations.isRequired],
        attributeNames: [validations.isRequired],
        attributeOptionsNames: [validations.isRequired]
      }
    }
  },
  watch: {
    dialog () {
      if (!this.dialog) {
        this.onClose()
      }
    }
  },
  created () {
    this.fetchForm()
  }
}
</script>