<template lang="pug">
v-container(v-if="plan !== null" fluid)
  CostPlanForm(v-if="plan.plan_type === 'COSTOS'" :plan="plan" edit)
  PlanForm(v-else :plan="plan" edit)
</template>

<script>
import { mapActions } from 'vuex'
import PlanForm from '../components/plan/PlanForm.vue'
import CostPlanForm from '../components/plan/forms/CostPlanForm.vue'
export default {
  components: {
    PlanForm,
    CostPlanForm
  },
  data () {
    return {
      plan: null
    }
  },
  methods: {
    ...mapActions('plan', ['getPlan'])
  },
  async created () {
    const response = await this.getPlan({ planId: this.$route.params.planId })

    if (response.status === 200) {
      this.plan = response.data
    }
  }
}
</script>