<template lang="pug">
div
  v-currency-field.mb-4(v-model="form.average_ticket" label="Ticket promedio" outlined type="number" :min="0" prefix="$" :rules="validations.average_ticket"
    :messages="['Ticket promedio: Este es el valor promedio de las ventas en un periodo de acuerdo a la unidad típica de venta pueden ser precio de producto ( Ej. Cajas de zapatos, bolsas de pellets, etc) o también precio promedio de servicios (Limpieza de estanques, m3 de madera aserrada, asesorías, etc)']")
  v-select(v-model="form.projection_type" @change="onChangeProyectionType" label="Tipo de proyección" outlined :items="projectionTypeOptions"
    :messages="['Tipo de proyección: En el caso que las ventas o producción sean diarias recomendamos ponerlo diario, por el contrario, si por la naturaleza del negocio se cierran ventas en periodos semanales se recomienda ese horizonte de tiempo.']")
</template>

<script>
import validations from '../../../shared/formValidations'

export default {
  props:['form'],
  data () {
    return {
      proyectionTypesDescription: {
        'Semanal': 'Proyección semanal: Si solo necesitas conocer el número de ventas que se esperan en una semana, selecciona esta opción.',
        'Diario': 'Proyección diaria: Si necesitas conocer cuanto debes vender cada día, selecciona esta opción.'
      },
      projectionTypeOptions: [
        {
          value: 'WEEKLY',
          text: 'Semanal'
        },
        {
          value: 'DAILY',
          text: 'Diario'
        }
      ],
      validations: {
        average_ticket: [validations.isRequired, validations.min(0)]
      }
    }
  },
  methods: {
    onChangeProyectionType (value) {
      console.log(value)
      if (value === 'WEEKLY') {
        this.form.work_days = {
          monday: 100,
          tuesday: 100,
          wednesdayes: 100,
          thursday: 100,
          friday: 100,
          saturday: 100,
          sunday: 100
        }
      } else {
        this.form.work_days = {
          monday: 100,
          tuesday: 100,
          wednesdayes: 100,
          thursday: 100,
          friday: 100,
          saturday: 50,
          sunday: 0
        }
      }
    }
  },
  computed: {
    proyectionTypeHint () {
      return this.proyectionTypesDescription[this.form.sales_projection_type]
    }
  }
}
</script>