<template lang="pug">
v-container(fluid)
  ListProductionCapacity
</template>

<script>
import ListProductionCapacity from '@/components/production_capacity/ListProductionCapacity'

export default {
  components: {
    ListProductionCapacity,
  },
}
</script>