<template lang="pug">
v-card.pb-4
  v-card-title
    span Líneas de Negocio
    ShowInfoBtn(@click="$refs.infoAlert.showAlert()")
    v-spacer
    v-btn(color="primary" @click="$refs.lineOfBusinessFormModal.openDialog(); globalEmitter(WIZARD_STEPS_EVENTS.ON_CLICK_CREATE_LINE_OF_BUSINESS)" :id="WIZARD_ELEMENTS_ID.BTN_CREATE_LINE_OF_BUSINESS")
      v-icon.mr-2 mdi-note-edit-outline
      span Nueva línea de negocio
  v-card-text
    InfoAlert(:message="alertMessage" ref="infoAlert" :id="WIZARD_ELEMENTS_ID.HELP_LINE_OF_BUSINESS" :showInfo="$route.query.help_line_of_business")
  v-simple-table(v-if="hasLineOfBusiness" key="customer-classification")
    template(v-slot:default)
      thead(key="customer-classification-header")
        tr
          th Nombre
          th(style="width: 80px") Acciones
      tbody(key="customer-classification-body")
        tr(v-for="item in items" :key="item.id")
          td {{item.name}}
          td
            v-menu(offset-y left)
              template(v-slot:activator="{ on, attrs }")
                v-btn(icon v-bind="attrs" v-on="on")
                  v-icon mdi-dots-vertical
              v-list
                v-list-item(@click="$refs.lineOfBusinessFormModal.openDialog(item.id)")
                  v-list-item-title Editar
                v-list-item(@click="deleteItem(item.id)")
                  v-list-item-title Eliminar
  EmptyState(
    v-else
    title="Líneas de Negocios"
    description="Aun no tienes líneas de negocio registradas, comienza creando una nueva línea de negocio para organizar tus productos."
    primaryActionText="Crear Línea de Negocio"
    @click-primary-action="$refs.lineOfBusinessFormModal.openDialog()"
  )
  LineOfBusinessFormModal(ref="lineOfBusinessFormModal" @save="fetchItems")
  CustomConfirmDialog(ref="confirmDialog")
</template>

<script>
import { mapActions } from 'vuex'
import LineOfBusinessFormModal from '@/components/products/LineOfBusinessFormModal.vue'
import CustomConfirmDialog from '@/components/common/dialogs/CustomConfirmDialog.vue'
import ShowInfoBtn from '../common/ShowInfoBtn.vue'
import InfoAlert from '../common/InfoAlert.vue'
import EmptyState from '../common/EmptyState.vue'
import { WIZARD_ELEMENTS_ID, WIZARD_STEPS_EVENTS } from '@/constants'
import { globalEmitter } from '@/utils/eventBus'

export default {
  components: {
    LineOfBusinessFormModal,
    CustomConfirmDialog,
    ShowInfoBtn,
    InfoAlert,
    EmptyState
  },
  data () {
    return {
      WIZARD_STEPS_EVENTS,
      WIZARD_ELEMENTS_ID,
      items: [],
      alertMessage: 'Esta función te permite establecer distintas líneas de negocio dentro de tu empresa, facilitando la organización de tus productos por categorías específicas. Podrás asociar productos a cada línea de negocio y, posteriormente, realizar análisis personalizados según tus necesidades. Por ejemplo, si diriges una distribuidora, puedes diferenciar entre una línea de alimentos y una línea de artículos de limpieza para analizar cada negocio de manera independiente.'
    }
  },
  methods: {
    ...mapActions('product', ['listLineOfBusiness', 'deleteLineOfBusiness']),
    globalEmitter,
    async fetchItems () {
      const { data } = await this.listLineOfBusiness()
      this.items = data || []
    },
    async deleteItem (id) {
      const confirm = await this.$refs.confirmDialog.open(
        'Eliminar línea de negocio',
        '¿Está seguro que desea eliminar esta línea de negocio? los productos asociados a esta línea de negocio no serán eliminados, pero quedarán sin línea de negocio definida.',
        {
          okTitle: 'Eliminar',
        }
      )
      if (confirm) {
        await this.deleteLineOfBusiness(id)
        await this.fetchItems()
      }
    }
  },
  computed: {
    hasLineOfBusiness() {
      return this.$store.state.product.hasLineOfBusiness
    }
  },
  async created () {
    await this.fetchItems()
    if (this.items.length === 0) {
      this.$refs.infoAlert.showAlert()
    }
  }
}
</script>