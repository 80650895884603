<template lang="pug">
v-dialog(v-model="dialog" width="500")
  template(v-slot:activator="{ on, attrs }")
    v-btn(v-bind="attrs" v-on="on") Agregar Registro
  v-card 
    v-card-title Nuevo registro de Porducción
    v-card-text
      v-form(ref="form" @submit.prevent="onSubmit")
        v-text-field(v-model="form.date" label="Fecha" type="date" outlined :rules="v.date")
        v-text-field(v-model="form.product_name" label="Producto" outlined :rules="v.product_name")
        v-currency-field(v-model="form.quantity" label="Cantidad" type="number" outlined :rules="v.quantity")
        v-text-field(v-model="form.production_site" label="Planta" outlined :rules="v.production_site")
        .text-right
          v-btn.mr-2(@click="$refs.form.reset(), dialog = false" color="primary" outlined :disabled="loading") Cancelar
          v-btn(type="submit" color="primary" :loading="loading") Agregar
</template>

<script>
import { mapActions } from 'vuex'
import formValidations from '../../../../../shared/formValidations'
import { formatDate } from '@/utils/dateUtils.js'

export default {
  props: {
    planId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      dialog: false,
      form: {
        date: formatDate(new Date()),
        product_name: null,
        quantity: null,
        production_site: null,
      }
    }
  },
  methods: {
    ...mapActions('real_data', ['createRealProduction']),
    async onSubmit () {
      if (!this.$refs.form.validate()) {
        return
      }
      this.loading = true
      const response = await this.createRealProduction({ data: { ...this.form, plan: this.planId } })

      if (response.status == 201) {
        this.$emit('save', response)
        this.dialog = false
      }
      this.loading = false
    }
  },
  computed: {
    v () {
      return {
        date: [formValidations.isRequired],
        product_name: [formValidations.isRequired],
        quantity: [formValidations.isRequired, formValidations.min(0)],
        production_site: [],
      }
    },
    dialog () {
      
    }
  }
}
</script>
