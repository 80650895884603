import axios from '../../plugins/axios'
import { getCurrentBusinessId } from '../../utils/authUtils'

export const createCommercialProjection = (_, { data } = {} ) => {
  return axios.post(`/estimated/business/${getCurrentBusinessId()}/commercial-projection/`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const setCommercialProjectionByProduct = (_, { data } = {} ) => {
  return axios.post(`/estimated/business/${getCurrentBusinessId()}/commercial-projection-item/set-by-product/`, data)
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const listCommercialProjections = (_, { params } = {}) => {
  return axios.get(`/estimated/business/${getCurrentBusinessId()}/commercial-projection/`, { params })
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const editCommercialProjection = (_, { id, data } = {} ) => {
  return axios.patch(`/estimated/business/${getCurrentBusinessId()}/commercial-projection/${id}/`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const commercialProjectionUpdateOptimalMix = (_, { id } = {} ) => {
  return axios.post(`/estimated/business/${getCurrentBusinessId()}/commercial-projection/${id}/update-optimal-mix/`)
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const deleteCommercialProjection = (_, id) => {
  return axios.delete(`/estimated/business/${getCurrentBusinessId()}/commercial-projection/${id}/`)
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const getCommercialProjection = (_, id) => {
  return axios.get(`/estimated/business/${getCurrentBusinessId()}/commercial-projection/${id}/`)
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const createCommercialProjectionItem = (_, { data } = {} ) => {
  return axios.post(`/estimated/business/${getCurrentBusinessId()}/commercial-projection-item/`, data)
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const listCommercialProjectionItems = (_, { params } = {}) => {
  return axios.get(`/estimated/business/${getCurrentBusinessId()}/commercial-projection-item/`, { params })
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const editCommercialProjectionItem = (_, { id, data } = {} ) => {
  return axios.patch(`/estimated/business/${getCurrentBusinessId()}/commercial-projection-item/${id}/`, data)
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const deleteCommercialProjectionItem = (_, id) => {
  return axios.delete(`/estimated/business/${getCurrentBusinessId()}/commercial-projection-item/${id}/`)
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const getCommercialProjectionItem = (_, id) => {
  return axios.get(`/estimated/business/${getCurrentBusinessId()}/commercial-projection-item/${id}/`)
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const getProjectionByCutomersChart = (_, params) => {
  return axios.get(`/estimated/business/${getCurrentBusinessId()}/commercial-projection-chart/chart-projection-by-customer`, { params })
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const getTotalProjectionChart = (_, params) => {
  return axios.get(`/estimated/business/${getCurrentBusinessId()}/commercial-projection-chart/total-projection`, { params })
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const getTopProductsTable = (_, params) => {
  return axios.get(`/estimated/business/${getCurrentBusinessId()}/commercial-projection-chart/table-projection-by-product`, { params })
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const getHeatMapChart = (_, params) => {
  return axios.get(`/estimated/business/${getCurrentBusinessId()}/commercial-projection-chart/heatmap`, { params })
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const getOptimalMixByCustomerChart = (_, params) => {
  return axios.get(`/estimated/business/${getCurrentBusinessId()}/commercial-projection-chart/chart-optimal-mix-by-customer`, { params })
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const getOptimalMixByProductChart = (_, params) => {
  return axios.get(`/estimated/business/${getCurrentBusinessId()}/commercial-projection-chart/chart-optimal-mix-by-product`, { params })
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const getOptimalMixTable = (_, params) => {
  return axios.get(`/estimated/business/${getCurrentBusinessId()}/commercial-projection-chart/table-optimal-mix`, { params })
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const exportOptimalMix = (_, params) => {
  return axios.get(`/estimated/business/${getCurrentBusinessId()}/commercial-projection-chart/export-optimal-mix`, { params, responseType: 'blob'})
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'optimal_mix.xlsx');
      document.body.appendChild(link);
      link.click();
    })
    .catch(error => ({ error }))
}

export const getSalePlanByProductChart = (_, params) => {
  return axios.get(`/estimated/business/${getCurrentBusinessId()}/commercial-projection-sale-plan-item/chart-sale-plan-by-product`, { params })
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const getSalePlanByCustomerChart = (_, params) => {
  return axios.get(`/estimated/business/${getCurrentBusinessId()}/commercial-projection-sale-plan-item/chart-sale-plan-by-customer`, { params })
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const importSalePlan = (_, { data } = {} ) => {
  return axios.post(`/estimated/business/${getCurrentBusinessId()}/commercial-projection-sale-plan-item/import/`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const getSalePlanItem = (_, id) => {
  return axios.get(`/estimated/business/${getCurrentBusinessId()}/commercial-projection-sale-plan-item/${id}/`)
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const listSalePlanItems = (_, params) => {
  return axios.get(`/estimated/business/${getCurrentBusinessId()}/commercial-projection-sale-plan-item/`, { params })
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const createSalePlanItem = (_, { data } = {} ) => {
  return axios.post(`/estimated/business/${getCurrentBusinessId()}/commercial-projection-sale-plan-item/`, data)
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const editSalePlanItem = (_, { id, data } = {} ) => {
  return axios.patch(`/estimated/business/${getCurrentBusinessId()}/commercial-projection-sale-plan-item/${id}/`, data)
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const setSalePlanItemByProduct = (_, { data } = {} ) => {
  return axios.post(`/estimated/business/${getCurrentBusinessId()}/commercial-projection-sale-plan-item/set-by-product/`, data)
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const deleteSalePlanItem = (_, id) => {
  return axios.delete(`/estimated/business/${getCurrentBusinessId()}/commercial-projection-sale-plan-item/${id}/`)
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const exportSalePlan = (_, params) => {
  return axios.get(`/estimated/business/${getCurrentBusinessId()}/commercial-projection-sale-plan-item/export-sale-plan`, { params, responseType: 'blob'})
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'plan_ventas.xlsx');
      document.body.appendChild(link);
      link.click();
      return { data: response.data, response, status: response.status }
    })
    .catch(error => ({ error }))
}

export const downloadOptimalMix = (_, params) => {
  return axios.get(`/estimated/business/${getCurrentBusinessId()}/commercial-projection-chart/export-optimal-mix`, { params, responseType: 'blob'})
    .catch(error => ({ error }))
}

export const statisticsOptimalMix = (_, params) => {
  return axios.get(`/estimated/business/${getCurrentBusinessId()}/commercial-projection-chart/statistics-optimal-mix/`, { params })
    .catch(error => ({ error }))
}

export const downloadSalePlan = (_, params) => {
  return axios.get(`/estimated/business/${getCurrentBusinessId()}/commercial-projection-sale-plan-item/export-sale-plan`, { params, responseType: 'blob'})
    .catch(error => ({ error }))
}

export const downloadProjectionItems = (_, id) => {
  return axios.get(`/estimated/business/${getCurrentBusinessId()}/commercial-projection/${id}/export-items/`, { responseType: 'blob'})
    .catch(error => ({ error }))
}

export const createPlanSale = (_, id) => {
  return axios.post(`/estimated/business/${getCurrentBusinessId()}/commercial-projection/${id}/create-plan-sale/`)
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}
export const getProjectionComparisonChart = (_) => {
  return axios.get(`/estimated/business/${getCurrentBusinessId()}/commercial-projection-chart/chart-projection-comparison`)
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const getStatisticsOptimalMix = (_, params) => {
  return axios.get(`/estimated/business/${getCurrentBusinessId()}/commercial-projection-chart/statistics-optimal-mix/`, { params })
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const getStatisticsSalePlan = (_, params) => {
  return axios.get(`/estimated/business/${getCurrentBusinessId()}/commercial-projection-sale-plan-item/statistics-sale-plan/`, { params })
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}