<template lang="pug">
div

</template>

<script>
import { driver } from "driver.js";
import { WIZARD_STEPS, WIZARD_STEPS_EVENTS } from "../../constants";
import { EventBus } from "../../utils/eventBus";
import { mapMutations } from 'vuex'

export default {
  data() {
    return {
      driver: new driver({
        popoverClass: 'driverjs-theme',
        allowClose: false,
        animate: false,
        showButtons: ['next', 'close'],
        doneBtnText: "Cerrar",
        nextBtnText: "Siguiente",
        prevBtnText: "Anterior",
        steps: this.getSteps(),
        onNextClick: (e, step) => {
          this.nextStep()
        },
      }),
      dialog: false,
      currentStep: 36,
      eventToNext: null,
    }
  },
  methods: {
    ...mapMutations('app', ['SET_WIZARD_IS_ACTIVE']),
    onChangeStep(currentStep) {
      if (currentStep.nextStep?.path && currentStep.nextStep.path !== this.$route.path) {
        this.$router.push(currentStep.nextStep.path)
      }

      if (currentStep.nextStep?.updateQueryParams) {
        this.$router.push({ query: currentStep.nextStep.updateQueryParams })
      }

      if (currentStep.nextStep?.eventToNext) {
        this.eventToNext = currentStep.nextStep.eventToNext
        currentStep.nextStep.eventToNext = null
      }
    },
    startDrive(step) {
      console.log('startDrive', step)
      this.SET_WIZARD_IS_ACTIVE(true)
      if (step || step === 0) {
        this.currentStep = step
      }
      this.setCurrentStep(this.currentStep)
    },
    eventHandler(event) {
      return () => {
        console.log('eventHandler', event)
        console.log('eventToNext', this.eventToNext)

        const step = this.getSteps()[this.currentStep]

        if (step.eventToDestroy === event) {
          this.driver.destroy()
          return
        }

        if (step.eventToNext === event) {
          this.nextStep()
        }
      }
    },
    nextStep() {
      console.log('nextStep')
      if (!this.driver.isActive()) {
        this.currentStep++
        this.setCurrentStep(this.currentStep)
        return
      }


      const step = this.getSteps()[this.currentStep]

      if (step.destroyOnNext) {
        this.driver.destroy()
        return
      }

      if (step.nextStep?.path && step.nextStep.path !== this.$route.path) {
        this.$router.push(step.nextStep.path)
      }

      if (step.nextStep?.updateQueryParams) {
        this.$router.push({ query: step.nextStep.updateQueryParams })
      }

      if (step.nextStep?.eventToNext) {
        this.eventToNext = step.nextStep.eventToNext
      }

      this.$nextTick(() => {

        this.currentStep++
        setTimeout(() => {
          this.driver.moveNext()
        }, step.nextStep?.delay || 150)

        if (this.currentStep === this.getSteps().length) {
          this.driver.destroy()
          this.SET_WIZARD_IS_ACTIVE(false)
        }
      })
    },
    setCurrentStep(stepIndex) {
      this.currentStep = stepIndex
      const step = this.getSteps()[this.currentStep]
      console.log(step.path && step.path !== this.$route.path)

      if (step.path && step.path !== this.$route.path) {
        this.$router.push(step.path)
      }

      setTimeout(() => {
        if (!this.driver.isActive()) {
          this.driver.drive(this.currentStep)
        }

        if (step?.path && step.path !== this.$route.path) {
          this.$router.push(step.path)
        }

        if (step?.updateQueryParams) {
          this.$router.push({ query: step.updateQueryParams })
        }

        if (step.nextStep?.eventToNext) {
          this.eventToNext = step.eventToNext
          step.eventToNext = null
        }
        this.driver.refresh()
      }, 150)
    },
    getSteps() {
      const steps = WIZARD_STEPS.map((step, index) => {
        return {
          element: '#' + step.elementId,
          popover: {
            title: step.title,
            description: step.description,
            showButtons: step.showButtons,
            nextBtnText: step.nextBtnText,
          },
          // Custom fields
          eventToNext: step.eventToNext,
          eventToDestroy: step.eventToDestroy,
          clickElement: step.clickElement,
          updateQueryParams: step.updateQueryParams,
          path: step.path,
          nextStep: WIZARD_STEPS[index + 1] ? WIZARD_STEPS[index + 1] : null,
          previousStep: WIZARD_STEPS[index - 1] ? WIZARD_STEPS[index - 1] : null,
          destroyOnNext: step.destroyOnNext,
        }
      });
      return steps;
    },
  },
  watch: {
    eventToNext() {
      console.log('eventToNext', this.eventToNext)
    },
  },
  created() {
    for (let key in WIZARD_STEPS_EVENTS) {
      if (key === 'START_WIZARD') {
        continue
      }
      if (key === 'OPEN_LAST_WIZARD_STEP') {
        continue
      }
      EventBus.$on(WIZARD_STEPS_EVENTS[key], this.eventHandler(WIZARD_STEPS_EVENTS[key]))
    }
    EventBus.$on(WIZARD_STEPS_EVENTS.START_WIZARD, this.startDrive)
    EventBus.$on(WIZARD_STEPS_EVENTS.OPEN_LAST_WIZARD_STEP, () => this.startDrive(this.currentStep))
  },
  beforeDestroy() {
    for (let key in WIZARD_STEPS_EVENTS) {
      EventBus.$off(WIZARD_STEPS_EVENTS[key])
    }
  }
}
</script>