<template lang="pug">
  #report-view
    v-container(fluid)
      v-card()
        v-card-text
          v-tabs.mb-3(
            v-model="currentTab"
            background-color="transparent"
            color="primary")
            v-tab(
              v-for="report in datastudioReports"
              :key="report.id") {{report.name}}
          v-tabs-items(v-model="currentTab")
            v-tab-item(
              v-for="report in datastudioReports"
              :key="report.id")
              v-card(width="100%" height=850)
                iframe(
                  width="100%"
                  height="100%"
                  :src="report.report_url"
                  frameborder="0"
                  style="border:0"
                  allowfullscreen)
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data () {
    return {
      currentTab: null,
      datastudioReports: []
    }
  },
  methods: {
    ...mapActions('reports', ['listDataStudioReports'])
  },
  async created () {
    this.datastudioReports = (await this.listDataStudioReports()).data
  }
}
</script>

<style lang="scss" scoped>
#report-view {
}
</style>
