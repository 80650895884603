import axios from '../../plugins/axios'

export const getInvitationByToken = (_, token) => {
  return axios.get(`/client/business-membership-invitation/get_by_token/${token}/`)
    .catch(err => err.response)
}

export const acceptInvitation = (_, token) => {
  return axios.get(`/client/business-membership-invitation/accept/${token}/`)
    .catch(err => err.response)
}

export const declineInvitation = (_, token) => {
  return axios.get(`/client/business-membership-invitation/decline/${token}/`)
    .catch(err => err.response)
}