<template lang="pug">
v-card
  v-card-title
    .d-flex.flex-column.align-center.w-100
      span Potencial Comercial
      span  por cliente (Venta)
  v-card-text
    Pie(:chart-data="chartData" :chart-options="chartOptions" :chart-id="id" :width="200" :height="200")
</template>

<script>
import { Pie } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement  } from 'chart.js'
import chartsUtils from '@/utils/chartsUtils'
import { mapActions } from 'vuex'

ChartJS.register(Title, Tooltip, BarElement, CategoryScale, LinearScale, ArcElement, Legend)

export default {
  props: {
    filters: {
      type: Object,
      required: true,
      default: () => ({})
    },
    projection: {
      type: Number,
      default: null,
    },
  },
  components: {
    Pie,
  },
  data() {
    return {
      id: this._uid.toString(),
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            display: true,
            position: 'top',
          },
          tooltip: {
            callbacks: {
              label: function(context) {
                return `${context.label} $${context.formattedValue}`
              }
            }
          }
        }
      },
      chartData: {
        labels: ['Cliente 1', 'Cliente 2', 'Cliente 3', 'Cliente 4'],
        datasets: [
          {
            data: [300, 50, 100, 150],
            backgroundColor: chartsUtils.colors,
            hoverBackgroundColor: chartsUtils.colors
          }
        ]
      }
    }
  },
  methods: {
    ...mapActions('commercial_projection', ['getProjectionByCutomersChart']),
    async fetchData() {
      const params = {
        ...this.filters,
        projection: this.projection || this.$route.params.id
      }
      const { data, error } = await this.getProjectionByCutomersChart(params)

      const { labels, datasets } = data

      this.chartData.labels = labels
      this.chartData.datasets = datasets.map((dataset, index) => ({
        data: dataset,
        backgroundColor: chartsUtils.colors,
        hoverBackgroundColor: chartsUtils.colors
      }))
      this.chartData = { ...this.chartData }
    }
  },
  watch: {
    filters: {
      handler() {
        this.fetchData()
      },
      deep: true,
    }
  },
  created() {
    this.fetchData()
  }
}
</script>
