<template lang="pug">
  v-dialog(v-model="dialog" width="500")
    v-card
      v-card-title {{ title }}
      v-card-text
        v-form(ref="form" @submit.prevent="onSubmit")
          v-text-field(
            label="Nombre de la línea de Negocio"
            v-model="form.name"
            outlined
            :rules="v.name")
          .text-right
            v-btn(color="primary" outlined @click="closeDialog" :disabled="loading").mr-2 Cancelar
            v-btn(color="primary" type="submit" :loading="loading") Guardar
  </template>

  <script>
  import { mapActions } from 'vuex'
  import FormMixin from '@/components/mixins/FormMixin'
  import validations from '@/shared/formValidations'
  import { WIZARD_STEPS_EVENTS } from '@/constants'
  import { globalEmitter } from '@/utils/eventBus';

  export default {
    mixins: [FormMixin],
    data () {
      return {
        WIZARD_STEPS_EVENTS,
        instanceId: null,
        form: {
          name: null,
        },
        dialog: false,
      }
    },
    methods: {
      ...mapActions('product', ['createLineOfBusiness', 'editLineOfBusiness', 'getLineOfBusiness']),
      async openDialog (instanceId) {
        this.instanceId = instanceId
        await this.fetchForm()
        this.dialog = true
      },
      closeDialog () {
        this.dialog = false
      },
      onSuccess () {
        this.dialog = false
        globalEmitter(WIZARD_STEPS_EVENTS.LINE_OF_BUSINESS_CREATED)
        this.$emit('save')
      },
      getAction () {
        return this.instanceId ? this.editLineOfBusiness : this.createLineOfBusiness
      },
      async fetchForm () {
        if (this.instanceId) {
          const { data } = await this.getLineOfBusiness(this.instanceId)
          this.form = {
            name: data.name,
          }
        }
      },
      getData () {
        const data = { data: this.form }
        if (this.instanceId) {
          data.id = this.instanceId
        }
        return data
      },
      resetForm () {
        this.form = {
          name: null,
        }
      },
      onClose () {
        this.instanceId = null
        this.resetForm()
      }
    },
    computed: {
      title () {
        return this.instanceId ? 'Editar Línea de Negocio' : 'Crear Línea de Negocio'
      },
      v () {
        return {
          name: [validations.isRequired]
        }
      }
    },
    watch: {
      dialog () {
        if (!this.dialog) {
          this.onClose()
        }
      }
    }
  }
  </script>