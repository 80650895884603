import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import customFilters from './utils/customVueFilters'
import './plugins/v-currency-field.js'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import { vMaska } from 'maska'
import "driver.js/dist/driver.css";

import './styles/main.scss'
import './styles/drivejs.scss';

Vue.filter('currency', customFilters.currency)
Vue.filter('number', customFilters.number)
Vue.filter('planEstimation', customFilters.planEstimation)
Vue.filter('userRole', customFilters.userRole)
Vue.filter('dateFormat', customFilters.dateFormat)

Vue.use(Toast)

Vue.directive('maska', vMaska)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
