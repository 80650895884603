<template lang="pug">
v-container(fluid)
  PageHeader.mb-4(v-if="commercialProjection" :title="commercialProjection.name" backTo="/proyeccion-comercial")
  //- Filtros de búsqueda
  v-card.mb-4
    v-card-text
      v-form(@submit.prevent="filter")
        v-row
          v-col
            v-row
              //- Campos de filtro
              v-col(cols="12" md="3")
                v-autocomplete(
                  v-model="filters.customer"
                  :items="customers"
                  :search-input.sync="searchCustomer"
                  label="Cliente"
                  return-object
                  item-text="name"
                  outlined
                  hide-details
                  multiple
                  chips
                  no-filter)
              v-col(cols="12" md="3")
                v-autocomplete(
                  v-model="filters.lineOfBusiness"
                  :items="lineOfBusiness"
                  :search-input.sync="searchLineOfBusiness"
                  label="Línea de negocio"
                  return-object
                  item-text="name"
                  outlined
                  hide-details
                  multiple
                  chips
                  no-filter)
              v-col(cols="12" md="3")
                v-autocomplete(
                  v-model="filters.product"
                  :items="products"
                  label="Producto"
                  :search-input.sync="searchProduct"
                  return-object
                  item-text="name"
                  outlined
                  multiple
                  chips
                  hide-details)
              v-col(cols="12" md="3")
                v-autocomplete(
                  v-model="filters.attribute"
                  :items="attributes"
                  label="Atributo"
                  :search-input.sync="searchAttribute"
                  return-object
                  :item-text="attributeName"
                  outlined
                  multiple
                  no-filter
                  chips
                  hide-details)
          v-col(cols="12" md="auto")
            v-row
              v-col(cols="12" md="auto")
                //- Botón de búsqueda
                v-btn(color="primary" type="submit" block height="54") Filtrar

  //- Gráfico de proyección comercial
  v-row
    v-col(cols="12" md="12")
      v-card(:id="WIZARD_ELEMENTS_ID.ANALIYSIS_COMMERCIAL_PROJECTION")
        v-toolbar(color="primary" dark flat)
          v-tabs(v-model="currentTab" grow)
            v-tab(v-for="tab in tabs" :key="tab" ) {{ tab }}
        v-tabs-items.mt-3(v-model="currentTab")

          v-tab-item(key="POTENCIAL COMERCIAL")
            div(:id="WIZARD_ELEMENTS_ID.SECTION_MARKET_SIZE")
              v-row
                v-col(cols="12" md="3" style="background-color: #f5f5f5")
                  .ml-3.mt-3
                    CommercialProjectionByCustomerChart.mb-4(:filters="currentFilters")
                    TableProductTop(:filters="currentFilters")
                v-col(cols="12" md="9")
                  MarketSize(:filters="currentFilters" @updateMarketSize="marketSize = $event")
                  HeatMap(:filters="currentFilters" :marketSize="marketSize")

          v-tab-item(key="MIX OPTIMO")
            div(:id="WIZARD_ELEMENTS_ID.SECTION_OPTIMAL_MIX")
              ChartOptimalMix(:filters="currentFilters" :totalYearProfit="totalYearProfit")
              TableOptimalMix(:filters="currentFilters" @setTotalYearProfit="totalYearProfit = $event")

          v-tab-item(key="PLAN DE VENTAS")
            div(:id="WIZARD_ELEMENTS_ID.SECTION_SALE_PLAN")
              SalePlanTab(:filters="currentFilters")
</template>

<script>
import CommercialProjectionByCustomerChart from '@/components/commercial_projection/charts/CommercialProjectionByCustomerChart.vue'
import TableProductTop from '@/components/commercial_projection/charts/TableProductTop.vue'
import MarketSize from '@/components/commercial_projection/charts/MarketSize.vue'
import HeatMap from '../components/commercial_projection/charts/HeatMap.vue'
import ChartOptimalMix from '../components/commercial_projection/charts/ChartOptimalMix.vue'
import TableOptimalMix from '../components/commercial_projection/charts/TableOptimalMix.vue'
import SalePlanTab from '@/components/commercial_projection/SalePlanTab.vue'
import PageHeader from '@/components/common/PageHeader.vue'
import { WIZARD_ELEMENTS_ID } from '@/constants'

import { debounce } from 'debounce'
import { mapActions } from 'vuex'

export default {
  components: {
    CommercialProjectionByCustomerChart,
    MarketSize,
    TableProductTop,
    HeatMap,
    ChartOptimalMix,
    TableOptimalMix,
    SalePlanTab,
    PageHeader,
  },
  data() {
    return {
      WIZARD_ELEMENTS_ID,
      filters: {
        customer: [],
        lineOfBusiness: [],
        product: [],
        attribute: [],
      },
      currentFilters: {},
      searchProduct: null,
      searchCustomer: null,
      searchLineOfBusiness: null,
      searchAttribute: null,
      currentTab: this.parseTab(),
      tabs: ['POTENCIAL COMERCIAL', 'MIX OPTIMO', 'PLAN DE VENTAS'],
      customers: [],
      lineOfBusiness: [],
      products: [],
      attributes: [],
      totalYearProfit: 0,
      marketSize: 0,
      commercialProjection: null,
    }
  },
  methods: {
    ...mapActions('product', ['listProducts', 'listLineOfBusiness', 'listAttributeOptions']),
    ...mapActions('customer', ['listCustomers']),
    ...mapActions('commercial_projection', ['getCommercialProjection']),
    filter() {
      this.currentFilters = {
        customer__in: this.filters.customer?.map(c => c.id).join(','),
        line_of_business__in: this.filters.lineOfBusiness?.map(l => l.id).join(','),
        product__in: this.filters.product?.map(p => p.id).join(','),
        attribute_option__in: this.filters.attribute?.map(a => a.id).join(','),
      }
    },
    async fetchCustomers() {
      const params = {
        search: this.searchCustomer,
        projection: this.$route.params.id,
        page_size: 25
      }
      const { data, error } = await this.listCustomers({ params })
      if (!error) {
        this.customers = [...this.filters.customer, ...data.results]
      }
    },
    async fetchComercialProjection() {
      const { data, error } = await this.getCommercialProjection(this.$route.params.id)
      if (!error) {
        this.commercialProjection = data
      }
    },
    async fetchLineOfBusiness() {
      const params = {
        search: this.searchLineOfBusiness,
        projection: this.$route.params.id,
        page_size: 25
      }
      const { data, error } = await this.listLineOfBusiness({ params })
      if (!error) {
        this.lineOfBusiness = [...this.filters.lineOfBusiness, ...data]
      }
    },
    async fetchAttributes () {
      const params = {
        search: this.searchAttribute,
        projection: this.$route.params.id,
        page_size: 25
      }
      const { data, error } = await this.listAttributeOptions({ params })
      if (!error) {
        this.attributes = [...this.filters.attribute, ...data.results]
      }
    },
    async fetchProducts() {
      const params = {
        search: this.searchProduct,
        projection: this.$route.params.id,
        page_size: 25
      }
      const { data, error } = await this.listProducts({ params })
      if (!error) {
        this.products = [...this.filters.product, ...data.results]
      }
    },
    attributeName(item) {
      if (item) {
        return `${item.attribute_name}: ${item.name}`;
      }
      return ''
    },
    parseTab() {
      console.log(this.$route.query.tab)

      if (this.$route.query.tab) {
        return parseInt(this.$route.query.tab)
      }
      return 0
    }
  },
  watch: {
    searchProduct() {
      this.fetchProducts()
    },
    searchCustomer() {
      this.fetchCustomers()
    },
    searchLineOfBusiness() {
      this.fetchLineOfBusiness()
    },
    searchAttribute() {
      this.fetchAttributes()
    },
    $route(to, from) {
      this.currentTab = this.parseTab()
    }
  },
  created() {
    this.fetchCustomers = debounce(this.fetchCustomers, 500)
    this.fetchLineOfBusiness = debounce(this.fetchLineOfBusiness, 500)
    this.fetchProducts = debounce(this.fetchProducts, 500)
    this.fetchAttributes = debounce(this.fetchAttributes, 500)
    this.fetchComercialProjection()
    this.fetchCustomers()
    this.fetchLineOfBusiness()
    this.fetchProducts()
    this.fetchAttributes()
  },
}
</script>
