

const _getInfoTemplate = ({ objective, whyIsItImportant, variables, stepByStep, analysis }) => {
  let reusult = `
    <p>
      <div>
        <b>Objetivo</b>
      </div>
      <div>
        ${objective}
      </div>
    </p>
    <p>
      <div>
        <b>¿Por qué es importante?</b>
      </div>
      <div>
        ${whyIsItImportant}
      </div>
    </p>
    <p>
      <div>
        <b>Variables</b>
      </div>
      <div>
        ${variables}
      </div>
    </p>
    <p>
      <div>
        <b>Paso a paso</b>
      </div>
      <ol>
        ${stepByStep.map(step => `<li>${step}</li>`).join('')}
      </ol>
    </p>
    <p>
    </p>
  `

  if (analysis) {
    reusult += `
      <p>
        <div>
          <b>Análisis</b>
        </div>
        <div>
          ${analysis}
        </div>
      </p>
    `
  }

  return reusult
}



export const HOME_INFO_CARDS = [
  {
    icon: 'mdi-calculator-variant-outline',
    title: 'Cálculo de SAM',
    text1: 'Cálculo de',
    text2: 'SAM',
    example: _getInfoTemplate({
      objective: 'Determinar la porción del mercado total que una empresa puede atender con sus capacidades actuales.',
      whyIsItImportant: 'Permite a la empresa enfocar sus recursos en segmentos de mercado donde tiene una ventaja competitiva.',
      variables: 'Capacidades Conjuntas, Línea de Negocios, Clientes actuales y potenciales.',
      stepByStep: [
        'Identificar capacidades actuales.',
        'Definir línea de negocios.',
        'Agregar clientes actuales y potenciales.',
        'Realizar análisis de mercado.',
      ],
      analysis: 'Este sería tu caso base. De acuerdo a tu mercado conocido, se ingresa y estima por cada uno de los clientes como se comporta la compra, en cantidades y precios. Este resultado, entrega el potencial comercial del SAM y el mix optimo asociado a tus capacidades',
    }),
  },
  {
    icon: 'mdi-calculator-variant-outline',
    title: 'Cálculo de TAM',
    text1: 'Cálculo de',
    text2: 'TAM',
    example: _getInfoTemplate({
      objective: 'Estimar el ingreso máximo posible si se capturase el 100% del mercado.',
      whyIsItImportant: 'Proporciona una visión del potencial total del mercado, ayudando en la planificación estratégica a largo plazo.',
      variables: 'Clientes actuales y potenciales, Productos Finales, Precio de Mercado.',
      stepByStep: [
        'Identificar todos los clientes potenciales.',
        'Definir productos finales.',
        'Estimar precio de mercado.',
        'Calcular ingreso máximo.',
      ],
      analysis: 'Siguiendo la linea del caso base, agrego los consumos estimados de clientes desconocidos, además de mi mercado conocido, estimando el tamaño de mercado total de mi negocio. Esto permite identificar el potencial total de tu negocio, y permite analizar que clientes que no conozco pueden ser una oportunidad para enfocar los esfuerzos futuros.',
    }),
  },
  {
    icon: 'mdi-calculator-variant-outline',
    title: 'Cálculo de SOM',
    text1: 'Cálculo de',
    text2: 'SOM',
    example: _getInfoTemplate({
      objective: 'Identificar el segmento del SAM que puede capturarse realísticamente en el corto a mediano plazo.',
      whyIsItImportant: 'Ayuda a establecer objetivos de ventas realistas y alcanzables.',
      variables: 'Clientes actuales y potenciales, Línea de Negocios, Capacidades Conjuntas.',
      stepByStep: [
        'Determinar capacidades actuales.',
        'Identificar clientes actuales y potenciales.',
        'Analizar segmentos del mercado.',
        'Establecer objetivos realistas.',
      ],
      analysis: 'Si para el análisis inicial usaste el caso base, mediante un plan de ventas realistas se define el SOM. Edito el mix optimo de acuerdo a lo que actualmente abarco y asignando metas realistas de venta.',
    }),
  },
  {
    icon: 'mdi-abacus',
    title: 'Evaluación de una Nueva Línea de Negocios',
    text1: 'Evaluación de una Nueva Línea de Negocios',
    text2: '',
    example: _getInfoTemplate({
      objective: 'Analizar el impacto y viabilidad de introducir una nueva línea de productos.',
      whyIsItImportant: 'Permite evaluar riesgos y beneficios antes de invertir recursos en el desarrollo de nuevos productos.',
      variables: 'Línea de Negocios, Productos Finales, Costo Unitario, Precio de Mercado.',
      stepByStep: [
        'Identificar nueva línea de negocios.',
        'Definir productos finales.',
        'Calcular costo unitario.',
        'Estimar precio de mercado.',
      ],
      analysis: 'Luego de crear el caso base, puedes duplicar el caso, creando además una nueva linea de negocios con nuevos productos asociados. Es importante estimar los consumos de tus clientes actuales o nuevos, y según esta información, puedes comparar el desempeño del potencial comercial y mix óptimo con los otros casos, evaluando el impacto en abrir una nueva linea de negocios.',
    }),
  },
  {
    icon: 'mdi-shopping',
    title: 'Lanzamiento de un Nuevo SKU - Desempeño de Producto',
    text1: 'Lanzamiento de un Nuevo',
    text2: 'SKU',
    example: _getInfoTemplate({
      objective: 'Estimar el rendimiento de un nuevo SKU en el mercado actual.',
      whyIsItImportant: 'Ayuda a prever la demanda y planificar la producción y distribución de nuevos productos.',
      variables: 'SKU, Producto Base, Costo Unitario, Precio de Mercado.',
      stepByStep: [
        'Definir el nuevo SKU.',
        'Identificar productos base similares.',
        'Calcular costo unitario.',
        'Estimar precio de mercado.',
      ],
      analysis: 'Luego de crear el caso base, puedes duplicar el caso, añadiendo un nuevo SKU. Esto te permite evaluar el rendimiento potencial del nuevo producto en el mercado y cómo se compara con los productos existentes. Es fundamental para la toma de decisiones en la producción y la distribución.',
    }),
  },
  {
    icon: 'mdi-map-marker-plus',
    title: 'Expansión a una Nueva Zona Geográfica',
    text1: 'Expansión a una Nueva Zona Geográfica',
    example: _getInfoTemplate({
      objective: 'Evaluar el potencial de ventas y los desafíos de ingresar a una nueva región geográfica.',
      whyIsItImportant: 'Informa decisiones estratégicas sobre expansión territorial y la asignación de recursos en nuevas áreas.',
      variables: 'Clientes potenciales, Precio de Mercado, Capacidades Conjuntas.',
      stepByStep: [
        'Identificar nueva zona geográfica.',
        'Agregar clientes potenciales.',
        'Estimar precio de mercado en la nueva zona.',
        'Evaluar capacidades para satisfacer la demanda.',
      ],
      analysis: 'Luego de crear el caso base, puedes duplicar el caso para explorar la expansión en una nueva zona geográfica. Esto te permitirá comparar el potencial comercial de la nueva región con tus áreas actuales y tomar decisiones informadas sobre la expansión.',
    }),
  },
  {
    icon: 'mdi-chart-line',
    title: 'Crecimiento Exponencial',
    text1: 'Crecimiento Exponencial',
    example: _getInfoTemplate({
      objective: 'Modelar el crecimiento acelerado de la empresa para prever desafíos y oportunidades.',
      whyIsItImportant: 'Permite planificar y gestionar el crecimiento, asegurando que la empresa pueda escalar sin comprometer la calidad o la eficiencia.',
      variables: 'Clientes actuales y potenciales, Capacidades Conjuntas, Recursos.',
      stepByStep: [
        'Modelar crecimiento esperado.',
        'Identificar clientes actuales y potenciales.',
        'Evaluar necesidades de recursos.',
        'Ajustar capacidades para soportar el crecimiento.',
      ],
      analysis: 'Luego de crear el caso base, puedes duplicar el caso para simular un escenario de crecimiento exponencial. Esto te ayudará a entender cómo el crecimiento acelerado afectará tus operaciones y qué ajustes necesitas hacer para mantener la competitividad.',
    }),
  },
  {
    icon: 'mdi-chart-bar',
    title: 'Mix Óptimo de Productos',
    text1: 'Mix Óptimo de',
    text2: 'Productos',
    example: _getInfoTemplate({
      objective: 'Determinar la combinación de productos más rentable y eficiente para la empresa.',
      whyIsItImportant: 'Optimiza el uso de recursos y mejora la rentabilidad al enfocar la producción en los productos más rentables.',
      variables: 'Productos Finales, Costo Unitario, Precio de Mercado.',
      stepByStep: [
        'Definir productos finales.',
        'Calcular costo unitario.',
        'Estimar precio de mercado.',
        'Determinar la combinación de productos más rentable.',
      ],
      analysis: 'El mix óptimo, más que un caso individual, es un análisis continuo. Con base en tu caso base, puedes ajustar la combinación de productos para maximizar la rentabilidad y asegurar que estás enfocando tus esfuerzos en los productos que generan el mayor retorno.',
    }),
  },
  {
    icon: 'mdi-chart-bar',
    title: 'Análisis de Rentabilidad por Cliente',
    text1: 'Análisis de Rentabilidad por',
    text2: 'Cliente',
    example: _getInfoTemplate({
      objective: 'Evaluar la rentabilidad de cada cliente individualmente.',
      whyIsItImportant: 'Ayuda a identificar clientes más valiosos y aquellos que pueden estar erosionando la rentabilidad.',
      variables: 'Clientes actuales, Costo Unitario, Precio de Mercado, Capacidades Conjuntas.',
      stepByStep: [
        'Identificar clientes actuales.',
        'Calcular costo unitario.',
        'Estimar precio de mercado.',
        'Evaluar capacidades conjuntas.',
      ],
      analysis: 'Una vez ingresado tu caso, puedes analizar en el plan de ventas final el margen bruto que te otorga cada cliente. Este análisis te permite identificar cuales son los clientes cruciales, que debes enfocarte en mantener y fidelizar para asegurar un éxito comercial.',
    }),
  },
  {
    icon: 'mdi-chart-bar',
    title: 'Impacto de la Variación de Precios',
    text1: 'Impacto de la Variación de',
    text2: 'Precios',
    example: _getInfoTemplate({
      objective: 'Analizar cómo cambios en los precios afectan las ventas y la rentabilidad.',
      whyIsItImportant: 'Informa decisiones de pricing para maximizar ingresos y margen de ganancia.',
      variables: 'Precio de Mercado, Ventas reales, Clientes actuales y potenciales, Productos Finales.',
      stepByStep: [
        'Estimar variaciones de precio.',
        'Analizar ventas reales.',
        'Identificar clientes actuales y potenciales.',
        'Evaluar impacto en productos finales.',
      ],
      analysis: 'Luego de crear el caso base, puedes duplicar el caso. Cambiando los precios de tus productos, puedes analizar cómo varía el mix óptimo y el potencial comercial.',
    }),
  },
  {
    icon: 'mdi-chart-bar',
    title: 'Análisis de Retención de Clientes',
    text1: 'Análisis de Retención de',
    text2: 'Clientes',
    example: _getInfoTemplate({
      objective: 'Medir y mejorar las tasas de retención de clientes.',
      whyIsItImportant: 'Aumenta la lealtad del cliente y reduce costos de adquisición de nuevos clientes.',
      variables: 'Clientes actuales, Plan de Ventas, Seguimiento y Control, Productos Finales.',
      stepByStep: [
        'Identificar clientes actuales.',
        'Evaluar plan de ventas.',
        'Monitorear seguimiento y control.',
        'Ajustar productos finales.',
      ],
    }),
  },
  {
    icon: 'mdi-chart-bar',
    title: 'Segmentación del Mercado',
    text1: 'Segmentación del',
    text2: 'Mercado',
    example: _getInfoTemplate({
      objective: 'Dividir el mercado en segmentos específicos según características como tamaño, sector, y ubicación.',
      whyIsItImportant: 'Permite personalizar estrategias de marketing y ventas para cada segmento.',
      variables: 'Clientes potenciales, Línea de Negocios, Capacidades Conjuntas, Productos Finales.',
      stepByStep: [
        'Identificar clientes potenciales.',
        'Definir líneas de negocios.',
        'Analizar capacidades conjuntas.',
        'Agregar productos finales.',
      ],
    }),
  }
]
