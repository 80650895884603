<template lang="pug">
ConfirmDialog(
  ref="confirmDialog",
  title="Eliminar Plan",
  text="¿Está seguro que desea eliminar este plan? Esta acción no se puede deshacer, se eliminará toda la información relacionada con este plan."
  @close="close"
  @confirm="confirm"
)
</template>

<script>
import ConfirmDialog from '../../common/dialogs/ConfirmDialog.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    ConfirmDialog
  },
  data () {
    return {
      planId: null,
    }
  },
  methods: {
    ...mapActions('plan', ['removePlan']),
    open (planId) {
      this.planId = planId
      this.$refs.confirmDialog.open()
    },
    close (response) {
      this.$emit('close', response)
    },
    async confirm () {
      const response = await this.removePlan({ planId: this.planId })

      if (response.status === 200 || response.status === 204) {
        this.$emit('confirm', true)
        this.$emit('save', response)
      } else {
        this.$emit('confirm', false)
      }
    }
  }
}
</script>