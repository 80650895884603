<template lang="pug">

v-container(fluid)
  v-img.mx-auto.mb-4(v-if="imgPath" :src="imgPath" max-height="400" max-width="400" alt="Empty state")

  h2.secondary--text.text-center
    span {{ title }}

  p.text-center {{ description }}

  .flex-column.flex-md-row.d-flex.justify-center(style="gap: 10px;")
    v-btn(v-if="primaryActionText" color="secondary" @click="$emit('click-primary-action')")
      span {{ primaryActionText }}
    v-btn(v-if="secondaryActionText" color="secondary" @click="$emit('click-secondary-action')" outlined)
      span {{ secondaryActionText }}

</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    primaryActionText: {
      type: String,
      default: null
    },
    secondaryActionText: {
      type: String,
      default: null
    },
    imgPath: {
      type: String,
      default: null
    }
  }
}
</script>