import axios from '../../plugins/axios'
import permissions from './permissions.json' 

const setPermissions = ({ commit, state }) => {
  const role = state.membershipContext.role
  const myPermissions = permissions.filter(p => p.roles.includes(role))

  commit('SET_PERMISSIONS', myPermissions)
}

const fetchMyMemberships = ({ commit }) => {
  return axios.get('/client/my-business-membership/')
    .then(response => {
      commit('SET_MY_MEMBERSHIPS', response.data)
    })
    .catch(error => {
      console.log(error)
    })
}

export const fetchUser = ({ commit }) => {
  return axios.get('/auth/me/')
    .then(response => {
    commit('SET_USER', bussiness)
      return response
    })
    .catch(err => err.response)
}

export const setMembershipContext = ({ commit, state }, value) => {
  commit('SET_MEMBERSHIP_CONTEXT', value)
  setPermissions({ commit, state })
}

export const setup = async ({ commit, state }) => {
  if (!localStorage.getItem('accessToken')) {
    console.log('not auth')
    commit('RESET_STATE')
    return
  }
  commit('SET_LOADING', true)

  await fetchMyMemberships({ commit })
  await fetchUser({ commit })

  if (state.membershipContext) {
    setMembershipContext({ commit, state }, state.membershipContext) 
  } else {
    setMembershipContext({ commit, state }, state.myMemberships[0]) 
  }
  commit('SET_LOADING', false)
}
