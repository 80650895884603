<template lang="pug">
v-dialog(v-model="dialog" width="500")
  template(v-slot:activator="{ on, attrs }")
    v-btn(v-bind="attrs" v-on="on") Agregar Venta
  v-card 
    v-card-title Nueva Venta
    v-card-text
      v-form(ref="form" @submit.prevent="onSubmit")
        v-text-field(v-model="form.date" label="Fecha" type="date" outlined :rules="v.date")
        v-text-field(v-model="form.product_name" label="Producto" outlined :rules="v.product_name")
        v-currency-field(v-model="form.quantity" label="Cantidad" outlined type="number" :min="0")
        v-currency-field(v-model="form.total_price" label="Monto Total" type="number" outlined prefix="$" :rules="v.total_price")
        v-text-field(v-model="form.payment_method" label="Medio de pago" outlined :rules="v.payment_method")
        v-text-field(v-model="form.store_name" label="Tienda" outlined :rules="v.store_name")
        .text-right
          v-btn.mr-2(@click="$refs.form.reset(), dialog = false" color="primary" outlined :disabled="loading") Cancelar
          v-btn(type="submit" color="primary" :loading="loading") Agregar
</template>

<script>
import { mapActions } from 'vuex'
import formValidations from '../../../../../shared/formValidations'
import { formatDate } from '@/utils/dateUtils.js'
import { EventBus } from '../../../../../utils/eventBus'

export default {
  props: {
    planId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      dialog: false,
      form: {
        date: formatDate(new Date()),
        product_name: null,
        quantity: null,
        total_price: null,
        payment_method: null,
        store_name: null
      }
    }
  },
  methods: {
    ...mapActions('real_data', ['createRealSale']),
    async onSubmit () {
      if (!this.$refs.form.validate()) {
        return
      }
      this.loading = true
      const response = await this.createRealSale({ data: { ...this.form, plan: this.planId } })

      if (response.status == 201) {
        this.$emit('save', response)
        this.dialog = false
        EventBus.$emit('updateRealData', this.planId)
      }
      this.loading = false
      this.$refs.form.reset()
    }
  },
  computed: {
    v () {
      return {
        date: [formValidations.isRequired],
        product_name: [formValidations.isRequired],
        quantity: [formValidations.isRequired, formValidations.minOrEqual(0)],
        total_price: [formValidations.isRequired, formValidations.min(0)],
        payment_method: [],
        store_name: []
      }
    }
  }
}
</script>
