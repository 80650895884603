import * as actions from './actions'
import mutations from './mutations'
import * as getters from './getters'

const getUserFromLocalStorage = () => {
  const userString = localStorage.getItem('user')
  if (userString) {
    return JSON.parse(userString)
  }
  return null
}

const AuthStore = {
  namespaced: true,
  state: {
    user: getUserFromLocalStorage()
  },
  getters,
  mutations,
  actions
}

export default AuthStore
