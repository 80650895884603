<template lang="pug">
v-card
  v-card-title
    v-row(dense)
      v-col
        span {{title}}
      v-col(cols="auto")
        v-text-field(v-model="filters.date__gte" label="Desde" type="date" hide-details outlined dense clearable)
      v-col(cols="auto")
        v-text-field(v-model="filters.date__lte" label="Hasta" type="date" hide-details outlined dense clearable)
      v-col(cols="auto")
        v-btn(@click="fetchData") filtrar
      v-col(cols="auto")
        v-btn(@click="exportData") exportar
      v-col(cols="auto")
        BaseImportRealDataForm(:action="importAction" @save="fetchData" :planId="plan.id")
      v-col(cols="auto")
        v-btn(@click="$refs.realSaleMasiveForm.openDialog(plan.id)" color="primary")
          v-icon(left) mdi-plus-circle-outline
          | Agregar venta
        RealProductionForm(v-if="plan.plan_type === 'PRODUCCIÓN'" :planId="plan.id" @save="fetchData")
        RealCostForm(v-if="plan.plan_type === 'COSTOS'" :planId="plan.id" @save="fetchData" :costList="plan.cost_items.map(e => e.name)")
  v-simple-table(key="list-real-data")
    template(v-slot:default)
      thead(key="list-real-data-header")
        tr
          th(v-for="item in tableField") {{item.text}}
          th Acciones
      tbody(key="list-real-data-body")
        tr(v-for="estimatedSale in estimatedSales" :key="estimatedSale.id")
          td(v-for="item in tableField") 
            label(v-if="item.currency") {{estimatedSale[item.field] | currency}}
            label(v-else) {{estimatedSale[item.field]}}
          td
            v-btn(icon color="red" @click="deleteRealData(estimatedSale.id, plan.plan_type)")
              v-icon mdi-trash-can-outline

  .text-center.py-4
    v-progress-circular(v-if="loading" :size="50" color="primary" indeterminate :width="7")
    label(v-else-if="!estimatedSales.length") No se encontraron datos registrados
  .text-center.pb-4
    v-pagination(v-model="currentPage" :length="paginationLength")
  RemoveRealDataDialog(ref="removeRealDataDialog" @save="fetchData")
  RealSaleMasiveForm(ref="realSaleMasiveForm" :planId="plan.id" @save="fetchData")
</template>

<script>
import PaginationMixin from '../../../mixins/PaginationMixin.vue'
import RealSaleForm from './forms/RealSaleForm.vue'
import RealSaleMasiveForm from './forms/RealSaleMasiveForm.vue'
import RealProductionForm from './forms/RealProductionForm.vue'
import RealCostForm from './forms/RealCostForm.vue'
import fileDownload from 'js-file-download'
import BaseImportRealDataForm from './forms/BaseImportRealDataForm.vue'
import RemoveRealDataDialog from '../../dialogs/RemoveRealDataDialog.vue'

export default {
  mixins: [PaginationMixin],
  props: {
    plan: {
      type: Object,
      required: true
    },
    listAction: {
      type: Function,
      required: true
    },
    exportAction: {
      type: Function,
      required: true
    },
    importAction: {
      type: Function,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    tableField: {
      type: Array,
      required: true
    }
  },
  components: {
    RealSaleForm,
    RealSaleMasiveForm,
    RealProductionForm,
    RealCostForm,
    BaseImportRealDataForm,
    RemoveRealDataDialog
  },
  data () {
    return {
      loading: false,
      filters: {},
      estimatedSales: []
    }
  },
  methods: {
    deleteRealData (realDataId, planType) {
      console.log({realDataId, planType})
      this.$refs.removeRealDataDialog.open(realDataId, planType)
    },
    async fetchData () {
      this.loading = true
      this.estimatedSales = []
      const params = this.getFilters()

      if (this.plan) {
        params.plan_id = this.plan.id
      }
      const response = await this.listAction({ params })
      this.estimatedSales = response.data.results
      this.itemCount = response.data.count
      this.loading = false
    },
    async exportData () {
      const params = this.getFilters()

      if (this.plan) {
        params.plan_id = this.plan.id
      }
      const response = await this.exportAction({ params })
      fileDownload(response.data, 'resultados.xlsx');
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
