<template lang="pug">
v-dialog(v-model="dialog" width="500")
  v-card
    v-card-title {{ title }}
    v-card-text
      v-form(ref="form" @submit.prevent="onSubmit")
        v-autocomplete(
          label="Selecciona un producto"
          v-model="form.product"
          :search-input.sync="searchProduct"
          item-value="id"
          item-text="name"
          outlined
          no-filter
          return-object
          :items="productsOptions"
          :rules="v.product")
          template(v-slot:item="{ item }")
            v-list-item-content
              v-list-item-title {{ item.name }}
              v-list-item-subtitle SKU: {{ item.sku }}
        v-autocomplete(
          label="Selecciona un cliente"
          v-model="form.customer"
          :search-input.sync="searchCustomer"
          outlined
          no-filter
          item-text="name"
          return-object
          :items="customers"
          :rules="v.customer")
        v-text-field(label="Cantidad" v-model="form.quantity" outlined type="number" :rules="v.quantity")
        v-text-field(label="Precio" v-model="form.price" outlined type="number" :rules="v.price")

        .text-right
          v-btn.mr-2(color="primary" outlined @click="closeDialog" :disabled="loading") Cancelar
          v-btn(color="primary" type="submit" :loading="loading") Guardar
</template>

<script>
import { mapActions } from 'vuex'
import FormMixin from '@/components/mixins/FormMixin' 
import validations from '@/shared/formValidations'
import { debounce } from 'debounce'

export default {
  mixins: [FormMixin],
  data() {
    return {
      instanceId: null,
      form: {
        product: null,
        customer: null,
        quantity: null,
        price: null,
      },
      searchProduct: null,
      products: [],
      searchCustomer: null,
      customers: [],
      dialog: false,
      linesOfBusiness: [],
    }
  },
  methods: {
    ...mapActions('commercial_projection', ['createCommercialProjectionItem', 'editCommercialProjectionItem', 'getCommercialProjectionItem']),
    ...mapActions('product', ['listLineOfBusiness', 'listProducts']),
    ...mapActions('customer', ['listCustomers']),
    async fetchLinesOfBusiness () {
      const { data } = await this.listLineOfBusiness()
      this.linesOfBusiness = data
    },
    async openDialog(instanceId) {
      this.instanceId = instanceId
      await this.fetchForm()
      this.dialog = true
    },
    closeDialog() {
      this.dialog = false
    },
    onSuccess() {
      this.dialog = false
      this.$emit('save')
    },
    onError({ error }) {
      if (Array.isArray(error.response.data)) {
        this.errors = error.response.data
      } else if (error.response.data.non_field_errors) {
        this.errors = [...error.response.data.non_field_errors]
        this.errors = this.errors.map(error => {
          let _error = error.replace(' projection,', '')
          _error = _error.replace('customer', 'Cliente')
          _error = _error.replace('product', 'Producto')
          return _error
        })
      }

      const errorMessage = this.errors.join('\n')
      this.$toast.error(errorMessage)
    },
    getAction() {
      return this.instanceId ? this.editCommercialProjectionItem : this.createCommercialProjectionItem
    },
    async fetchForm() {
      if (this.instanceId) {
        const { data } = await this.getCommercialProjectionItem(this.instanceId)
        this.form = {
          product: data.product,
          customer: data.customer,
          quantity: data.quantity,
          price: data.price,
        }
        this.products = [data.product]
        this.customers = [data.customer]
      }
    },
    getData() {
      const data = { 
        data: {
          product: this.form.product.id,
          customer: this.form.customer.id,
          quantity: this.form.quantity,
          price: this.form.price,
        } 
      }
      if (this.instanceId) {
        data.id = this.instanceId
      }
      return data
    },
    resetForm() {
      this.$refs.form.resetValidation()
      this.form = {
        year: new Date().getFullYear(),
        line_of_business: null,
        name: null,
      }
    },
    onClose() {
      this.instanceId = null
      this.resetForm()
    },
    async handleSearchProduct() {
      const params = {
        search: this.searchProduct || '',
        page_size: 20
      }
      const { data } = await this.listProducts({params})
      this.products = [...data.results]
    },
    async handleSearchCustomer() {
      const params = {
        search: this.searchCustomer || '',
        page_size: 20
      }
      const { data } = await this.listCustomers({params})
      this.customers = [...data.results]
    }
  },
  computed: {
    title() {
      return this.instanceId ? 'Editar Item' : 'Crear Item'
    },
    v() {
      return {
        product: [validations.isRequired],
        customer: [validations.isRequired],
        quantity: [validations.isRequired, validations.min(0)],
        price: [validations.isRequired, validations.min(0)],
      }
    },
    yearsOptions() {
      const startYear = 2020
      const endYear = new Date().getFullYear() + 1
      const years = []
      for (let i = startYear; i <= endYear; i++) {
        years.push(i)
      }
      return years
    },
    productsOptions() {
      return this.products.map(product => ({
        ...product,
      }))
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.onClose()
      }
    },
    searchProduct() {
      this.handleSearchProduct()
    },
    searchCustomer() {
      this.handleSearchCustomer()
    }
  },
  created () {
    this.handleSearchProduct = debounce(this.handleSearchProduct, 300)
    this.handleSearchCustomer = debounce(this.handleSearchCustomer, 300)
    this.fetchLinesOfBusiness()
  }
}
</script>
