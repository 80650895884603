<template lang="pug">
EmptyState(
  v-if="!hasBaseProducts"
  title="Productos SKU"
  description="Primero debes crear un productos base para poder crear productos SKU."
  imgPath="/img/empty_state_1.png"
)
div(v-else)
  v-card-title
    span Productos SKU
    ShowInfoBtn(@click="$refs.infoAlert.showAlert()")
    v-spacer
    v-btn.mr-2(:disabled="!selectedItems.length" color="primary" @click="deleteSelectedItems" outlined)
      v-icon.mr-2 mdi-delete
      span Eliminar Selección
    v-btn.mr-2(
      color="primary"
      :id="WIZARD_ELEMENTS_ID.BTN_CREATE_PRODUCT"
      @click="globalEmitter(WIZARD_STEPS_EVENTS.ON_CLICK_CREATE_PRODUCT); $router.push('/productos/nuevo')"
    )
      v-icon.mr-2 mdi-plus-circle-outline
      span Añadir Producto
    v-btn(color="primary" @click="$refs.productImportFormModal.openDialog()")
      v-icon.mr-2 mdi-import
      span Importar
  v-card-text
    InfoAlert(:message="alertMessage" ref="infoAlert" :id="WIZARD_ELEMENTS_ID.HELP_PRODUCTS" :showInfo="$route.query.help_products")
  template(v-if="hasProducts")
    v-card-text
      v-form(@submit.prevent="handleFilter")
        v-row
          v-col(cols="12" md="4")
            v-text-field(
              label="Nombre del producto"
              v-model="filter.search"
              outlined
              hide-details
              clearable
              )
          v-col(cols="12" md="4")
            v-select(
              label="Línea de negocio"
              v-model="filter.base_product__line_of_business"
              outlined
              hide-details
              item-text="name"
              item-value="id"
              clearable
              :items="linesOfBusiness")
          v-col(cols="12" md="auto")
            v-btn(color="primary" block height="100%" type="submit") Filtrar
    v-simple-table(key="customer-classification")
      template(v-slot:default)
        thead(key="customer-classification-header")
          tr
            th
              v-checkbox(
                @change="toggleSelectAll"
                v-model="selectAll"
              )
            th SKU
            th Nombre
            th Producto Base
            th Precio de Mercado
            th Atributo Uno
            th Atributo Dos
            th Atributo Tres
            th Atributo Cuatro
            th(style="width: 80px") Acciones
        tbody(key="customer-classification-body")
          tr(v-for="item in items" :key="item.id")
            td
              v-checkbox(
                v-model="item._selected"
              )
            td {{item.sku}}
            td {{item.name}}
            td {{item.base_product_name}}
            td {{item.market_price | currency }}
            td(v-if="item.attribute_options.length > 0") {{item.attribute_options[0].name}}
            td(v-else) -
            td(v-if="item.attribute_options.length > 1") {{item.attribute_options[1].name}}
            td(v-else) -
            td(v-if="item.attribute_options.length > 2") {{item.attribute_options[2].name}}
            td(v-else) -
            td(v-if="item.attribute_options.length > 3") {{item.attribute_options[3].name}}
            td(v-else) -
            td
              v-menu(offset-y left)
                template(v-slot:activator="{ on, attrs }")
                  v-btn(icon v-bind="attrs" v-on="on")
                    v-icon mdi-dots-vertical
                v-list
                  v-list-item(:to="`/productos/editar/${item.id}`")
                    v-list-item-title Editar
                  v-list-item(:to="`/productos/nuevo?copy=${item.id}`")
                    v-list-item-title Duplicar
                  v-list-item(@click="deleteItem(item.id)")
                    v-list-item-title Eliminar
    v-pagination(v-model="pagination.page" :length="pagination.pages" @input="fetchItems")

  EmptyState(
    v-else
    title="Productos SKU"
    description="Aun no tienes productos SKU registrados, crea tu primer producto SKU."
    primaryActionText="Crear Producto SKU"
    imgPath="/img/empty_state_1.png"
    @click-primary-action="$router.push('/productos/nuevo')"
  )

  CustomerClassificationFormModal(ref="customerClassificationFormModal" @save="fetchItems")
  CustomConfirmDialog(ref="confirmDialog")
  ProductImportFormModal(ref="productImportFormModal" @save="fetchItems")
</template>

<script>
import { mapActions } from 'vuex'
import CustomerClassificationFormModal from '@/components/customers/CustomerClassificationFormModal.vue'
import CustomConfirmDialog from '@/components/common/dialogs/CustomConfirmDialog.vue'
import ProductImportFormModal from '@/components/products/ProductImportFormModal.vue'
import ShowInfoBtn from '../common/ShowInfoBtn.vue'
import InfoAlert from '../common/InfoAlert.vue'
import EmptyState from '../common/EmptyState.vue'
import TableMultipleSelectionMixin from '@/components/mixins/TableMultipleSelectionMixin.vue'
import { CASE_MODIFICATION_WARNING, WIZARD_ELEMENTS_ID, WIZARD_STEPS_EVENTS } from '@/constants'
import { globalEmitter } from '@/utils/eventBus'

export default {
  mixins: [TableMultipleSelectionMixin],
  components: {
    CustomerClassificationFormModal,
    ProductImportFormModal,
    CustomConfirmDialog,
    ShowInfoBtn,
    InfoAlert,
    EmptyState
  },
  data () {
    return {
      WIZARD_STEPS_EVENTS,
      WIZARD_ELEMENTS_ID,
      pagination: {
        page: 1,
        pages: 1,
        pageSize: 20
      },
      filter: {
        search: '',
        base_product__line_of_business: null
      },
      items: [],
      linesOfBusiness: [],
      alertMessage: "Este es tu centro de gestión de la base de datos de productos. Aquí puedes ingresar tus productos uno por uno manualmente o importar un listado completo a través de una planilla. Si optas por la importación, asegúrate de que los campos 'Producto Base' y 'Atributos' estén correctamente etiquetados y escritos para garantizar que el sistema reconozca e integre correctamente los datos al modelo."
    }
  },
  methods: {
    globalEmitter,
    ...mapActions('product', ['listProducts', 'deleteProduct', 'listLineOfBusiness', 'bulkDeleteProducts']),
    async fetchItems () {
      const params = {
        ...this.filter,
        page: this.pagination.page,
        page_size: this.pagination.pageSize
      }
      const { data } = await this.listProducts({ params })
      this.items = data.results || []
      this.pagination.pages = Math.ceil(data.count / this.pagination.pageSize)
    },
    async handleFilter () {
      this.pagination.page = 1
      await this.fetchItems()
    },
    async deleteItem (id) {
      const confirm = await this.$refs.confirmDialog.open(
        'Eliminar producto',
        '¿Está seguro que desea eliminar este producto?. ' + CASE_MODIFICATION_WARNING,
        {
          okTitle: 'Eliminar',
        }
      )
      if (confirm) {
        await this.deleteProduct(id)
        await this.fetchItems()
      }
    },
    async fetchLinesOfBusiness () {
      const { data } = await this.listLineOfBusiness()
      this.linesOfBusiness = data
    },
    async deleteSelectedItems() {
      const ok = await this.$refs.confirmDialog.open(
        'Eliminar productos',
        '¿Está seguro que desea eliminar los productos seleccionados?. ' + CASE_MODIFICATION_WARNING,
        {
          okTitle: 'Eliminar',
          icon: 'mdi-delete'
        }
      )

      if (!ok) return

      const { error } = await this.bulkDeleteProducts(this.selectedItems.map(item => item.id))
      if (error) {
        this.$toast.error('No se pudieron eliminar los productos seleccionados')
        return
      }

      this.$toast.success('Productos eliminados correctamente')
      this.fetchItems()
    }
  },
  computed: {
    hasProducts() {
      return this.$store.state.product.hasProducts
    },
    hasBaseProducts() {
      return this.$store.state.product.hasBaseProducts
    },
  },
  async created () {
    this.fetchLinesOfBusiness()
    await this.fetchItems()
    if (this.items.length === 0 && this.$refs.infoAlert) {
      this.$refs.infoAlert.showAlert()
    }
  }
}
</script>