import Vue from 'vue'
import Vuex from 'vuex'
// Modulos
import reports from './reports'
import auth from './auth'
import plan from './plan'
import real_data from './real_data'
import estimated_data from './estimated_data'
import app_config from './app_config'
import business from './business'
import invitation from './invitation'
import app from './app'
import product from './product'
import customer from './customer'
import commercial_projection from './commercial_projection'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    reports,
    auth,
    plan,
    real_data,
    estimated_data,
    app_config,
    business,
    invitation,
    app,
    product,
    customer,
    commercial_projection
  }
})
