<template lang="pug">
div
  div
    v-card-title
      span Plan de Ventas (SOM)
      ShowInfoBtn(@click="$refs.infoAlert.showAlert()")
      v-spacer
      v-btn.mr-2(color="primary" @click="$refs.salePlanItemByProductFormModal.openDialog()")
        v-icon(left) mdi-plus-circle-outline
        span MODIFICAR PLAN
      v-menu(offset-y)
        template(v-slot:activator="{ on, attrs }")
          v-btn(v-bind="attrs" v-on="on" color="primary")
            span acciones
            v-icon(right) mdi-menu-down
        v-list
          v-list-item(@click="$refs.importSalePlanFormModal.openDialog()")
            v-list-item-title.d-flex.primary--text.font-weight-medium.justify-space-between
              div
                v-icon(color="primary") mdi-file-upload
              div Cargar Masivo
          v-list-item(@click="handleExportSalePlan()" v-if="isStarted")
            v-list-item-title.d-flex.primary--text.font-weight-medium.justify-space-between
              div
                  v-icon(color="primary") mdi-download-circle
              div Exportar
          v-list-item(@click="$refs.salesPlanMonitoringForm.openDialog(projection || $route.params.id)" v-if="isStarted")
            v-list-item-title.d-flex.primary--text.font-weight-medium.justify-space-between Generar plan de venta para seguimiento
    v-card-text
        InfoAlert(:message="alertMessage" ref="infoAlert")
    StartSalePlanPanel(v-if="!isStarted" @start="$refs.importSalePlanFormModal.openDialog()")
    div(v-else)
      ChartSalePlan(:filters="filters" ref="chartSalePlan")
      TableSalePlan(:filters="filters" ref="tableSalePlan" @editItem="$refs.salePlanItemByProductFormModal.openDialog($event)")
  ImportSalePlanFormModal(ref="importSalePlanFormModal" @save="checkIfSalePlanStarted")
  SalePlanItemByProductFormModal(ref="salePlanItemByProductFormModal" @save="checkIfSalePlanStarted")
  SalesPlanMonitoringForm(ref="salesPlanMonitoringForm")
</template>

<script>
import StartSalePlanPanel from '@/components/commercial_projection/StartSalePlanPanel.vue'
import ImportSalePlanFormModal from '@/components/commercial_projection/ImportSalePlanFormModal.vue'
import ChartSalePlan from '@/components/commercial_projection/charts/ChartSalePlan.vue'
import TableSalePlan from '@/components/commercial_projection/charts/TableSalePlan.vue'
import SalePlanItemByProductFormModal from '@/components/commercial_projection/SalePlanItemByProductFormModal.vue'
import { mapActions } from 'vuex'
import InfoAlert from '../common/InfoAlert.vue'
import ShowInfoBtn from '../common/ShowInfoBtn.vue'
import SalesPlanMonitoringForm from './SalesPlanMonitoringForm.vue'

export default {
  props: {
    filters: {
      type: Object,
      required: true,
      default: () => ({})
    },
    projection: {
      type: Number,
      default: null,
    },
  },
  components: {
    StartSalePlanPanel,
    ImportSalePlanFormModal,
    ChartSalePlan,
    TableSalePlan,
    SalePlanItemByProductFormModal,
    InfoAlert,
    ShowInfoBtn,
    SalesPlanMonitoringForm
  },
  data () {
    return {
      isStarted: false,
      loading: true,
      alertMessage: 'El plan de ventas constituye el paso final de este módulo. A partir de la información recopilada, tienes la <b>flexibilidad de importar y personalizar el mix óptimo, o de crear manualmente un plan de ventas.</b> El resultado de tu plan de ventas será tu SOM (Mercado Obtenible Servible), que representa la porción de tu mercado servible que realísticamente puedes capturar en el corto a mediano plazo, considerando las limitaciones de tus capacidades actuales. Este proceso te permite <b>exportar</b> el plan resultante al <b>módulo de Seguimiento.</b> Allí, podrás medir y controlar tus metas de venta y costos, asegurando una <b>gestión eficiente</b> y orientada a <b>resultados óptimos.</b>',
    }
  },
  methods: {
    ...mapActions('commercial_projection', ['listSalePlanItems', 'exportSalePlan']),
    async handleExportSalePlan() {
      const params = {
        projection: this.projection || this.$route.params.id,
        ...this.filters
      }
      await this.exportSalePlan(params)
    },
    async checkIfSalePlanStarted() {
      this.loading = true
      const params = {
        projection: this.projection || this.$route.params.id
      }
      const { data } = await this.listSalePlanItems(params)

      if (data) {
        this.isStarted = data.count > 0
      }
      this.loading = false
    },
    updateData() {
      this.$refs.chartSalePlan.fetchData()
      this.$refs.tableSalePlan.fetchData()
    }
  },
  created() {
    this.checkIfSalePlanStarted()
  }
}
</script>
