const colors = [
  '#FF6384',
  '#4BBCC0',
  '#FFD156',
  '#C9CBCF',
  '#369EEB',
  '#FF7F00',
  '#00BCD4',
  '#FFD54F',
  '#D5D7DA',
  '#4CAF50',
  '#F06292',
  '#3F51B5',
  '#F9A825',
  '#9E9E9E',
  '#8BC34A',
  '#E91E63',
  '#9C27B0',
  '#FFEB3B',
  '#607D8B',
  '#03A9F4',
  '#FFAFAF',
  '#A2DADA',
  '#FFE0AF',
  '#B4E1E8',
  '#AAD7EE',
  '#FFBFA0',
  '#AEEEF4',
  '#FFEFB3',
  '#C8EAC4',
  '#C8DFC7',
  '#FBCDE1',
  '#BBC8E6',
  '#FCD9B1',
  '#FFD8B8',
  '#BDE5A1',
  '#FBA9D6',
  '#D4B0D8',
  '#FFF4CC',
  '#BFD3DE',
  '#B9E8FF',
]
export default {
  colors
}