import axios from '../../plugins/axios'

export const listPlan = ({ rootGetters }, { params } = {} ) => {
  const _params ={
    ...params,
    business_id: rootGetters['app/businessId']
  }
  return axios.get('/plan/', { params: _params })
    .catch(err => err.response)
}

export const getPlan = (_, { planId, params } = {} ) => {
  return axios.get(`/plan/${planId}/`, { params })
    .catch(err => err.response)
}

export const createSalePlan = ({ rootState }, { data } = {} ) => {
  data.business = rootState.app_config.currentBusiness.id
  return axios.post('/plan/sale-plan/', data)
    .catch(err => err.response)
}

export const createProductionPlan = ({ rootState }, { data } = {} ) => {
  data.business = rootState.app_config.currentBusiness.id
  return axios.post('/plan/production-plan/', data)
    .catch(err => err.response)
}

export const createCostPlan = ({ rootState }, { data } = {} ) => {
  data.business = rootState.app_config.currentBusiness.id
  return axios.post('/plan/cost-plan/', data)
    .catch(err => err.response)
}

export const removePlan = (_, { planId } = {} ) => {
  return axios.delete(`/plan/${planId}/`)
    .catch(err => err.response)
}

export const updatePlan = (_, { planId, data } = {} ) => {
  return axios.put(`/plan/${planId}/`, data)
    .catch(err => err.response)
}