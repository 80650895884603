<template lang="pug">
v-container#login(fluid)
  v-card(width="500px")
    v-card-text.pa-md-14.pa-7
      v-img.mx-auto(src="../assets/img/brand/logotipo.png" height="80" contain)
      SignupForm.mb-4
      .text-center
        span.font-weight-medium ¿Ya tienes una cuenta?
      .text-center
        router-link(to="/login").text-decoration-none.font-weight-medium Inicia sesión
</template>

<script>
import SignupForm from '../components/auth/SignupForm.vue'

export default {
  components: {
    SignupForm
  }
}
</script>

<style scoped lang="scss">
#login {
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 600px) {
  #login {
    padding: 0 !important;
    background-color: white;
  }
  .v-card { 
    border-radius: 0 !important;
  }
}
</style>
