<template lang="pug">
v-form(ref="form" @submit.prevent="onSubmit" style="height: 100%;")
  v-container.d-block(fluid style="height: 100%;")
    v-card(style="min-height: 100%")
      v-card-title Añadir SKU
      v-card-text
          ProductAutocomplete(
            v-model="form.product"
            :rules="v.product"
          )
      v-card-text
        .mb-4
          b Clientes
        v-row(v-for="(item, index) in form.items" :key="index")
          v-col(cols="12" md="3")
            CustomerAutocomplete(
              v-model="item.customer"
              :rules="v.customer"
            )
          v-col(cols="12" md="3")
            NumberMaskedInput(label="Cantidad" v-model="item.quantity" outlined type="number" :rules="v.quantity")
          v-col(cols="12" md="3")
            NumberMaskedInput(label="Precio (Opcional)" v-model="item.price" outlined type="number")
          v-col(cols="12" md="auto")
            v-btn.mt-md-2(text block color="red" @click="removeItem(index)")
              v-icon mdi-delete
              | Eliminar
        div
          v-btn(text color="primary" @click="addItem")
            v-icon mdi-plus
            |  Agregar otro Cliente

        .text-right
          v-btn.mr-2(color="primary" outlined @click="handleCancel" :disabled="loading") Cancelar
          v-btn(color="primary" type="submit" :loading="loading" @click="") Guardar
  v-dialog(v-model="succesDialog" width="500")
    v-card
      v-card-title Agregado Correctamente
      v-card-text.text-center
        v-icon(color="success" size="80") mdi-check-circle-outline
      v-card-text.text-cente
        v-form(ref="form" @submit.prevent="onSubmit")
          p Se han añadido correctamente los SKU a la proyección comercial, puedes continuar añadiendo más o volver a la lista de SKU
          .text-right
            v-btn.mr-2(color="primary" outlined @click="handleCancel") Volver
            v-btn(color="primary" @click="succesDialog = false") Continuar
</template>

<script>
import { mapActions } from 'vuex'
import FormMixin from '@/components/mixins/FormMixin'
import validations from '@/shared/formValidations'
import CustomerAutocomplete from '../components/common/CustomerAutocomplete.vue'
import ProductAutocomplete from '../components/common/ProductAutocomplete.vue'
import NumberMaskedInput from '@/components/common/NumberMaskedInput.vue'
import { WIZARD_STEPS_EVENTS } from '@/constants'
import { globalEmitter } from '@/utils/eventBus'

export default {
  mixins: [FormMixin],
  components: {
    CustomerAutocomplete,
    ProductAutocomplete,
    NumberMaskedInput,
  },
  data() {
    return {
      comercialProjectionsId: this.$route.params.id,
      form: {
        product: null,
        items: [
          {
            customer: null,
            quantity: null,
            price: null,
          }
        ],
      },
      succesDialog: false,
      searchProduct: null,
      products: [],
      searchCustomer: {},
      customers: {},
      dialog: false,
      linesOfBusiness: [],
    }
  },
  methods: {
    ...mapActions('commercial_projection', ['setCommercialProjectionByProduct', 'editCommercialProjectionItem', 'getCommercialProjectionItem', 'listCommercialProjectionItems']),
    ...mapActions('product', ['listLineOfBusiness', 'getProduct']),
    ...mapActions('customer', ['listCustomers']),
    async fetchLinesOfBusiness () {
      const { data } = await this.listLineOfBusiness()
      this.linesOfBusiness = data
    },
    addItem() {
      this.form.items.push({
        customer: null,
        quantity: null,
        price: null,
      })
    },
    removeItem(index) {
      this.form.items.splice(index, 1)
    },
    handleCancel() {
      this.$router.push(`/proyeccion-comercial/${this.comercialProjectionsId}/items`)
    },
    onSuccess() {
      this.succesDialog = true
      globalEmitter(WIZARD_STEPS_EVENTS.COMMERCIAL_PROJECTION_ITEM_CREATED)
    },
    onError({ error }) {
      if (Array.isArray(error.response.data)) {
        this.errors = error.response.data
      } else if (error.response.data.non_field_errors) {
        this.errors = [...error.response.data.non_field_errors]
        this.errors = this.errors.map(error => {
          let _error = error.replace(' projection,', '')
          _error = _error.replace('customer', 'Cliente')
          _error = _error.replace('product', 'Producto')
          return _error
        })
      }

      const errorMessage = this.errors.join('\n')
      this.$toast.error(errorMessage)
    },
    getAction() {
      return this.setCommercialProjectionByProduct
    },
    filterEmptyItems() {
      return this.form.items.filter(item => item.customer && item.quantity)
    },
    getData() {
      const data = {
        data: {
          product: this.form.product.id,
          projection: this.comercialProjectionsId,
          items: this.filterEmptyItems().map(item => {
            const result = {
              customer: item.customer.id,
              quantity: item.quantity,
            }
            if (item.price) {
              result.price = item.price
            }
            return result
          })
        }
      }
      if (this.instanceId) {
        data.id = this.instanceId
      }
      return data
    },
    resetForm() {
      this.$refs.form.resetValidation()
      this.form = {
        year: new Date().getFullYear(),
        line_of_business: null,
        name: null,
      }
    },
    onClose() {
      this.instanceId = null
      this.resetForm()
    },
    async handleSearchProduct() {
      const params = {
        search: this.searchProduct || '',
        page_size: 20
      }
      const { data } = await this.listProducts({params})
      this.products = [...data.results]
    },
    async handleSearchCustomer() {
      const params = {
        search: this.searchCustomer || '',
        page_size: 20
      }
      const { data } = await this.listCustomers({params})
      this.customers = [...data.results]
    },
    async fetchForm() {
      const productId = this.$route.query.productId
      console.log(productId)
      if (productId) {
        const { data } = await this.getProduct(productId)

        this.form = {
          product: data,
          items: []
        }
        this.fetchRelatedItems(productId, this.comercialProjectionsId)
        console.log(this.form)

        return
      }
    },
    async fetchRelatedItems(productId, projectionId) {
      const params = {
        product: productId,
        projection: projectionId,
        page_size: 1000,
      }
      const { data } = await this.listCommercialProjectionItems({ params })

      this.form.items = data.results.map(item => ({
        customer: item.customer,
        quantity: item.quantity,
        price: item.price,
      }))
    },
    checkDuplicateItems(v) {
      if (!v) return
      const filteredItems = this.form.items.filter(item => item.customer && item.customer.id === v.id)
      return filteredItems.length === 1 ? true : 'El cliente ya ha sido agregado'
    },
  },
  computed: {
    v() {
      return {
        product: [validations.isRequired],
        customer: [validations.isRequired, this.checkDuplicateItems],
        quantity: [validations.isRequired, validations.min(0)],
        price: [validations.isRequired, validations.min(0)],
      }
    },
    yearsOptions() {
      const startYear = 2020
      const endYear = new Date().getFullYear() + 1
      const years = []
      for (let i = startYear; i <= endYear; i++) {
        years.push(i)
      }
      return years
    },
    productsOptions() {
      return this.products.map(product => ({
        ...product,
      }))
    },
  },
  watch: {
    'form.product': {
      async handler() {
        if (this.form.product) {
          await this.fetchRelatedItems(this.form.product.id, this.comercialProjectionsId)
          if (this.form.items.length === 0) {
            this.addItem()
          }
        } else {
          this.form.items = []
          this.addItem()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created () {
    this.fetchForm()
  }
}
</script>
