<template lang="pug">
v-dialog(v-model="dialog" width="500")
  template(v-slot:activator="{ on, attrs }")
    v-btn(icon v-bind="attrs" v-on="on")
      v-icon mdi-pencil-outline
  v-card
    v-card-title Cambiar información del negocio
    v-card-text
      v-form(ref="form" @submit.prevent="onSubmit")
        v-text-field(
          label="Nombre del negocio"
          v-model="form.name"
          outlined
          :rules="v.name")
        v-select(
          v-model="form.line_of_business"
          :items="lineOfBusinessChoices"
          label="Giro"
          outlined
          :rules="v.line_of_business")
        .text-right
          v-btn(color="primary" outlined @click="cancel" :disabled="loading").mr-2 Cancelar
          v-btn(color="primary" type="submit" :loading="loading") Guardar
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import FormMixin from '@/components/mixins/FormMixin'
import validations from '@/shared/formValidations'

export default {
  mixins: [FormMixin],
  data () {
    return {
      form: {
        name: null,
        line_of_business: null,
      },
      dialog: false,
      lineOfBusinessChoices: [],
    }
  },
  methods: {
    ...mapActions('business', ['businessChangeName', 'getLineOfBusinessChoices']),
    ...mapActions('app_config', ['setCurrentBusiness']),
    openDialog () {
      this.dialog = true
    },
    closeDialog () {
      this.dialog = false
    },
    cancel () {
      this.$refs.form.reset()
      this.dialog = false
    },
    onSuccess () {
      this.dialog = false
      this.$refs.form.reset()
      this.setCurrentBusiness()
    },
    getAction () {
      return this.businessChangeName
    },
    fetchForm () {
      this.form = {
        name: this.currentBusiness.name,
        line_of_business: this.currentBusiness.line_of_business
      }
    },
    getData () {
      return { businessId: this.currentBusiness.id, data: this.form}
    }
  },
  computed: {
    ...mapGetters('app_config', ['currentBusiness']),
    v () {
      return {
        name: [validations.isRequired]
      }
    }
  },
  created () {
    this.getLineOfBusinessChoices().then(({ data }) => {
      if (data) {
        this.lineOfBusinessChoices = data
      }
    })
  },
  watch: {
    dialog () {
      if (this.dialog) {
        this.fetchForm()
      }
    }
  }
}
</script>