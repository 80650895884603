<template lang="pug">
  v-container(fluid)
    .d-flex.flex-column(style="gap: 16px;")
    ListCommercialProjectionItems
  </template>
  
  <script>
  import ListCommercialProjectionItems from '@/components/commercial_projection/ListCommercialProjectionItems.vue'
  export default {
    components: {
      ListCommercialProjectionItems,
    },
  }
  </script>