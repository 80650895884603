import * as actions from './actions'
import mutations from './mutations'
import * as getters from './getters'

const getCurrentBusinessFromLocalStorage = () => {
  const businessString = localStorage.getItem('current_business')
  if (businessString) {
    return JSON.parse(businessString)
  }
  return null
}

const AppConfig = {
  namespaced: true,
  state: {
    currentBusiness: getCurrentBusinessFromLocalStorage()
  },
  getters,
  mutations,
  actions
}

export default AppConfig
