<template lang="pug">
v-dialog(v-model="dialog" width="600")
  v-card(v-if="!submited")
    v-card-title Importar Plan de Ventas
    v-card-text
      v-form(ref="form" @submit.prevent="onSubmit")
        div.mb-2 Selecciona el archivo Excel para cargar tu plan. Descarga la 
          a(href="/plantilla importar plan ventas.xlsx" target="_blank" download)  plantilla aquí
          | .
        v-file-input(
          label="Archivo"
          v-model="form.file"
          persistent-hint
          outlined
          accept="csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          :rules="v.file")
        .text-right
          v-btn.mr-2(color="primary" outlined @click="closeDialog" :disabled="loading") Cancelar
          v-btn(color="primary" type="submit" :loading="loading") Guardar
  v-card(v-else-if="errors.length > 0")
    v-card-title Falló la Carga
    v-card-text
      .d-flex.justify-center.align-center
        v-icon.mx-auto(color="error" size="80") mdi-alert-circle-outline
      v-card-text.text-center
        div Elementos cargados: {{ itemsUploaded }} / {{ itemsCount }}
        div Lamentablemente encontramos errores al cargar los elementos, por favor revisa los errores y vuelve a intentar.
      ul
        li(v-for="error in errors" :key="error") {{ error }}
      .text-right.mt-4
        v-btn.mr-2(color="primary" outlined @click="closeDialog" :disabled="loading") Cerrar
  v-card(v-else)
    v-card-title Archivo Subido Correctamente
    v-card-text
      .d-flex.justify-center.align-center
        v-icon.mx-auto(color="success" size="80") mdi-check-circle-outline
    v-card-text
      .text-center
        div El archivo se ha cargado correctamente, se han importado {{ itemsCount }} Líneas
      .text-right.mt-4
        v-btn.mr-2(color="primary" outlined @click="closeDialog" :disabled="loading") Cerrar
</template>

<script>
import { mapActions } from 'vuex'
import FormMixin from '@/components/mixins/FormMixin' 
import validations from '@/shared/formValidations'

export default {
  mixins: [FormMixin],
  data() {
    return {
      instanceId: null,
      submited: false,
      itemsCount: 0,
      itemsUploaded: 0,
      isUploading: false,
      form: {
        file: null,
        projection_id: this.$route.params.id
      },
      dialog: false,
      errors: []
    }
  },
  methods: {
    ...mapActions('commercial_projection', ['importSalePlan']),
    async openDialog() {
      this.dialog = true
    },
    closeDialog() {
      this.dialog = false
    },
    onError({ error }) {
      const data = error.response.data
      self.errors = []
      self.itemsUploaded = 0
      self.itemsCount = 0

      if (this.isUploading) {
        this.submited = true
        this.errors = data.errors
        this.itemsUploaded = data.created_items,
        this.itemsCount = data.total_items
      } else {
        this.dialog = false
      }
      this.$emit('save')
    },
    onSuccess({ data }) {
      self.errors = []
      self.itemsUploaded = 0
      self.itemsCount = 0

      if (this.isUploading) {
        this.submited = true
        this.errors = data.errors
        this.itemsUploaded = data.created_items,
        this.itemsCount = data.total_items
      } else {
        this.dialog = false
      }
      
      this.$emit('save')
    },
    getAction() {
      return this.importSalePlan
    },
    getData() {
      const formData = new FormData()
      const data = { data: formData }
      this.isUploading = !!this.form.file
      for (const key in this.form) {
        if (Array.isArray(this.form[key])) {
          for (const value of this.form[key]) {
            formData.append(key, value)
          }
          continue
        }
        formData.append(key, this.form[key])
      }
      return data
    },
    resetForm() {
      this.form = {
        file: null,
        projection_id: this.$route.params.id
      }
      this.$refs.form?.resetValidation()
    },
    onClose() {
      this.errors = []
      this.submited = false
      this.loading = false
      this.resetForm()
    }
  },
  computed: {
    v() {
      return {
        file: [validations.isRequired]
      }
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.onClose()
      }
    }
  },
}
</script>
  