const mutations = {
  SET_MY_MEMBERSHIPS (state, value) {
    state.myMemberships = value
  },
  SET_USER (state, value) {
    state.user = value
  },
  SET_MEMBERSHIP_CONTEXT (state, value) {
    state.membershipContext = value
    if (value) {
      localStorage.setItem('membership_context', JSON.stringify(value))
    } else {
      localStorage.removeItem('membership_context')
    }
  },
  SET_PERMISSIONS (state, value) {
    state.permissions = value
  },
  SET_LOADING (state, value) {
    state.loading = value
  },
  SET_WIZARD_IS_ACTIVE (state, value) {
    state.wizardIsActive = value
  }
}

export default mutations
