const formValidations = {
  isRequired: (v) => v === 0 ? true : !!v || 'Este campo es obligatorio',
  min (min) { return (v) => v == null || v == '' || parseInt(v) > min || `El valor debe ser mayor a ${min}`},
  minOrEqual (min) { return (v) => v == null || v == '' || parseInt(v) >= min || `El valor debe ser mayor o igual ${min}`},
  max (max) { return (v) => v == null || v == '' || parseInt(v) <= max || `El valor debe ser menor o igual a ${max}`},
  isEmail: (v) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/.test(v) || 'El email no es valido',
  minLength (min) { return (v) =>  v && v.length >= min || `El valor debe tener al menos ${min} caracteres`},
  equals (value, errorText) { return (v) => v === value || errorText ||'El valor no es igual'},
  password: (v) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d$@$!%*?&]{8,}/.test(v) || 'La contraseña debe tener al menos una mayúscula, una minúscula y un numero',
}

export default formValidations