import * as actions from './actions'
import mutations from './mutations'
import * as getters from './getters'

const getMembershipContextFromLocalStorage = () => {
  const businessString = localStorage.getItem('membership_context')
  if (businessString) {
    return JSON.parse(businessString)
  }
  return null
}

const getDefaultState = () => {
  return {
    loading: false,
    myMemberships: [],
    user: null,
    membershipContext: getMembershipContextFromLocalStorage(),
    permissions: [],
    wizardIsActive: false,
  }
}

const AppConfig = {
  namespaced: true,
  state: getDefaultState(),
  getters,
  mutations:{
    ...mutations,
    RESET_STATE: state => {
      Object.assign(state, getDefaultState())
    }
  },
  actions
}

export default AppConfig
