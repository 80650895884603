<template lang="pug">
v-container(fluid)
  .d-flex.flex-column(style="gap: 16px;")
    v-card
      v-card-title
        span.mr-2 {{currentBusiness.name}}
        BusinessFormModal
      v-card-text
        div.mb-2
          strong.mr-2 Nombre de la empresa:
          span {{currentBusiness.name}}
        div.mb-2
          strong.mr-2 Dueño:
          span {{currentBusiness.owner_full_name}}
        div.mb-2
          strong.mr-2 Giro:
          span {{currentBusiness.line_of_business_display}}
  
    v-card
      v-card-title
        span Lista de Usuarios
        v-spacer 
        BusinessInvitationFormModal
      v-simple-table(key="list-users")
        template(v-slot:default)
          thead(key="list-users-header")
            tr
              th Nombre
              th Correo
              th Rol
              th Activo
              th Acciones
          tbody(key="list-users-body")
            tr(v-for="member in members" :key="member.id")
              td {{member.member.full_name}} 
              td {{member.member.email}} 
              td {{member.role | userRole}}
              td
                v-icon(v-if="member.is_active" color="green") mdi-checkbox-marked
                v-icon(v-else="member.is_active" color="red") mdi-close-box
              td
                v-menu(offset-y left)
                  template(v-slot:activator="{ on, attrs }")
                    v-btn(icon v-bind="attrs" v-on="on")
                      v-icon mdi-dots-vertical
                  v-list
                    v-list-item
                      v-list-item-title Activar / Desactivar
                    v-list-item
                      v-list-item-title Eliminar
</template>

<script>
import BusinessFormModal from '@/components/business_config/BusinessFormModal.vue'
import BusinessInvitationFormModal from '@/components/business_config/BusinessInvitationFormModal.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    BusinessFormModal,
    BusinessInvitationFormModal,
  },
  data () {
    return {
      members: [

      ]
    }
  },
  computed: {
    ...mapGetters('app_config', ['currentBusiness'])
  },
  methods: {
    ...mapActions('business', ['listBusinessMembers'])
  },
  async created () {
    const response = await this.listBusinessMembers()
    this.members = response.data.results
  }
}
</script>