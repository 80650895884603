<template lang="pug">
v-card
  v-card-title
    .d-flex.justify-space-between.align-center.w-100
      span Tabla de Margen Bruto anual por producto y cantidades vendidas por cliente
      v-select.month-select(v-model="monthFilter" :items="monthOptions" label="Filtrar por mes" outlined clearable hide-details)
  v-card-text
    v-simple-table(v-if="tableData")
      template(v-slot:default)
        thead
          tr
            th Producto
            th Unidad de medida
            th Utilidad Total
            th(v-for="names in tableData.customers_name") {{names}}
        tbody
          tr(v-for="item in tableData.data" :key="item.id")
            td.td {{item.product_name}}
            td.td {{item.product_unit}}
            td.td {{item.total_profit | currency()}}
            td.td(v-for="quantity in item.quantity_by_customer") {{quantity | number}}
    v-pagination(v-model="pagination.page" :length="total_pages" @input="fetchData")
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    filters: {
      type: Object,
      required: true,
      default: () => ({})
    },
    projection: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      pagination: {
        page: 1,
        page_size: 100,
      },
      product_count: 0,
      tableData: null,
      monthFilter: null,
    }
  },
  methods: {
    ...mapActions('commercial_projection', ['getOptimalMixTable']),
    async fetchData() {
      const params = {
        ...this.filters,
        ...this.pagination,
        ...(this.monthFilter !== null && { month: this.monthFilter }),
        projection: this.projection || this.$route.params.id
      }
      const { data, error } = await this.getOptimalMixTable(params)
      if (!error) {
        this.tableData = data
        this.product_count = data.count
        const totalProfit = data?.data?.reduce((acc, cur) => acc + cur.total_profit, 0)
        this.$emit('setTotalYearProfit', totalProfit)
      }
    }
  },
  computed: {
    total_pages() {
      return Math.ceil(this.product_count / this.pagination.page_size)
    },
    monthOptions() {
      return [
        { text: 'Enero', value: 0 },
        { text: 'Febrero', value: 1 },
        { text: 'Marzo', value: 2 },
        { text: 'Abril', value: 3 },
        { text: 'Mayo', value: 4 },
        { text: 'Junio', value: 5 },
        { text: 'Julio', value: 6 },
        { text: 'Agosto', value: 7 },
        { text: 'Septiembre', value: 8 },
        { text: 'Octubre', value: 9 },
        { text: 'Noviembre', value: 10 },
        { text: 'Diciembre', value: 11 },
      ]
    },
  },
  watch: {
    filters: {
      handler() {
        this.pagination.page = 1
        this.fetchData()
      },
      deep: true,
    },
    monthFilter() {
      this.pagination.page = 1
      this.fetchData()
    },
  },
  created() {
    this.fetchData()
  }
}
</script>

<style scoped>
.td {
  min-height: 32px !important;
  height: 32px !important;
}

.month-select {
  flex-grow: 0;
  width: 300px;
}
</style>
