<template lang="pug">
v-container(fluid)
  UserForm
</template>

<script>
import UserForm from '../components/users/forms/UserForm.vue'
export default {
  components: {
    UserForm
  }
}
</script>