<template lang="pug">
v-dialog(v-model="dialog" width="500")
  template(v-slot:activator="{ on, attrs }")
    v-btn(v-bind="attrs" v-on="on") Importar
      v-icon mdi-tray-arrow-up
  v-card 
    v-card-title {{title}}
    v-card-text
      v-form(ref="form" @submit.prevent="onSubmit")
        v-file-input(
          v-model="form.file"
          label="Archivo Excel"
          placeholder="Select your files"
          prepend-icon="mdi-paperclip"
          outlined
          clearable
          required)
        .text-right
          v-btn.mr-2(@click="$refs.form.reset(), dialog = false" color="primary" outlined :disabled="loading") Cancelar
          v-btn(type="submit" color="primary" :loading="loading") Importar
</template>

<script>
import FormMixin from '../../../../mixins/FormMixin.vue'

export default {
  mixins: [FormMixin],
  props: {
    title: {
      type: String,
      default: 'Importar Elementos'
    },
    planId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      dialog: false,
      form: {
        file: null
      }
    }
  },
  methods: {
    getData () {
      const data = new FormData()
      data.append('file', this.form.file)
      return { planId: this.planId, data }
    },
    onSuccess () {
      this.dialog = false
    }
  }
}
</script>