<template lang="pug">
div
  v-card-title
    span Potencial Comercial del Caso
    ShowInfoBtn(@click="$refs.infoAlert.showAlert()")
  v-card-text
    InfoAlert(:message="alertMessage" ref="infoAlert")
    v-row
      v-col
        h3 Tasa de crecimiento anual
        p Ingresa el porcentaje de crecimiento anual de potencial de mercado
        v-form(@submit.prevent="updateGrowthRate" ref="form")
          v-row
            v-col(cols="12" md="3")
              v-text-field(v-model="form.growthRate" type="number" :rules="v.growthRate" outlined suffix="%" min-width="120px")
            v-col(cols="12" md="auto")
              v-btn(color="primary" type="submit" height="56") Graficar
      v-col(cols="12" md="auto")
        h3 Tamaño de mercado
        h1.primary--text {{ computedMarketSize | currency }}
  v-card-text(style="display flex; align-items: center; justify-content: center")
    Bar.chart(:chart-data="chartData" :chart-options="chartOptions" :chart-id="id" :height="100")
</template>

<script>
import { Bar } from 'vue-chartjs/legacy'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import { mapActions } from 'vuex'
import InfoAlert from '../../common/InfoAlert.vue'
import ShowInfoBtn from '../../common/ShowInfoBtn.vue'

ChartJS.register(Title, Tooltip, BarElement, CategoryScale, Legend, LinearScale)


export default {
  props: {
    filters: {
      type: Object,
      required: true,
      default: () => ({})
    },
    projection: {
      type: Number,
      default: null,
    },
  },
  components: {
    Bar,
    InfoAlert,
    ShowInfoBtn,
  },
  data() {
    return {
      id: this._uid.toString(),
      alertMessage: `
<p>
El potencial comercial representa el <b>tamaño total de venta de tu mercado</b> según el caso ingresado. Es una combinación de tu Mercado Total Disponible (TAM) y tu Mercado Disponible Servible (SAM), <b>abarcando</b> así tu <b>mercado objetivo conocido</b>. Esto <b>se centra en lo que conoces del negocio</b> y no toma en cuenta tus capacidades operativas actuales.
<br/>
<br/>
Utiliza los filtros disponibles para segmentar por cliente, producto o línea de negocio según lo requieras.
</p>`,
      marketSize: 0,
      form: {
        growthRate: 0
      },
      chartOptions: {
        responsive: true,

        plugins: {
          legend: {
            display: true,
            position: 'top',
          },
          tooltip: {
            callbacks: {
              label: function(context) {
                return `$${context.formattedValue}`
              }
            }
          }
        }
      },
      chartData: {
        labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        datasets: [
          {
            label: 'Potencial en $',
            data: [],
            backgroundColor: '#eb6e81',
            hoverBackgroundColor: '#eb6e81'
          }
        ]
      },
      v: {
        growthRate: [
          v => !!v || 'Este campo es requerido',
          v => v >= 0 || 'El valor debe ser mayor o igual a 0'
        ]
      }
    }
  },
  computed: {
    computedMarketSize() {
      return this.chartData.datasets[0].data.reduce((acc, value) => acc + value, 0)
    }
  },
  methods: {
    ...mapActions('commercial_projection', ['getTotalProjectionChart']),
    async fetchData() {
      const params = {
        ...this.filters,
        projection: this.projection || this.$route.params.id
      }
      const { data, error } = await this.getTotalProjectionChart(params)

      if (!error) {
        this.marketSize = data.total
        this.updateChart()
      }
    },
    updateGrowthRate() {
      if (this.$refs.form.validate()) {
        this.updateChart()
      }
    },
    updateChart() {
      const months = 12
      const firstMonth = this.marketSize / months
      const growthByMonth = firstMonth * (this.form.growthRate / 100) / months

      this.chartData.datasets[0].data = Array.from({ length: months }, (_, index) => firstMonth + growthByMonth * index)
      this.chartData = { ...this.chartData }
    },
  },
  watch: {
    filters: {
      handler() {
        this.fetchData()
      },
      deep: true,
    },
    computedMarketSize(value) {
      this.$emit('updateMarketSize', value)
    },
  },
  created() {
    this.fetchData()
  }
}
</script>

<style>
canvas {
  aspect-ratio: unset !important;
}
</style>
