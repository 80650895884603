<template lang="pug">
v-dialog(v-model="dialog" width="500")
  template(v-slot:activator="{ on, attrs }")
    v-btn(v-bind="attrs" v-on="on") Agregar Costo
  v-card 
    v-card-title Nuevo Costo
    v-card-text
      v-form(ref="form" @submit.prevent="onSubmit")
        v-text-field(v-model="form.date" label="Fecha" type="date" outlined :rules="v.date")
        v-row(v-for="cost in form.costs")
          v-col(cols="4")
            h4 {{cost.cost_name}}:
          v-col
          v-currency-field(v-model="cost.cost" label="Costo" type="number" outlined prefix="$" :rules="v.cost" hide-details)
        .text-right.mt-4
          v-btn.mr-2(@click="$refs.form.reset(), dialog = false" color="primary" outlined :disabled="loading") Cancelar
          v-btn(type="submit" color="primary" :loading="loading") Agregar
</template>

<script>
import { mapActions } from 'vuex'
import formValidations from '../../../../../shared/formValidations'
import { formatDate } from '@/utils/dateUtils.js'

export default {
  props: {
    planId: {
      type: Number,
      required: true
    },
    costList: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      dialog: false,
      form: {
        date: formatDate(new Date()),
        cost_name: null,
        costs: this.costList.map(costName => ({ cost_name: costName, cost: null }))
      }
    }
  },
  methods: {
    ...mapActions('real_data', ['createRealCost']),
    async onSubmit () {
      if (!this.$refs.form.validate()) {
        return
      }
      this.loading = true
      for (const cost of this.form.costs) {
        if (!!cost.cost) {
          const response = await this.createRealCost({
            data: {
              plan: this.planId,
              date: this.form.date,
              cost_name: cost.cost_name,
              cost: cost.cost
            }
          })
          if (response.status != 201) {
            return
          }
        }
      }
      this.resetForm()
      this.$emit('save')
      this.dialog = false
      this.loading = false
    },
    resetForm () {
      this.$refs.form.resetValidation()
      this.form = {
        date: null,
        cost_name: null,
        costs: this.costList.map(costName => ({ cost_name: costName, cost: null }))
      }
    }
  },
  computed: {
    v () {
      return {
        date: [formValidations.isRequired],
        cost_name: [formValidations.isRequired],
        cost: [formValidations.min(0)]
      }
    }
  }
}
</script>
