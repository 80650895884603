import axios from '../../plugins/axios'
import { getCurrentBusinessId } from '../../utils/authUtils'



export const createCustomer = (_, { data } = {} ) => {
  return axios.post(`/client/business/${getCurrentBusinessId()}/customer/`, data)
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const listCustomers = (_, { params } = {}) => {
  return axios.get(`/client/business/${getCurrentBusinessId()}/customer/`, { params })
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const editCustomer = (_, { id, data } = {} ) => {
  return axios.patch(`/client/business/${getCurrentBusinessId()}/customer/${id}/`, data)
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const deleteCustomer = (_, id) => {
  return axios.delete(`/client/business/${getCurrentBusinessId()}/customer/${id}/`)
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const getCustomer = (_, id) => {
  return axios.get(`/client/business/${getCurrentBusinessId()}/customer/${id}/`)
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const importCustomers = (_, { data }) => {
  return axios.post(`/client/business/${getCurrentBusinessId()}/customer/import/`,  data, { headers: { 'Content-Type': 'multipart/form-data' } })
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const listCommunes = () => {
  return axios.get(`/client/commune/`)
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }))
}

export const bulkDeleteCustomers = (_, ids) => {
  return axios.delete('/client/business/' + getCurrentBusinessId() + '/customer/bulk-delete/', { data: { ids }})
    .then(response => ({ data: response.data, response, status: response.status }))
    .catch(error => ({ error }));
}

export const downloadCustomers = (_, params) => {
  return axios.get(`/client/business/${getCurrentBusinessId()}/customer/export/`, { params, responseType: 'blob'})
    .catch(error => ({ error }))
}