<template lang="pug">
  v-container(fluid)
    .d-flex.flex-column(style="gap: 16px;")
    ListCommercialProjection
  </template>
  
  <script>
  import ListCommercialProjection from '@/components/commercial_projection/ListCommercialProjection.vue'
  export default {
    components: {
      ListCommercialProjection,
    },
  }
  </script>