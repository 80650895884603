import axios from 'axios'
import settings from '../../settings'
import { isAuthenticated, getAccessToken, getRefreshToken, isTokenExpired, setAuthTokens, clearAuthTokens } from '../utils/authUtils'


const instance = axios.create({
  baseURL: `${settings.API_URL}/api`,
  withCredentials: false
})

const refreshAccessToken = () => {
  return axios.post(`${settings.API_URL}/api/auth/refresh/`, { refresh: getRefreshToken() })
    .then(response => {
      setAuthTokens(response.data.access, response.data.refresh)
      return Promise.resolve()
    })
    .catch(error => {
      clearAuthTokens()
    })
}


instance.interceptors.request.use(async config => {
  if (isAuthenticated()) {
    if (isTokenExpired(getAccessToken())) {
      await refreshAccessToken()
    }
    config.headers['Authorization'] = 'Bearer ' + getAccessToken()
  }
  return config
})



export default instance
