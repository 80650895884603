<template lang="pug">
div
  v-card-text
    LineChartGenerator(:chart-data="chartData" :chart-options="chartOptions" :chart-id="id" :height="100")
</template>


<script>
import {  Line as LineChartGenerator  } from 'vue-chartjs/legacy'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'

import { mapActions } from 'vuex'
import chartsUtils from '@/utils/chartsUtils'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

export default {
  components: {
    LineChartGenerator
  },
  data() {
    return {
      id: this._uid.toString(),
      chartOptions: {
        plugins: {
          legend: {
            display: true,
            position: 'top',
          },
          tooltip: {
            callbacks: {
              label: function(context) {
                const skuCount = context ? context.dataset['extra'][context.dataIndex]['sku_count'] : 0
                const communeCount = context ? context.dataset['extra'][context.dataIndex]['commune_count'] : 0
                return `$${context.formattedValue} (${skuCount} SKU, ${communeCount} Comunas)`
              },
            }
          }
        }
      },
      chartData: {
        labels: [],
        datasets: [
        ]
      },
    }
  },
  methods: {
    ...mapActions('commercial_projection', ['getProjectionComparisonChart']),
    async fetchData() {
      const { data, error } = await this.getProjectionComparisonChart()

      if (!error) {
        this.chartData.labels = data.labels
        this.chartData.datasets = data.datasets.map((dataset, index) => ({
          label: dataset.label,
          data: dataset.data,
          extra: dataset.extra,
          backgroundColor: chartsUtils.colors[index % chartsUtils.colors.length],
          hoverBackgroundColor: chartsUtils.colors[index % chartsUtils.colors.length],
          borderColor: chartsUtils.colors[index % chartsUtils.colors.length]
        }))
      }
    },
  },
  watch: {
    chartBy() {
      this.fetchData()
    }
  },
  created() {
    this.fetchData()
  }
}
</script>
