const mutations = {
  SET_CURRENT_BUSINESS (state, value) {
    if (value) {
      state.currentBusiness = value
      localStorage.setItem('current_business', JSON.stringify(value))
    } else {
      state.currentBusiness = null
      localStorage.removeItem('current_business')
    }
  }
}

export default mutations
