<template lang="pug">
  v-container(fluid)
    CostPlanForm(v-if="$route.params.planType === 'costos'")
    PlanForm(v-else)
</template>

<script>
import PlanForm from '../components/plan/PlanForm.vue'
import CostPlanForm from '../components/plan/forms/CostPlanForm.vue'
export default {
  components: {
    PlanForm,
    CostPlanForm
  }
}
</script>