<template lang="pug">
v-dialog(v-model="dialog" width="1200")
  v-card
    v-card-title Nuevo SKU para Plan de Ventas
    v-form(ref="form" @submit.prevent="onSubmit")
      v-stepper(v-model="currentStep" flat)
        v-stepper-header.d-flex.flex-nowrap(style="overflow-x: auto;")
          template(v-for="step, index in steps")
            v-stepper-step(
              :complete="currentStep > index + 1"
              @click="goToStep"
              :step="index+1"
              editable
              :rules="[() => index + 1 === 1 ? !(currentStep !== 1 && !form.product):  true]"
            ) {{ step }}
            v-divider(v-if="index + 1 !== steps.length")
      
        v-stepper-items
          v-stepper-content(step="1")
            ProductAutocomplete.mt-2(
              v-model="form.product"
              :rules="v.product")
            CustomerAutocomplete(
              label="Selecciona un cliente"
              v-model="selectedCustomer"
              hide-details
              @change="addCustomer")
            div.my-4.d-flex.flex-wrap
              v-chip.ma-1(
                v-for="(item, index) in form.items"
                close
                small
                @click:close="removeItem(index)"
              ) {{ item.customer.name }}
            .text-right
              v-btn.mr-2(color="primary" outlined @click="closeDialog" :disabled="loading") Cancelar
              v-btn(color="primary" @click="nextStep()") Siguiente
          v-stepper-content(v-for="item, index in form.items" :step="index + 2")
            
            div(v-if="currentStep===index+2")
              h3.mb-4 {{ item.customer.name }}
              h4.mb-4 Cantidad Mensual a Vender ({{ unitOfMeasure }})
              v-row.mt-2
                v-col(cols="12" md="2")
                  NumberMaskedInput(label="Cantidad Enero" v-model="item.january_quantity" outlined type="number" :rules="v.quantity" :suffix="unitOfMeasure")
                v-col(cols="12" md="2")
                 NumberMaskedInput(label="Cantidad Febrero" v-model="item.february_quantity" outlined type="number" :rules="v.quantity" :suffix="unitOfMeasure")
                v-col(cols="12" md="2")
                 NumberMaskedInput(label="Cantidad Marzo" v-model="item.march_quantity" outlined type="number" :rules="v.quantity" :suffix="unitOfMeasure")
                v-col(cols="12" md="2")
                 NumberMaskedInput(label="Cantidad Abril" v-model="item.april_quantity" outlined type="number" :rules="v.quantity" :suffix="unitOfMeasure")
                v-col(cols="12" md="2")
                 NumberMaskedInput(label="Cantidad Mayo" v-model="item.may_quantity" outlined type="number" :rules="v.quantity" :suffix="unitOfMeasure")
                v-col(cols="12" md="2")
                 NumberMaskedInput(label="Cantidad Junio" v-model="item.june_quantity" outlined type="number" :rules="v.quantity" :suffix="unitOfMeasure")
                v-col(cols="12" md="2")
                 NumberMaskedInput(label="Cantidad Julio" v-model="item.july_quantity" outlined type="number" :rules="v.quantity" :suffix="unitOfMeasure")
                v-col(cols="12" md="2")
                 NumberMaskedInput(label="Cantidad Agosto" v-model="item.august_quantity" outlined type="number" :rules="v.quantity" :suffix="unitOfMeasure")
                v-col(cols="12" md="2")
                 NumberMaskedInput(label="Cantidad Septiembre" v-model="item.september_quantity" outlined type="number" :rules="v.quantity" :suffix="unitOfMeasure")
                v-col(cols="12" md="2")
                 NumberMaskedInput(label="Cantidad Octubre" v-model="item.october_quantity" outlined type="number" :rules="v.quantity" :suffix="unitOfMeasure")
                v-col(cols="12" md="2")
                 NumberMaskedInput(label="Cantidad Noviembre" v-model="item.november_quantity" outlined type="number" :rules="v.quantity" :suffix="unitOfMeasure")
                v-col(cols="12" md="2")
                 NumberMaskedInput(label="Cantidad Diciembre" v-model="item.december_quantity" outlined type="number" :rules="v.quantity" :suffix="unitOfMeasure")
                v-col(cols="12")
                  NumberMaskedInput(label="Precio Especifico (opcional)" v-model="item.price" outlined type="number")
              .text-right
                v-btn.mr-2(color="primary" outlined @click="closeDialog" :disabled="loading") Cancelar
                v-btn(color="primary" @click="nextStep()") Siguiente
          v-stepper-content(:step="steps.length")
            h3 Resumen
            div
              strong Producto:
              span(v-if="form.product")  {{ form.product.name }} - {{ form.product.sku }}
              span(v-else)  No se ha seleccionado un producto

            v-simple-table.mb-4(v-if="form.items.length > 0")
              template(v-slot:default)
                thead
                  tr
                    th.text-left Cliente
                    th.text-left Enero
                    th.text-left Febrero
                    th.text-left Marzo
                    th.text-left Abril
                    th.text-left Mayo
                    th.text-left Junio
                    th.text-left Julio
                    th.text-left Agosto
                    th.text-left Septiembre
                    th.text-left Octubre
                    th.text-left Noviembre
                    th.text-left Diciembre
                    th.text-left Precio
                tbody
                  tr(v-for="(item, index) in form.items")
                    td.text-left {{ item.customer.name }}
                    td.text-left {{ (item.january_quantity || 0) | number }}
                    td.text-left {{ (item.february_quantity || 0) | number }}
                    td.text-left {{ (item.march_quantity || 0) | number }}
                    td.text-left {{ (item.april_quantity || 0) | number }}
                    td.text-left {{ (item.may_quantity || 0) | number }}
                    td.text-left {{ (item.june_quantity || 0) | number }}
                    td.text-left {{ (item.july_quantity || 0) | number }}
                    td.text-left {{ (item.august_quantity || 0) | number }}
                    td.text-left {{ (item.september_quantity || 0) | number }}
                    td.text-left {{ (item.october_quantity || 0) | number }}
                    td.text-left {{ (item.november_quantity || 0) | number }}
                    td.text-left {{ (item.december_quantity || 0) | number }}
                    td.text-left(v-if="item.price ") {{ item.price | currency }}
                    td.text-left(v-else) -
            div.mt-4(v-else) No se han agregado clientes.
            .text-right
              v-btn.mr-2(color="primary" outlined @click="closeDialog" :disabled="loading") Cancelar
              v-btn(color="primary" type="submit" :disabled="!form.product" :loading="loading") Guardar
          
</template>

<script>
import { mapActions } from 'vuex'
import FormMixin from '@/components/mixins/FormMixin' 
import validations from '@/shared/formValidations'
import ProductAutocomplete from '../common/ProductAutocomplete.vue'
import CustomerAutocomplete from '../common/CustomerAutocomplete.vue'
import NumberMaskedInput from '../common/NumberMaskedInput.vue'
export default {
  mixins: [FormMixin],
  components: {
    ProductAutocomplete,
    CustomerAutocomplete,
    NumberMaskedInput
  },
  data() {
    return {
      currentStep: 1,
      projectionId: this.$route.params.id,
      instanceId: null,
      selectedCustomer: null,
      form: {
        product: null,
        items: [],
        customer: null,
        price: null,
        january_quantity: 0,
        february_quantity: 0,
        march_quantity: 0,
        april_quantity: 0,
        may_quantity: 0,
        june_quantity: 0,
        july_quantity: 0,
        august_quantity: 0,
        september_quantity: 0,
        october_quantity: 0,
        november_quantity: 0,
        december_quantity: 0,
      },
      searchProduct: null,
      products: [],
      searchCustomer: null,
      customers: [],
      dialog: false,
    }
  },
  methods: {
    ...mapActions('commercial_projection', ['setSalePlanItemByProduct', 'listSalePlanItems']),
    ...mapActions('product', ['listLineOfBusiness', 'listProducts']),
    ...mapActions('customer', ['listCustomers']),
    addCustomer (customer) {
      if (!customer) return
      this.$nextTick(() => {
        this.selectedCustomer = null
      })
      if (this.form.items.some(e => e.customer.id === customer.id)) return
      this.form.items.push(
        {
          customer,
          price: null,
          january_quantity: 0,
          february_quantity: 0,
          march_quantity: 0,
          april_quantity: 0,
          may_quantity: 0,
          june_quantity: 0,
          july_quantity: 0,
          august_quantity: 0,
          september_quantity: 0,
          october_quantity: 0,
          november_quantity: 0,
          december_quantity: 0,
        }
      )
    },
    removeItem (index) {
      this.form.items.splice(index, 1)
    },
    async openDialog(instanceId) {
      this.instanceId = instanceId
      await this.fetchForm()
      this.dialog = true
    },
    closeDialog() {
      this.dialog = false
    },
    onSuccess() {
      this.dialog = false
      this.$emit('save')
    },
    onError({ error }) {
      if (Array.isArray(error.response.data)) {
        this.errors = error.response.data
      } else if (error.response.data.non_field_errors) {
        this.errors = [...error.response.data.non_field_errors]
        this.errors = this.errors.map(error => {
          let _error = error.replace(' projection,', '')
          _error = _error.replace('customer', 'Cliente')
          _error = _error.replace('product', 'Producto')
          return _error
        })
      }

      const errorMessage = this.errors.join('\n')
      this.$toast.error(errorMessage)
    },
    getAction() {
      return this.setSalePlanItemByProduct
    },
    async fetchItems () {
      const params = {
        product: this.form.product.id,
        projection: this.projectionId,
        page_size: 1000
      }
      const { data } = await this.listSalePlanItems(params)
      this.form.items = data.results
    },
    async fetchForm() {
      this.resetForm()
    },
    getData() {
      const data = { 
        data: {
          product: this.form.product.id,
          projection: this.$route.params.id,
          items: this.form.items.map(e => {
            return this.cleanItem(e)
          })
        } 
      }
      return data
    },
    cleanItem(item) {
      const cleanedItem = {}
      for (const property in item) {
        if (property === "customer") {
          cleanedItem[property] = item.customer.id
          continue
        }

        if (property === "price") {
          if (!!item[property] && item[property] !== 0) {
            cleanedItem[property] = item.price
          }
          continue
        }

        cleanedItem[property] = item[property] || 0
      }
      return cleanedItem
    },
    resetForm() {
      this.currentStep = 1
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
      this.errors = []
      this.searchProduct = ''
      this.searchCustomer = ''
      this.form = {
        product: null,
        items: []
      }
    },
    onClose() {
      this.instanceId = null
    },
    nextStep(){
      if (!this.$refs.form.validate()) return
      this.currentStep++
    },
    goToStep (event) {
      event.preventDefault()
      console.log(event)
    }
  },
  computed: {
    steps() {
      let steps = [
        'Producto',
        ...this.form.items.map(e => e.customer.name),
        'Finalizar'
      ]
      if (steps.length > 6) {
        steps = steps.map(e => {
          if (e.length > 6){
            return e.substring(0, 3) + '...'
          }
          return e
        })
      }
      return steps
    },
    unitOfMeasure() {
      return this.form.product ? this.form.product.unit_of_measure : ''
    },
    v() {
      return {
        product: [validations.isRequired],
        customer: [validations.isRequired],
        quantity: [validations.isRequired, validations.minOrEqual(0)],
        price: [validations.isRequired, validations.min(0)],
      }
    },
    yearsOptions() {
      const startYear = 2020
      const endYear = new Date().getFullYear() + 1
      const years = []
      for (let i = startYear; i <= endYear; i++) {
        years.push(i)
      }
      return years
    },
    productsOptions() {
      return this.products.map(product => ({
        ...product,
      }))
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.onClose()
      }
    },
    'form.product' (value) {
      if (value) {
        this.fetchItems()
      }
    }
  },
}
</script>
