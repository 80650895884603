<template lang="pug">
v-container(fluid)
  .d-flex.flex-column(style="gap: 16px;")
    ListLineOfBusiness
    ListBaseProducts
</template>

<script>
import ListBaseProducts from '@/components/products/ListBaseProducts.vue'
import ListLineOfBusiness from '@/components/products/ListLineOfBusiness.vue'
export default {
  components: {
    ListBaseProducts,
    ListLineOfBusiness
  },
}
</script>