const mutations = {
  SET_USER (state, value) {
    if (value) {
      state.user = value
      localStorage.setItem('user', JSON.stringify(value))
    } else {
      state.user = null
      localStorage.removeItem('user')
    }
  },
  SET_HAS_PRODUCTS (state, value) {
    state.hasProducts = value
  },
  SET_HAS_BASE_PRODUCTS (state, value) {
    state.hasBaseProducts = value
  },
  SET_HAS_LINE_OF_BUSINESS (state, value) {
    state.hasLineOfBusiness = value
  },
}

export default mutations
