<template lang="pug">
v-card
  v-card-title Tabla detallada del Plan de Ventas
  v-card-text
    v-simple-table
      template(v-slot:default)
        thead
          tr
            th SKU
            th Producto
            th Cliente
            th Cantidad
            th Unidad de Medida
            th Precio
            th Margen Bruto
            th Acciones
        tbody
          tr(v-for="item in items" :key="item.id")
            td.td {{item.product_sku}}
            td.td {{item.product_name}}
            td.td {{item.customer_name}}
            td.td {{item.total_quantity | number}}
            td.td {{item.unit_of_measure}}
            td.td {{item.price | currency}}
            td.td {{item.profit | currency}}
            td.td
              v-btn(color="primary" @click="$emit('editItem', item.id)" icon)
                v-icon mdi-pencil
              v-btn(color="error" @click="deleteItem(item.id)" icon)
                v-icon mdi-delete
    v-pagination(v-model="pagination.page" :length="total_pages" @input="fetchData")
  CustomConfirmDialog(ref="confirmDialog")
</template>

<script>
import { mapActions } from 'vuex'
import CustomConfirmDialog from '@/components/common/dialogs/CustomConfirmDialog.vue'

export default {
  components: {
    CustomConfirmDialog
  },
  props: {
    filters: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  data() {
    return {
      pagination: {
        page: 1,
        page_size: 100
      },
      product_count: 0,
      items: []
    }
  },
  methods: {
    ...mapActions('commercial_projection', ['listSalePlanItems', 'deleteSalePlanItem']),
    async fetchData() {
      const params = {
        ...this.filters,
        ...this.pagination,
        projection: this.$route.params.id
      }
      const { data, error } = await this.listSalePlanItems(params)
      if (!error) {
        this.items = data.results
        this.product_count = data.count
      }
    },
    async deleteItem (id) {
      const confirm = await this.$refs.confirmDialog.open(
        'Eliminar item',
        '¿Está seguro que desea eliminar este item?',
        {
          okTitle: 'Eliminar',
        }
      )
      if (confirm) {
        await this.deleteSalePlanItem(id)
        await this.fetchData()
      }
    },
  },
  computed: {
    total_pages() {
      return Math.ceil(this.product_count / this.pagination.page_size)
    }
  },
  watch: {
    filters: {
      handler() {
        this.pagination.page = 1
        this.fetchData()
      },
      deep: true,
    },
  },
  created() {
    this.fetchData()
  }
}
</script>

<style scoped>
.td {
  min-height: 32px !important;
  height: 32px !important;
}

</style>
