<template lang="pug">
  v-container(v-if="plan" fluid)
    PageHeader.mb-4(v-if="plan" :title="plan.name" backTo="/planes" :subtitle="`Año ${plan.year}`")
    PlanDetail.mb-4(:plan="plan" ref="planDetail")
    PlanDetailData(:plan="plan" ref="planDetailData" @updateData="updateChart()")
</template>

<script>
import PlanDetail from '../components/plan/PlanDetail.vue'
import PlanDetailData from '../components/plan/PlanDetailData.vue'
import { mapActions } from 'vuex'
import PageHeader from '@/components/common/PageHeader.vue'
export default {
  components: {
    PlanDetail,
    PlanDetailData,
    PageHeader
  },
  data () {
    return {
      plan: null
    }
  },
  methods: {
    updateChart () {
      this.$refs.planDetail.updateChart()
    },
    ...mapActions('plan', ['getPlan']),
    async fetchData () {
      this.plan = (await this.getPlan({ planId: this.$route.params.planId})).data
    }
  },
  created () {
    this.fetchData()
  }
}
</script>
