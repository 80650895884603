<template lang="pug">
v-dialog(v-model="dialog" width="1200")
  v-card
    v-card-title Agregar Venta
    v-form(ref="form" @submit.prevent="onSubmit")
      v-stepper(v-model="currentStep" flat)
        v-stepper-header.d-flex.flex-nowrap(style="overflow-x: auto;")
          template(v-for="step, index in steps")
            v-stepper-step(
              :complete="currentStep > index + 1"
              @click="goToStep"
              :step="index+1"
              editable
              :rules="[() => index + 1 === 1 ? !(currentStep !== 1 && (!form.customer || !form.date || !form.payment_method)):  true]"
            ) {{ step }}
            v-divider(v-if="index + 1 !== steps.length")
      
        v-stepper-items
          v-stepper-content(step="1")
            CustomerAutocomplete.mt-2(
              label="Selecciona un cliente"
              v-model="form.customer"
              :rules="v.customer")
            ProductAutocomplete(
              v-model="selectedProduct"
              :rules="v.product"
              hide-details
              @change="addProduct")
            div.my-4.d-flex.flex-wrap
              v-chip.ma-1(
                v-for="(item, index) in form.items"
                close
                small
                @click:close="removeItem(index)"
              ) {{ item.product.name }}
            v-text-field(
              v-model="form.date"
              label="Fecha"
              type="date"
              outlined
              :rules="v.date")
            v-select(
              v-model="form.payment_method"
              label="Medio de pago"
              outlined
              :items="['Efectivo', 'Tarjeta', 'Transferencia']"
              :rules="v.payment_method")

            .text-right
              v-btn.mr-2(color="primary" outlined @click="closeDialog" :disabled="loading") Cancelar
              v-btn(color="primary" @click="nextStep()") Siguiente
          v-stepper-content(v-for="item, index in form.items" :step="index + 2")
            
            div(v-if="currentStep===index+2")
              h5 {{ item.product.name }}
              div.mb-4 SKU: {{ item.product.sku }}
              v-row.mt-2
                v-col(cols="12")
                  NumberMaskedInput(label="Cantidad" v-model="item.quantity" outlined type="number" :rules="v.quantity" :suffix="item.product.unit_of_measure")
                  NumberMaskedInput(label="Monto total" v-model="item.total_price" outlined type="number" :rules="v.quantity" prefix="$")
                  v-textarea(label="Detalle" v-model="item.detail" outlined type="number")
              .text-right
                v-btn.mr-2(color="primary" outlined @click="closeDialog" :disabled="loading") Cancelar
                v-btn(color="primary" @click="nextStep()") Siguiente
          v-stepper-content(:step="steps.length")
            h3 Resumen
            div
              strong Cliente:
              span(v-if="form.customer")  {{ form.customer.name }}
              span(v-else)  No se ha seleccionado un cliente
            div
              strong Fecha:
              span(v-if="form.date")  {{ form.date | dateFormat }}
              span(v-else)  No se ha seleccionado una fecha
            div
              strong Medio de pago:
              span  {{ form.payment_method || 'No se ha seleccionado un medio de pago' }}
            v-simple-table.mb-4(v-if="form.items.length > 0")
              template(v-slot:default)
                thead
                  tr
                    th.text-left Producto
                    th.text-left SKU
                    th.text-left Cantidad
                    th.text-left Precio Total

                tbody
                  tr(v-for="(item, index) in form.items")
                    td.text-left {{ item.product.name }}
                    td.text-left {{ item.product.sku }}
                    td.text-left {{ item.quantity | number }} {{ item.product.unit_of_measure }}
                    td.text-left {{ item.total_price | currency }}
            .text-right
              v-btn.mr-2(color="primary" outlined @click="closeDialog" :disabled="loading") Cancelar
              v-btn(color="primary" type="submit" :disabled="form.items.length === 0 || !form.customer || !form.date || !form.payment_method" :loading="loading") Guardar
          
</template>

<script>
import { mapActions } from 'vuex'
import FormMixin from '@/components/mixins/FormMixin' 
import validations from '@/shared/formValidations'
import ProductAutocomplete from '@/components/common/ProductAutocomplete.vue'
import CustomerAutocomplete from '@/components/common/CustomerAutocomplete.vue'
import NumberMaskedInput from '@/components/common/NumberMaskedInput.vue'

export default {
  mixins: [FormMixin],
  components: {
    ProductAutocomplete,
    CustomerAutocomplete,
    NumberMaskedInput
  },
  data() {
    return {
      currentStep: 1,
      instanceId: null,
      selectedProduct: null,
      form: {
        items: [],
        customer: null,
        date: null,
        payment_method: null,
      },
      searchProduct: null,
      products: [],
      searchCustomer: null,
      customers: [],
      dialog: false,
    }
  },
  methods: {
    ...mapActions('real_data', ['createMasiveRealSale']),
    ...mapActions('product', ['listLineOfBusiness', 'listProducts']),
    ...mapActions('customer', ['listCustomers']),
    addProduct (product) {
      if (!product) return
      this.$nextTick(() => {
        this.selectedProduct = null
      })
      if (this.form.items.some(e => e.product.id === product.id)) return
      this.form.items.push(
        {
          product,
          quantity: 0,
          total_price: 0,
          detail: ''
        }
      )
    },
    removeItem (index) {
      this.form.items.splice(index, 1)
    },
    async openDialog(instanceId) {
      this.instanceId = instanceId
      this.resetForm()
      this.dialog = true
    },
    closeDialog() {
      this.dialog = false
    },
    onSuccess() {
      this.dialog = false
      this.$emit('save')
    },
    onError({ error }) {
      if (Array.isArray(error.response.data)) {
        this.errors = error.response.data
      } else if (error.response.data.non_field_errors) {
        this.errors = [...error.response.data.non_field_errors]
        this.errors = this.errors.map(error => {
          let _error = error.replace(' projection,', '')
          _error = _error.replace('customer', 'Cliente')
          _error = _error.replace('product', 'Producto')
          return _error
        })
      }

      const errorMessage = this.errors.join('\n')
      this.$toast.error(errorMessage)
    },
    getAction() {
      return this.createMasiveRealSale
    },
    getData() {
      const data = { 
        data: this.form.items.map(e => {
          return this.cleanItem(e)
        })
      }
      return data
    },
    cleanItem(item) {
      return {
        plan: this.instanceId,
        customer: this.form.customer.id,
        date: this.form.date,
        payment_method: this.form.payment_method,
        product: item.product.id,
        quantity: item.quantity || 0,
        total_price: item.total_price || 0,
        detail: item.detail || ''
      }
      
    },
    resetForm() {
      this.currentStep = 1
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
      }
      this.errors = []
      this.searchProduct = ''
      this.searchCustomer = ''
      this.form = {
        product: null,
        items: []
      }
    },
    onClose() {
      this.instanceId = null
    },
    nextStep(){
      if (!this.$refs.form.validate()) return
      this.currentStep++
    },
    goToStep (event) {
      event.preventDefault()
      console.log(event)
    }
  },
  computed: {
    steps() {
      let steps = [
        'Ventas',
        ...this.form.items.map(e => e.product.name),
        'Finalizar'
      ]
      if (steps.length > 6) {
        steps = steps.map(e => {
          if (e.length > 6){
            return e.substring(0, 3) + '...'
          }
          return e
        })
      }
      return steps
    },
    v() {
      return {
        product: [() => !!this.form.items.length || 'Debe agregar al menos un producto'],
        customer: [validations.isRequired],
        quantity: [validations.isRequired, validations.minOrEqual(0)],
        price: [validations.isRequired, validations.min(0)],
      }
    },
    yearsOptions() {
      const startYear = 2020
      const endYear = new Date().getFullYear() + 1
      const years = []
      for (let i = startYear; i <= endYear; i++) {
        years.push(i)
      }
      return years
    },
    productsOptions() {
      return this.products.map(product => ({
        ...product,
      }))
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.onClose()
      }
    },
  },
}
</script>

